import { Button } from "@chakra-ui/react";
import { useI18n } from "next-localization";

export const FilterLoadmoreHandler = ({
    loading,
    hasNext,
    fetchSearchResults,
    ariaControls,
}: {
    loading: boolean;
    hasNext: boolean;
    fetchSearchResults: () => void;
    ariaControls?: string;
}) => {
    const { t } = useI18n();
    return (
        <Button
            display={"flex"}
            mt="sp40"
            mx="auto"
            size="sm"
            isLoading={loading}
            onClick={(e) => {
                e.preventDefault();
                if (hasNext) {
                    fetchSearchResults();
                }
            }}
            aria-controls={ariaControls}
            aria-disabled={loading || !hasNext}
            sx={{
                ...((loading || !hasNext) && {
                    cursor: "not-allowed",
                    pointerEvents: "none",
                }),
            }}
        >
            {loading || !hasNext ? t("noMoreResultsButtonText") : t("loadMoreButtonText")}
        </Button>
    );
};
