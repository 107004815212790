export const dateFormatter = (utcDate: string): string | null => {
    if (!utcDate || utcDate === "0001-01-01T00:00:00Z") {
        return null; // or any default value you prefer for invalid dates
    }

    const date = new Date(utcDate.split("T")[0] + "T00:00:00Z");
    const userTimezoneOffset = date.getTimezoneOffset() * 60000;
    const articleDateObj = new Date(date.getTime() + userTimezoneOffset);
    const options: Intl.DateTimeFormatOptions = {
        month: "short",
        day: "numeric",
        year: "numeric",
    };
    return articleDateObj.toLocaleDateString("en-US", options);
};
