import {
    useSitecoreContext,
    type ComponentParams,
    type ComponentRendering,
    type Field,
    type ImageField,
    type LinkField,
} from "@sitecore-jss/sitecore-jss-nextjs";
import { Box, Container, Heading, Stack } from "@chakra-ui/react";
import SCHeading from "commons/sc/SCHeading";
import SCText from "commons/sc/SCText";
import SCImage from "commons/sc/SCImage";
import SCLinkButton from "commons/sc/SCLinkButton";
import BundleIcon from "commons/ui/BundleIcon";
import { gradients } from "themes/foundations/color";
import { mapGradient } from "themes/utils/mapGradient";
import useImageSizes, { imageSize } from "utils/hooks/useImageSizes";

interface Fields {
    Kicker: Field<string>;
    Headline: Field<string>;
    Text: Field<string>;
    CTA: LinkField;
    BackgroundImageDesktop: ImageField;
    BackgroundImageMobile: ImageField;
}

type HeroProps = {
    rendering?: ComponentRendering & { params: ComponentParams };
    fields: Fields;
    size?: "halfSize" | "fullSize";
    variant?: "bordered" | "fullScreen";
};

interface HeroBackgroundProps extends HeroProps {
    sizes?: ReturnType<typeof useImageSizes>;
}

const heroFullSizeMaxHeight = {
    base: "calc(45svh + var(--space-sp80))",
    lg: "calc(50svh + var(--space-sp80))",
    xl: "calc(45svh + var(--space-sp80))",
    xxl: "calc(40svh + var(--space-sp80))",
};

const HeroContent = (props: HeroProps) => {
    const {
        sitecoreContext: { pageEditing },
    } = useSitecoreContext();
    const hasLower = props.fields.Headline.value.startsWith("<");
    return (
        <Stack py="sp24" spacing="sp24" maxWidth={["100%", null, null, "calc((8/12) * 100%)"]}>
            <SCText
                textField={props.fields?.Kicker}
                size="eyebrowSmall"
                textTransform={"uppercase"}
            />
            {pageEditing ? (
                <SCHeading
                    as="h1"
                    textField={props.fields?.Headline}
                    size="h1"
                    sx={{
                        wordBreak: "break-word",
                        ...(hasLower && { textTransform: "inherit" }),
                    }}
                    id={props.rendering?.uid ?? props.rendering?.componentName}
                />
            ) : (
                <Heading
                    as="h1"
                    size="h1"
                    sx={{
                        wordBreak: "break-word",
                        ...(hasLower && { textTransform: "inherit" }),
                    }}
                    id={props.rendering?.uid ?? props.rendering?.componentName}
                >
                    {props.fields?.Headline.value.replace("<", "")}
                </Heading>
            )}

            <SCText size="bodyXl" textField={props.fields?.Text} />
            <SCLinkButton
                size="sm"
                rightIcon={<BundleIcon name="ArrowForward" />}
                width={"fit-content"}
                variant={"secondary"}
                linkField={props.fields?.CTA}
            />
        </Stack>
    );
};

const HeroBackground = (props: HeroBackgroundProps) => {
    const imageDesktop = props.fields?.BackgroundImageDesktop;
    const imageMobile = props.fields?.BackgroundImageMobile?.value?.src
        ? props.fields?.BackgroundImageMobile
        : props.fields?.BackgroundImageDesktop;

    return (
        <Box
            position="relative"
            height={"100%"}
            _before={{
                content: '""',
                position: "absolute",
                top: 0,
                bottom: 0,
                left: 0,
                right: 0,
                bgGradient: mapGradient(gradients.shadowGradient),
                zIndex: 1,
                pointerEvents: "none",
                "> div:first-of-type, > img:first-of-type": {
                    display: { base: "block", lg: "none" },
                },
                "> div:last-of-type, > img:last-of-type": {
                    display: { base: "none", lg: "block" },
                },
            }}
        >
            {imageMobile && (
                <SCImage
                    priority
                    imageField={imageMobile}
                    sx={{
                        width: "100%",
                        height: "100%",
                        objectFit: "cover",
                        borderRadius: { base: 0, lg: "base" },
                        display: { base: "block", lg: "none" },
                        minHeight: {
                            base: `calc(${props.size === "halfSize" ? "50svh" : "100svh"} - var(--sizes-headerMobileTopHeight) - var(--space-sp28))`,
                            sm: `calc(${props.size === "halfSize" ? "50svh" : "100svh"} - var(--sizes-headerMobileTopHeight) - var(--space-sp32))`,
                            lg: undefined,
                        },
                    }}
                    {...imageSize(Number(imageMobile?.value?.width), 65, 46)}
                    sizes={props.sizes}
                />
            )}
            {imageDesktop && (
                <SCImage
                    priority
                    blur
                    imageField={imageDesktop}
                    sx={{
                        width: "100%",
                        height: props.size === "halfSize" ? heroFullSizeMaxHeight : "100%",
                        objectFit: "cover",
                        aspectRatio: "16/9",
                        display: { base: "none", lg: "block" },
                    }}
                    {...imageSize(Number(imageDesktop?.value?.width), 16, 9)}
                    sizes={props.sizes}
                />
            )}
        </Box>
    );
};

const Hero = ({ ...props }: HeroBackgroundProps) => {
    const isFullScreen = props.variant === "fullScreen";
    const sizes = useImageSizes({ base: isFullScreen ? 1 / 2 : 1 }, isFullScreen);

    const getHeroHeight = () => {
        if (props.variant === "bordered") {
            return "100%";
        } else if (props.size === "halfSize") {
            return heroFullSizeMaxHeight;
        } else {
            return "75svh";
        }
    };

    if (!props.fields) return null;
    return (
        <Container
            as="section"
            display={"grid"}
            gridTemplateRows={props.size === "halfSize" ? "0fr" : undefined}
            margin={"0 auto"}
            variant={props.variant === "fullScreen" ? "fullWidthHero" : "hero"}
            maxWidth={props.variant === "fullScreen" ? "none" : undefined}
            width={props.variant === "fullScreen" ? "100%" : undefined}
            alignItems={"center"}
            marginTop={
                props.variant === "fullScreen"
                    ? [
                          "calc(var(--sizes-headerMobileTopHeight) * -1)",
                          "calc(var(--sizes-headerTopHeight) * -1)",
                      ]
                    : undefined
            }
            aria-labelledby={props.rendering?.uid ?? props.rendering?.componentName}
            overflow={props.variant === "fullScreen" ? "hidden" : undefined}
            height={getHeroHeight()}
        >
            <Box
                gridArea="1/1"
                width="100%"
                borderRadius={props.variant === "fullScreen" ? 0 : "base"}
                overflow={props.variant === "fullScreen" ? undefined : "hidden"}
                height={props.size === "halfSize" ? heroFullSizeMaxHeight : "70svh"}
            >
                <HeroBackground fields={props.fields} sizes={sizes} />
            </Box>
            {props.variant === "fullScreen" ? (
                <Container
                    gridArea="1/1"
                    color="white"
                    position="relative"
                    zIndex={2}
                    px={"clamp(2.625rem, 0.028rem + 11.08vw, 10rem)"}
                >
                    <HeroContent fields={props.fields} rendering={props.rendering} />
                </Container>
            ) : (
                <Box
                    px={{ base: "sp24", lg: "sp80" }}
                    gridArea="1/1"
                    color="white"
                    position="relative"
                    zIndex={2}
                >
                    <HeroContent fields={props.fields} rendering={props.rendering} />
                </Box>
            )}
        </Container>
    );
};

export const Default = (props: HeroProps) => {
    return <Hero variant="bordered" fields={props.fields} rendering={props.rendering} />;
};
export const FullScreen = (props: HeroProps) => {
    return <Hero variant="fullScreen" fields={props.fields} rendering={props.rendering} />;
};

export const BorderedHalfSize = (props: HeroProps) => {
    return (
        <Hero
            variant="bordered"
            size="halfSize"
            fields={props.fields}
            rendering={props.rendering}
        />
    );
};

export const FullScreenHalfSize = (props: HeroProps) => {
    return (
        <Hero
            variant="fullScreen"
            size="halfSize"
            fields={props.fields}
            rendering={props.rendering}
        />
    );
};
