import { Box, Button } from "@chakra-ui/react";
import type { ButtonProps } from "@chakra-ui/react";
import NextLink from "next/link";
import { Link as JssLink, useSitecoreContext } from "@sitecore-jss/sitecore-jss-nextjs";
import type { LinkField } from "@sitecore-jss/sitecore-jss-nextjs";
import { getLinkText } from "./SCLink";

interface SCLinkButtonProps extends Omit<ButtonProps, "as" | "onMouseEnter" | "onTouchStart"> {
    linkField?: LinkField;
}

const SCLinkButton = ({ linkField, ...props }: SCLinkButtonProps) => {
    const {
        sitecoreContext: { pageEditing },
    } = useSitecoreContext();

    if (!linkField) return null;

    const linkTitle = getLinkText(linkField);

    if (!pageEditing && (linkField?.value?.href || linkField?.value?.url) && linkTitle !== "") {
        return (
            <Button
                as={NextLink}
                href={linkField.value.href}
                {...(linkField.value.target && { target: linkField.value.target })}
                {...props}
            >
                <Box as="span" noOfLines={1}>
                    {linkTitle}
                </Box>
            </Button>
        );
    } else if (pageEditing) {
        return (
            <Button as="span" {...props}>
                <JssLink field={linkField} />
            </Button>
        );
    }

    return null;
};

export default SCLinkButton;
