import { Box, Flex, useBreakpointValue } from "@chakra-ui/react";
import SCImage from "commons/sc/SCImage";
import VideoPlayer from "commons/ui/VideoPlayer";
import BundleIcon from "commons/ui/BundleIcon";
import SCLinkButton from "commons/sc/SCLinkButton";
import SCText from "commons/sc/SCText";
import SCHeading from "commons/sc/SCHeading";
import type { PartnerResult } from "components/PartnersFilter";

const Links = (partner: PartnerResult) => {
    const isMobileViewport = useBreakpointValue({ base: true, md: false }, { ssr: true });
    const website = partner.websiteLink?.jsonValue;
    const linkedin = partner.linkedInLink?.jsonValue;
    const downloadLink = partner.downloadLink?.jsonValue;
    return (
        <Flex
            gap={isMobileViewport ? "sp24" : "sp42"}
            marginTop={isMobileViewport ? "sp24" : "0"}
            justifyContent={isMobileViewport ? "space-between" : "start"}
        >
            {website && (
                <SCLinkButton
                    linkField={website}
                    display="flex"
                    flexDirection={isMobileViewport ? "column" : "row"}
                    variant="unstyledWithHover"
                    size="md"
                    leftIcon={<BundleIcon name="Globe" width="24px" height="24px" />}
                />
            )}
            {linkedin && (
                <SCLinkButton
                    linkField={linkedin}
                    display="flex"
                    flexDirection={isMobileViewport ? "column" : "row"}
                    variant="unstyledWithHover"
                    size="md"
                    leftIcon={<BundleIcon name="Linkedin" width="24px" height="24px" />}
                />
            )}
            {downloadLink && (
                <SCLinkButton
                    linkField={downloadLink}
                    display="flex"
                    flexDirection={isMobileViewport ? "column" : "row"}
                    variant="unstyledWithHover"
                    size="md"
                    leftIcon={<BundleIcon name="Download" width="24px" height="24px" />}
                />
            )}
        </Flex>
    );
};

const PartnerLayout = (partner: PartnerResult) => {
    const isMobileViewport = useBreakpointValue({ base: true, md: false }, { ssr: true });

    const logo = partner.logo?.jsonValue;

    const videoUrl = partner.video?.value;

    const thumbnail = {
        thumbnailAlt: partner.partnerName?.value,
        thumbnailImage: partner.thumbnailImage?.jsonValue,
        thumbnailUrl: partner.thumbnailUrl?.value,
    };

    return (
        <Flex
            gap={isMobileViewport ? "sp24" : "sp80"}
            flexDirection={isMobileViewport ? "column-reverse" : "row"}
        >
            {
                //Links for mobile
            }
            {isMobileViewport && <Links {...partner} />}

            <Flex flexDirection="column" width={isMobileViewport ? "100%" : "50%"} gap="sp24">
                {!isMobileViewport && logo && (
                    <SCImage
                        imageField={logo}
                        style={{
                            objectFit: "contain",
                        }}
                        width={200}
                        height={80}
                    />
                )}

                {videoUrl && <VideoPlayer videoUrl={videoUrl} thumbnail={thumbnail} />}

                <Flex flexDirection="column" gap="sp12">
                    <SCHeading
                        as="h3"
                        size="h4"
                        fontSize={isMobileViewport ? "xs" : "md"}
                        fontWeight={isMobileViewport ? "bold" : "normal"}
                        textField={partner?.teaserHeadline}
                    />

                    <SCText fontSize="xs" textField={partner?.teaserText} />
                </Flex>
            </Flex>

            <Flex
                flexDirection="column"
                justifyContent="space-between"
                width={isMobileViewport ? "100%" : "50%"}
                gap="sp32"
            >
                <Box>
                    <SCHeading
                        as="h2"
                        fontSize={isMobileViewport ? "lg" : "xl"}
                        lineHeight="shorter"
                        marginBottom="sp40"
                        textField={partner?.headline}
                    />

                    <SCText fontSize="md" marginBottom="sp24" textField={partner?.subHeadline} />

                    <SCText
                        fontSize="xs"
                        marginBottom={isMobileViewport ? "sp24" : "sp60"}
                        textField={partner?.text}
                    />
                </Box>

                {!isMobileViewport && <Links {...partner} />}
            </Flex>

            {isMobileViewport && logo && (
                <SCImage
                    imageField={logo}
                    style={{
                        objectFit: "contain",
                    }}
                    width={200}
                    height={80}
                />
            )}
        </Flex>
    );
};

export default PartnerLayout;
