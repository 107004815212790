import type { GenericHeaderProps } from "commons/ui/GenericHeader";
import GenericHeader from "commons/ui/GenericHeader";
import type { ComponentParams, ComponentRendering } from "@sitecore-jss/sitecore-jss-nextjs";
import type { ArticleListCardProps } from "./ArticleListCard";
import ArticleListCard from "./ArticleListCard";
import Carousel from "commons/ui/Carousel/Carousel";

interface Fields extends GenericHeaderProps {
    Pages: ArticleListCardProps[];
}

export type ArticleListCarouselProps = {
    rendering: ComponentRendering & { params: ComponentParams };
    params: { [key: string]: string };
    fields: Fields;
};

const ArticleListCarousel = (props: ArticleListCarouselProps) => {
    const articleList =
        props.rendering.fields?.Pages && Object.values(props.rendering.fields?.Pages);
    const slidesCount = articleList?.length ?? 1;
    const isSlidesCountOver = slidesCount > 3;
    const cardStyle = {
        ".articleListCarousel_carousel_slide": {
            "&:nth-of-type(even)": {
                ".item": {
                    pt: [0, null, null, 128],

                    "&>div:nth-of-type(2)": {
                        order: 3,
                        height: "auto",
                    },
                },
            },

            "&:nth-of-type(odd)": {
                ".item": {
                    pt: 0,

                    "&>div:nth-of-type(2)": {
                        order: [3, null, null, 0],
                        height: ["auto", null, null, 128],
                    },
                },
            },
        },
    };

    if (!props.fields) return null;

    return (
        <GenericHeader
            Id={props.rendering.uid ?? props.rendering.componentName}
            AnchorId={props.fields?.AnchorId}
            HeaderHeadline={props.fields?.HeaderHeadline}
            HeaderSubHeadline={props.fields?.HeaderSubHeadline}
            HeaderCTA={props.fields?.HeaderCTA}
            headlineSize={props.params?.HeaderHeadlineSize}
            spacingSize={props.params?.Padding}
        >
            {articleList && (
                <Carousel
                    id={props.rendering?.uid}
                    gap={["sp16", null, "sp20"]}
                    slidesToShow={[1, 2, 2, isSlidesCountOver ? 3.3 : 3]}
                    dots={[slidesCount > 1, slidesCount > 2, null, slidesCount > 3.3]}
                    sx={cardStyle}
                >
                    {articleList.map((item: ArticleListCardProps, index) => {
                        return (
                            <ArticleListCard
                                id={item.id}
                                key={item.id}
                                carouselIndex={index}
                                fields={item.fields}
                                url={item.url}
                            />
                        );
                    })}
                </Carousel>
            )}
        </GenericHeader>
    );
};
export default ArticleListCarousel;
