/* eslint-disable @typescript-eslint/no-explicit-any */

type CallbackFn<T> = (payload: T) => void;

export class EventEmitter<T> {
    events: Record<keyof T, CallbackFn<any>[]>;

    constructor() {
        this.events = {} as any;
    }

    on<K extends keyof T>(event: K, callback: CallbackFn<T[K]>) {
        if (!this.events[event]) {
            this.events[event] = [];
        }

        this.events[event].push(callback);

        return {
            off: () => this.off(event, callback),
        };
    }

    off<K extends keyof T>(event: K, callback: CallbackFn<T[K]>) {
        if (this.events[event] && callback) {
            this.events[event] = this.events[event].filter((x) => x !== callback);
        }
    }

    offAll(event: keyof T) {
        if (this.events[event]) {
            delete this.events[event];
        }
    }

    emit<K extends keyof T>(event: K, payload: T[K]) {
        if (this.events[event]) {
            this.events[event].forEach((callback: CallbackFn<T[K]>) => {
                callback(payload);
            });
        }
    }
}
