import type { ComponentParams, ComponentRendering } from "@sitecore-jss/sitecore-jss-nextjs";
import { Placeholder } from "@sitecore-jss/sitecore-jss-nextjs";
import { Accordion as ChakraAccordion } from "@chakra-ui/react";
import type { GenericHeaderProps } from "commons/ui/GenericHeader";
import GenericHeader from "commons/ui/GenericHeader";

export type AccordionProps = {
    rendering: ComponentRendering & { params: ComponentParams };
    params: { [key: string]: string };
    fields: Fields;
};

interface Fields extends GenericHeaderProps {
    fields: Fields;
}

const Accordion = (props: AccordionProps) => {
    const phKey = `Accordion-${props.params?.DynamicPlaceholderId}`;
    if (!props.fields) return null;
    return (
        <GenericHeader
            Id={props.rendering.uid ?? props.rendering.componentName}
            AnchorId={props.fields?.AnchorId}
            HeaderHeadline={props.fields?.HeaderHeadline}
            HeaderSubHeadline={props.fields?.HeaderSubHeadline}
            HeaderCTA={props.fields?.HeaderCTA}
            headlineSize={props.params?.HeaderHeadlineSize}
            spacingSize={props.params?.Padding}
        >
            <ChakraAccordion
                variant="ContentAccordion"
                sx={{ counterReset: "line-number" }}
                allowToggle
                role="group"
                aria-labelledby={`GH_${props.rendering?.uid}`}
            >
                <Placeholder name={phKey} rendering={props.rendering} />
            </ChakraAccordion>
        </GenericHeader>
    );
};
export default Accordion;
