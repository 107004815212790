import { useCallback, useEffect } from "react";

export const useScroll = (callback: () => void, callbefore?: () => void) => {
    const handleScroll = useCallback(() => {
        if (typeof callback === "function") {
            callback();
        }
    }, [callback]);

    useEffect(() => {
        if (typeof callbefore === "function") {
            callbefore();
        }
        handleScroll();
        window.addEventListener("scroll", handleScroll);
        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, [callbefore, handleScroll]);
};
