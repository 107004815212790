import { Link } from "@chakra-ui/react";
import type { LinkProps } from "@chakra-ui/react";
import { Link as JssLink, useSitecoreContext } from "@sitecore-jss/sitecore-jss-nextjs";
import type { LinkField } from "@sitecore-jss/sitecore-jss-nextjs";
import NextLink from "next/link";
interface SCLinkProps extends Omit<LinkProps, "href"> {
    linkField?: LinkField;
}

export const getLinkText = (props: LinkField): string => {
    if (props?.value.text) {
        return String(props.value.text);
    } else if (props.value.title) {
        return String(props.value.title);
    }
    return "";
};

const SCLink = ({ linkField, ...props }: SCLinkProps) => {
    const {
        sitecoreContext: { pageEditing },
    } = useSitecoreContext();

    if (!linkField) return null;

    const linkTitle = getLinkText(linkField);

    if (!pageEditing && (linkField?.value?.href || linkField?.value?.url) && linkTitle !== "") {
        return (
            <Link
                as={NextLink}
                href={linkField.value.href}
                {...(linkField.value.target && { target: linkField.value.target })}
                {...props}
            >
                {linkTitle}
            </Link>
        );
    } else if (pageEditing) {
        return (
            <Link as="span" {...props}>
                <JssLink field={linkField} />
            </Link>
        );
    }
    return null;
};

export default SCLink;
