import type { ComponentParams, ComponentRendering, Field } from "@sitecore-jss/sitecore-jss-nextjs";
import {
    Heading,
    IconButton,
    Link,
    List,
    ListItem,
    Text,
    Box,
    Stack,
    FormControl,
    VisuallyHidden,
    FormLabel,
    Input,
} from "@chakra-ui/react";
import type { FormEvent } from "react";
import { useEffect, useState, useRef, useCallback } from "react";
import { useRouter } from "next/router";
import NextLink from "next/link";
import { useI18n } from "next-localization";
import type { GenericHeaderProps } from "commons/ui/GenericHeader";
import GenericHeader from "commons/ui/GenericHeader";
import BundleIcon from "commons/ui/BundleIcon";
import { SkeletonContainerList } from "commons/ui/SkeletonContainer";
import { FilterLoadmoreHandler } from "commons/ui/FilterUI";

interface SearchResult {
    title: string;
    snippet: string;
    link: string;
    formattedUrl: string;
}

interface SearchResultData {
    results: SearchResult[];
    nextPage: number;
    totalResults: number;
    firstNewResultIndex: number;
    hasNext: boolean;
}

interface Fields extends GenericHeaderProps {
    AnkiroSearchProfile: Field<string>;
}

export type SearchResultsProps = {
    rendering: ComponentRendering & { params: ComponentParams };
    params: { [key: string]: string };
    fields: Fields;
};

const numberOfResultsPerPage = 10;

const SearchResults = (props: SearchResultsProps) => {
    const { t } = useI18n();

    const { asPath, query, push, isReady } = useRouter();
    const pageUrl = asPath.split("?")[0];

    const inputRef = useRef<HTMLInputElement>(null);

    const [searchResults, setSearchResults] = useState<SearchResultData>({
        results: [],
        nextPage: 0,
        totalResults: 0,
        firstNewResultIndex: -1,
        hasNext: false,
    });
    const [queryString, setQueryString] = useState<string>("");
    const [startIndex, setStartIndex] = useState<number>(0);
    const [loading, setLoading] = useState<boolean>(false);

    const firstNewResultRef = useRef<HTMLAnchorElement>(null);

    const fetchSearchResults = useCallback(async () => {
        setLoading(true);
        try {
            const queryDidChange = query.q !== queryString;
            const termUrlQueryString = `searchTerms=${query.q}&startIndex=${startIndex}&lang=en`;
            const termUrl = `/api/page-search?${termUrlQueryString}`;

            const response = await fetch(termUrl);
            if (!response.ok) {
                throw new Error("Network response was not ok");
            }

            const searchData: SearchResultData = await response.json();
            if (searchData.results.length > 0) {
                setSearchResults((prevResults) => ({
                    results: queryDidChange
                        ? [...searchData.results]
                        : [...prevResults.results, ...searchData.results],
                    nextPage: searchData.nextPage,
                    totalResults: searchData.totalResults,
                    firstNewResultIndex: queryDidChange ? 0 : prevResults.results.length,
                    hasNext: searchData.hasNext,
                }));

                if (searchData.nextPage) {
                    setStartIndex(searchData.nextPage);
                }
            } else {
                setSearchResults({
                    results: [],
                    nextPage: 0,
                    totalResults: 0,
                    firstNewResultIndex: -1,
                    hasNext: false,
                });
            }
            setLoading(false);
        } catch (error) {
            // eslint-disable-next-line no-console
            console.error("Fetch Error :-S", error);
        } finally {
            setLoading(false);
        }
    }, [query.q, queryString, startIndex]);

    const renderLoadMoreButtonLabel = ({
        t,
        loading,
        hasNext,
    }: {
        t: (key: string) => string;
        loading: boolean;
        hasNext: boolean;
    }): string => {
        if (loading) {
            return t("loadMoreLoadingA11yText");
        } else if (hasNext) {
            return t("fetchMoreResultsA11yText");
        } else {
            return "";
        }
    };

    const onSubmit = (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        const formData = new FormData(event.currentTarget);
        push({ pathname: pageUrl, query: { q: formData.get("q") as string } });
    };

    const clearSearch = () => {
        if (inputRef.current) {
            inputRef.current.value = "";
        }
        push({ pathname: pageUrl, query: { q: "" } });
    };

    useEffect(() => {
        if (isReady) {
            fetchSearchResults();
            setQueryString(query.q as string);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [query.q]);

    useEffect(() => {
        if (
            isReady &&
            firstNewResultRef?.current &&
            searchResults.hasNext &&
            searchResults.results.length > numberOfResultsPerPage
        ) {
            firstNewResultRef?.current.focus();
            firstNewResultRef?.current.scrollIntoView({
                behavior: "smooth",
                block: "start",
                inline: "center",
            });
        }
    }, [
        searchResults.firstNewResultIndex,
        isReady,
        searchResults.hasNext,
        searchResults.results.length,
    ]);

    if (!props.fields) return null;

    return (
        <GenericHeader
            Id={props.rendering?.uid ?? props.rendering?.componentName}
            AnchorId={props.fields?.AnchorId}
            HeaderHeadline={props.fields?.HeaderHeadline}
            HeaderSubHeadline={props.fields?.HeaderSubHeadline}
            HeaderCTA={props.fields?.HeaderCTA}
            headlineSize={props.params?.HeaderHeadlineSize}
            spacingSize={props.params?.Padding}
        >
            <Box position="relative">
                <form
                    onSubmit={onSubmit}
                    role="search"
                    aria-labelledby={`GH_${props.rendering?.uid}`}
                >
                    <FormControl position={"relative"}>
                        <VisuallyHidden>
                            <FormLabel htmlFor="search-input">{t("searchPlaceholder")}</FormLabel>
                        </VisuallyHidden>
                        <Input
                            ref={inputRef}
                            id="search-input"
                            type="search"
                            name="q"
                            inputMode="search"
                            autoComplete="one-time-code"
                            placeholder={t("searchPlaceholder")}
                            borderRadius="base"
                            px="sp16"
                            py="sp32"
                            pr={"sp120"}
                            defaultValue={query.q as string}
                            onKeyUp={(e) => {
                                if (e.key === "Enter") {
                                    const value = e.currentTarget.value;
                                    push({ pathname: pageUrl, query: { q: value } });
                                }
                            }}
                            _focusVisible={{
                                outline: "1px dashed black",
                                outlineOffset: "2px",
                            }}
                        />
                        <Box
                            position="absolute"
                            height="full"
                            top="0"
                            right="0"
                            display="flex"
                            zIndex={1}
                        >
                            <IconButton
                                type="reset"
                                variant={"outline"}
                                border="0"
                                onClick={(e) => {
                                    e.preventDefault();
                                    clearSearch();
                                }}
                                size="lg"
                                borderRadius={0}
                                p={{ base: "sp24", lg: "sp20" }}
                                height="full"
                                sx={{
                                    svg: {
                                        height: "full",
                                        width: "full",
                                    },
                                }}
                                aspectRatio={"1/1"}
                                borderLeft={0}
                                _hover={{
                                    bg: "black",
                                    svg: {
                                        fill: "white",
                                    },
                                }}
                                icon={<BundleIcon name="Close" />}
                                aria-label={t("resetButtonLabel")}
                            />
                            <IconButton
                                variant={"primary"}
                                size="lg"
                                borderTopLeftRadius={0}
                                borderBottomLeftRadius={0}
                                border={"1px solid black"}
                                borderLeft="0"
                                aspectRatio={"1/1"}
                                p={{ base: "sp24", lg: "sp20" }}
                                height="full"
                                sx={{
                                    svg: {
                                        height: "full",

                                        width: "full",
                                    },
                                }}
                                _hover={{
                                    bg: "white",

                                    svg: {
                                        fill: "black",
                                    },
                                }}
                                icon={<BundleIcon name="ArrowForward" />}
                                type="submit"
                                aria-label={t("searchPlaceholder")}
                            />
                        </Box>
                    </FormControl>
                </form>
            </Box>

            <VisuallyHidden role="status" aria-live="polite">
                {renderLoadMoreButtonLabel({ t, loading, hasNext: searchResults.hasNext })}
            </VisuallyHidden>

            {!loading && searchResults.results.length === 0 && query.q && (
                <Box mt={{ base: "sp40", lg: "sp88" }}>
                    <Text as="p" size="bodyXl">
                        {t("noSearchResults")}
                    </Text>
                </Box>
            )}

            {searchResults.results.length > 0 && (
                <List
                    display={"flex"}
                    mt={{ base: "sp40", lg: "sp88" }}
                    flexDir={"column"}
                    gap={"sp40"}
                >
                    {searchResults.results.map((result: SearchResult, index: number) => {
                        const isFirstNewResult = index === searchResults.firstNewResultIndex;
                        return (
                            <ListItem key={result.link}>
                                <Link
                                    as={NextLink}
                                    ref={isFirstNewResult ? firstNewResultRef : null}
                                    href={result.link}
                                    variant={"searchLink"}
                                    {...(index === 0 && {
                                        ref: firstNewResultRef,
                                    })}
                                >
                                    <Stack spacing={{ base: "sp8", lg: "sp16" }}>
                                        <Heading
                                            as="h3"
                                            size="sm"
                                            textDecoration={"underline"}
                                            lineHeight={"base"}
                                        >
                                            {result.title}
                                        </Heading>
                                        <Text
                                            size="bodySmall"
                                            sx={{
                                                ".max": {
                                                    fontWeight: "bold",
                                                },
                                            }}
                                            dangerouslySetInnerHTML={{ __html: result.snippet }}
                                        />
                                        <Text
                                            size="bodySmall"
                                            dangerouslySetInnerHTML={{
                                                __html: result.formattedUrl,
                                            }}
                                        />
                                    </Stack>
                                </Link>
                            </ListItem>
                        );
                    })}
                </List>
            )}
            {loading && isReady && (
                <SkeletonContainerList numberOfResultsPerPage={numberOfResultsPerPage} />
            )}

            <FilterLoadmoreHandler
                loading={loading}
                hasNext={searchResults.hasNext}
                fetchSearchResults={fetchSearchResults}
                ariaControls={props.rendering.uid}
            />
        </GenericHeader>
    );
};

export default SearchResults;
