import { Button } from "@chakra-ui/react";
import type { ButtonProps } from "@chakra-ui/react";
import NextLink from "next/link";
import type { LinkProps } from "next/link";

interface LinkButtonProps
    extends Omit<LinkProps, "as" | "onClick" | "onMouseEnter" | "onTouchStart">,
        ButtonProps {
    children: React.ReactNode;
}

const LinkButton = ({ href, children, ...props }: LinkButtonProps) => {
    return (
        <Button as={NextLink} href={href} variant={props.variant} size={props.size} {...props}>
            {children}
        </Button>
    );
};

export default LinkButton;
