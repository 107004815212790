import type {
    ComponentParams,
    ComponentRendering,
    Field,
    HtmlElementRendering,
} from "@sitecore-jss/sitecore-jss-nextjs";
import { Placeholder, useSitecoreContext } from "@sitecore-jss/sitecore-jss-nextjs";
import { Box } from "@chakra-ui/react";
import BundleIcon from "commons/ui/BundleIcon";
import { useEffect, useRef, useState, useCallback } from "react";
import type { GenericHeaderProps } from "commons/ui/GenericHeader";
import GenericHeader from "commons/ui/GenericHeader";
import SCHeading from "commons/sc/SCHeading";

interface Fields extends GenericHeaderProps {
    AnswerHeadline: Field<string>;
    QuestionHeadline: Field<string>;
}

export type QAListProps = {
    rendering: ComponentRendering & { params: ComponentParams };
    params: { [key: string]: string };
    fields: Fields;
};

const QAList = (props: QAListProps) => {
    const {
        sitecoreContext: { pageEditing },
    } = useSitecoreContext();

    const ref = useRef<HTMLDivElement>(null);
    const QAListItems =
        props.rendering.placeholders && Object.values(props.rendering.placeholders)[0];

    const phKey = `QAList-${props.params?.DynamicPlaceholderId}`;

    const [activeItemIndex, setActiveItemIndex] = useState<string>("");

    function isScrolledIntoView(div: HTMLElement, elem: HTMLElement) {
        const docViewTop = div.scrollLeft;
        const docViewBottom = docViewTop + div.offsetWidth;

        const elemTop = elem.offsetLeft;
        const elemBottom = elemTop + elem.offsetWidth;

        return elemBottom <= docViewBottom && elemTop >= docViewTop;
    }

    const handleScroll = useCallback(() => {
        const div = ref.current;
        const scrollPosition = div?.scrollLeft;
        if (scrollPosition) {
            const children = div?.children;
            if (children) {
                const activeChild = Array.from(children).find((child: HTMLElement) =>
                    isScrolledIntoView(div, child)
                );

                if (activeChild) {
                    setActiveItemIndex(activeChild.id.split("qa-element-")[1]);
                }
            }
        }
    }, [ref, setActiveItemIndex]);

    useEffect(() => {
        if (!pageEditing) {
            const scrollContainer = ref.current;
            if (scrollContainer) {
                scrollContainer.addEventListener("scroll", handleScroll);
            }
            return () => {
                scrollContainer?.removeEventListener("scroll", handleScroll);
            };
        }
        return undefined;
    }, [handleScroll, pageEditing]);

    if (!props.fields) return null;

    return (
        <GenericHeader
            Id={props.rendering.uid ?? props.rendering.componentName}
            AnchorId={props.fields?.AnchorId}
            HeaderHeadline={props.fields?.HeaderHeadline}
            HeaderSubHeadline={props.fields?.HeaderSubHeadline}
            HeaderCTA={props.fields?.HeaderCTA}
            headlineSize={props.params?.HeaderHeadlineSize}
            spacingSize={props.params?.Padding}
        >
            <Box
                bg={{ base: "transparent", lg: "brand.lighterGray" }}
                mx={{ base: 0, lg: "-sp80" }}
                display={"grid"}
            >
                <Box
                    display={{ base: "none", lg: "grid" }}
                    p={{ base: "sp16", lg: 0 }}
                    px={{ base: 0, lg: "sp80" }}
                    pt={{ base: 0, lg: "sp24" }}
                    pb={{ base: 0, lg: "sp24" }}
                    gridTemplateColumns={{ lg: "1fr auto 1fr" }}
                    gap="sp48"
                    borderBottom={"1px solid"}
                    borderColor={"brand.lightGray"}
                >
                    <SCHeading as="h3" textField={props.fields?.QuestionHeadline} />
                    <BundleIcon aria-hidden="true" opacity="0" name="ArrowForwardIos" />
                    <SCHeading as="h3" textField={props.fields?.AnswerHeadline} />
                </Box>
                <Box
                    ref={ref}
                    display="flex"
                    flexDirection={{ base: "row", lg: "column" }}
                    gap={{ base: "sp16", lg: 0 }}
                    overflowX={{ base: "scroll", lg: "auto" }}
                    scrollSnapType={{ base: "x mandatory", lg: "none" }}
                    position={"relative"}
                    px="1px"
                    mx="-1px"
                    sx={{ scrollbarWidth: "none" }}
                >
                    <Placeholder
                        name={phKey}
                        rendering={props.rendering}
                        params={{
                            AnswerHeadline: props.fields?.AnswerHeadline.value,
                            QuestionHeadline: props.fields?.QuestionHeadline.value,
                        }}
                    />
                </Box>
                <Box
                    justifyContent={"center"}
                    mt="sp32"
                    gap="sp8"
                    display={{ base: "flex", lg: "none" }}
                >
                    {QAListItems?.map(
                        (item: ComponentRendering | HtmlElementRendering, index: number) => (
                            <Box
                                width="8px"
                                aspectRatio={1}
                                borderRadius="50%"
                                key={`box-${(item as { uid: string }).uid}`}
                                bg="black"
                                opacity={
                                    activeItemIndex === (item as { uid: string }).uid ||
                                    (activeItemIndex === "" && index === 0)
                                        ? "1"
                                        : "0.35"
                                }
                            />
                        )
                    )}
                </Box>
            </Box>
        </GenericHeader>
    );
};

export default QAList;
