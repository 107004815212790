import { AspectRatio, Box, Button, Grid, GridItem } from "@chakra-ui/react";
import {
    withDatasourceCheck,
    type ComponentParams,
    type ComponentRendering,
    type GetStaticComponentProps,
} from "@sitecore-jss/sitecore-jss-nextjs";

import GenericHeader, { type GenericHeaderProps } from "commons/ui/GenericHeader";
import { useCallback, useEffect, useState } from "react";
import { ModalNextArrow, ModalOverlay, ModalPrevArrow } from "commons/ModalOverlay";
import graphqlClientFactory from "lib/graphql-client-factory";
import useImageSizes from "utils/hooks/useImageSizes";
import { ChakraNextImage } from "commons/sc/SCImage";
import { SkeletonContainer } from "commons/ui/SkeletonContainer";

interface ImageGallery {
    item: { imagePath: { value: string } };
}

interface ImageResult {
    id: string;
    url: {
        url: string;
    };
    altText: {
        value: string;
    } | null;
    width: {
        value: string;
    };
    height: {
        value: string;
    };
}

interface ImagePath {
    results: ImageResult[];
}

export type ImageGalleryProps = {
    rendering: ComponentRendering & { params: ComponentParams };
    params: { [key: string]: string };
    fields: GenericHeaderProps;
    ImagePath: ImagePath;
};

const ImageGallery = (props: ImageGalleryProps) => {
    const sizes = useImageSizes({ base: 1, sm: 1 / 2, md: 1 / 3 }, true);

    const [openImgIndex, setOpenImgIndex] = useState<number>();
    const [imagesLoading, setImagesLoading] = useState<boolean[]>(
        Array(props.ImagePath.results.length).fill(true)
    );
    const [modalImageLoading, setModalImageLoading] = useState<boolean>(true);
    const images = props.ImagePath.results;
    const modalOpen = openImgIndex != undefined;

    const goToNext = useCallback(() => {
        let newIndex = (openImgIndex ?? 0) + 1;
        if (newIndex >= images.length) newIndex = 0;

        setModalImageLoading(true);
        setOpenImgIndex(newIndex);
    }, [images, openImgIndex]);

    const goToPrev = useCallback(() => {
        let newIndex = (openImgIndex ?? 0) - 1;
        if (newIndex < 0) newIndex = images.length - 1;

        setModalImageLoading(true);
        setOpenImgIndex(newIndex);
    }, [images, openImgIndex]);

    const openOverlay = (index: number) => {
        setModalImageLoading(true);
        setOpenImgIndex(index);
    };

    const closeOverlay = () => {
        setOpenImgIndex(undefined);
    };

    const handleImageLoad = (index: number) => {
        const newLoadingStates = [...imagesLoading];
        newLoadingStates[index] = false;
        setImagesLoading(newLoadingStates);
    };

    useEffect(() => {
        const handleKeyDown = (event: KeyboardEvent) => {
            if (!modalOpen) return;

            if (event.key === "ArrowLeft") {
                goToPrev();
            } else if (event.key === "ArrowRight") {
                goToNext();
            }
        };

        window.addEventListener("keydown", handleKeyDown);

        return () => {
            window.removeEventListener("keydown", handleKeyDown);
        };
    }, [goToNext, goToPrev, modalOpen]);

    return (
        <GenericHeader
            Id={props.rendering?.uid ?? props.rendering?.componentName}
            AnchorId={props.fields?.AnchorId}
            HeaderHeadline={props.fields?.HeaderHeadline}
            HeaderSubHeadline={props.fields?.HeaderSubHeadline}
            HeaderCTA={props.fields?.HeaderCTA}
            headlineSize={props.params?.HeaderHeadlineSize}
            spacingSize={props.params?.Padding}
        >
            <Grid
                templateColumns={{
                    base: "repeat(1, 1fr)",
                    sm: "repeat(2, 1fr)",
                    md: "repeat(3, 1fr)",
                }}
                columnGap="sp12"
                rowGap="sp36"
            >
                {images.map((image, index) => (
                    <GridItem key={image.id} onClick={() => openOverlay(index)} cursor="pointer">
                        <SkeletonContainer isLoaded={!imagesLoading[index]}>
                            <AspectRatio ratio={16 / 9}>
                                <ChakraNextImage
                                    src={image.url.url}
                                    alt={image.altText?.value ?? ""}
                                    fill
                                    sizes={sizes}
                                    onLoad={() => handleImageLoad(index)} // Call onLoad
                                />
                            </AspectRatio>
                        </SkeletonContainer>
                    </GridItem>
                ))}
            </Grid>

            {modalOpen && (
                <ModalOverlay
                    closeOverlay={closeOverlay}
                    next={
                        <Button variant="unstyled" onClick={goToNext}>
                            <ModalNextArrow />
                        </Button>
                    }
                    prev={
                        <Button variant="unstyled" onClick={goToPrev}>
                            <ModalPrevArrow />
                        </Button>
                    }
                >
                    <Box
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        w="100%"
                        h="100%"
                    >
                        <SkeletonContainer
                            width={parseInt(images[openImgIndex].width?.value) ?? 0}
                            height={parseInt(images[openImgIndex].height?.value) ?? 0}
                            isLoaded={!modalImageLoading}
                        >
                            <ChakraNextImage
                                src={images[openImgIndex].url.url}
                                alt={images[openImgIndex].altText?.value ?? ""}
                                width={parseInt(images[openImgIndex].width?.value) ?? 0}
                                height={parseInt(images[openImgIndex].height?.value) ?? 0}
                                quality={95}
                                objectFit="contain"
                                style={{
                                    maxHeight: "100%",
                                    width: "auto",
                                }}
                                onLoad={() => setModalImageLoading(false)}
                            />
                        </SkeletonContainer>
                    </Box>
                </ModalOverlay>
            )}
        </GenericHeader>
    );
};

export const getStaticProps: GetStaticComponentProps = async (rendering, layoutData) => {
    const graphQLClient = graphqlClientFactory();

    // Retrieve ImageGallery data. Only need the ImagePath to retrieve the media folder
    const queryDataSource = `
    query search($datasource: String!, $language: String!) {
        item(path: $datasource, language: $language) {
            ...on ImageGallery {
                imagePath: field(name: "ImagePath") {
                    value
                }
            }
        }
    }`;

    const imageGallery = await graphQLClient.request<ImageGallery>(queryDataSource, {
        datasource: rendering.dataSource,
        contextItem: layoutData?.sitecore?.route?.itemId,
        language: layoutData?.sitecore?.context?.language,
    });

    // ImagePath not defined
    if (!imageGallery?.item?.imagePath?.value)
        return {
            ImagePath: { results: [] },
        };

    // Retrieve images inside the media folder (Image Path)
    const queryImages = `
    query {
        ImagePath: search(
            where: {
            AND: [
                {
                name: "_path"
                value: "${imageGallery.item.imagePath.value}"
                operator: CONTAINS
                }
            ]
            }
            first: 30
            after: ""
        ) {
            total
            pageInfo {
                endCursor
                hasNext
            }
            results {
                id
                url {
                    url
                }
                altText: field(name: "Alt") {
                    value
                }
                width: field(name: "Width") {
                    value
                }
                height: field(name: "Height") {
                    value
              }
            }
        }
    }`;

    return await graphQLClient.request<ImageGalleryProps>(queryImages);
};

export default withDatasourceCheck()<ImageGalleryProps>(ImageGallery);
