import type { ImageField, Field } from "@sitecore-jss/sitecore-jss-nextjs";
import { Box } from "@chakra-ui/react";

import SCImage from "commons/sc/SCImage";
import SCText from "commons/sc/SCText";
import useImageSizes, { imageSize } from "utils/hooks/useImageSizes";

interface Fields {
    CaptionHeadline: Field<string>;
    CaptionText: Field<string>;
    Image: ImageField;
}

export type ImageProps = {
    params: { [key: string]: string };
    fields: Fields;
};

const ImageComponent = (props: ImageProps) => {
    const sizes = useImageSizes({ base: 1, xl: 1 / 2 });

    if (!props.fields) return null;
    return (
        <>
            <Box>
                <SCImage
                    imageField={props.fields?.Image}
                    {...imageSize(Number(props.fields?.Image?.value?.width), 4, 3)}
                    sizes={sizes}
                    sx={{
                        borderRadius: "base",
                        aspectRatio: "4/3",
                        objectFit: "cover",
                        width: "100%",
                        height: "auto",
                    }}
                />
            </Box>
            <Box mt="sp24" lineHeight="tall">
                <SCText textField={props.fields?.CaptionHeadline} size="imageCaptionHeadline" />
                <SCText size="bodySmall" textField={props.fields?.CaptionText} />
            </Box>
        </>
    );
};
export default ImageComponent;
