import type { ComponentParams, ComponentRendering } from "@sitecore-jss/sitecore-jss-nextjs";
import { Box, Button, Container, Text } from "@chakra-ui/react";
import type { Dispatch, SetStateAction } from "react";
import { useEffect, useState } from "react";
import type { HeroCarouselElementProps } from "./HeroCarouselElement";
import HeroCarouselElement from "./HeroCarouselElement";
import { translateXin } from "themes/foundations/animations";
import { useI18n } from "next-localization";

interface HeroProgressBarProps extends HeroCarouselElementProps {
    setCurrentSlideIndex: Dispatch<SetStateAction<number>>;
    setFirstLoad: Dispatch<SetStateAction<boolean>>;
    totalSlides: number;
    loaded: boolean;
    paused: boolean;
}
interface Fields {
    Elements: HeroCarouselElementProps[];
}

export type HeroCarouselProps = {
    rendering: ComponentRendering & { params: ComponentParams };
    params: { [key: string]: string };
    fields: Fields;
};

// Seconds
const carouselDurationSeconds = 10;

const HeroProgressBar = ({
    index,
    currentSlideIndex,
    setCurrentSlideIndex,
    firstLoad,
    setFirstLoad,
    totalSlides,
    loaded,
    paused,
    ...props
}: HeroProgressBarProps) => {
    const { t } = useI18n();
    const currentSlide = currentSlideIndex === index;
    const procentageHidden = 100 / totalSlides + "%";

    const heroCarouselAriaLabel = t("heroCarouseSlideAriaLabel")
        ? t("heroCarouseSlideAriaLabel")
        : "Go to hero slide {0}";

    return (
        <Box
            sx={{
                flexBasis: procentageHidden,
                overflow: "hidden",
            }}
            p={"sp4"}
        >
            <Button
                variant={"heroProgressBar"}
                p={"1px"}
                onClick={() => {
                    setCurrentSlideIndex(index);
                    setFirstLoad(false);
                }}
                aria-label={heroCarouselAriaLabel.replace("{0}", `${index + 1}`)}
                aria-current={currentSlide ? true : undefined}
            >
                <Text
                    as="span"
                    noOfLines={1}
                    size={"heroProgressBarText"}
                    sx={{
                        opacity: currentSlide ? "1" : ".3",
                        transition: currentSlide ? "opacity 0.3s ease-in" : "opacity .3s ease-out ",
                    }}
                    _hover={{
                        opacity: "1",
                    }}
                    _focusVisible={{
                        opacity: "1",
                    }}
                >
                    {props.fields?.SliderText.value}
                </Text>
                <Box
                    sx={{
                        bg: "rgba(255,255,255,0.3)",
                        height: "2px",
                        mt: "sp16",
                        position: "relative",
                    }}
                >
                    <Box
                        {...(currentSlide && {
                            animation: `${translateXin} ${carouselDurationSeconds}s linear forwards`,
                        })}
                        onAnimationEnd={() => {
                            if (!paused) {
                                if (currentSlideIndex < totalSlides - 1) {
                                    setCurrentSlideIndex(currentSlideIndex + 1);
                                } else {
                                    setCurrentSlideIndex(0);
                                }
                                if (firstLoad) setFirstLoad(false);
                            }
                        }}
                        sx={{
                            bg: currentSlide && "white",
                            height: "full",
                            width: "full",
                            animationPlayState: paused || !loaded ? "paused" : "running",
                        }}
                    ></Box>
                </Box>
            </Button>
        </Box>
    );
};

const HeroCarousel = (props: HeroCarouselProps) => {
    const [currentSlideIndex, setCurrentSlideIndex] = useState<number>(0);
    const [firstLoad, setFirstLoad] = useState<boolean>(true);
    const totalSlides = props.fields?.Elements.length;
    const [loaded, setLoaded] = useState<boolean>(false);
    const [paused, setPaused] = useState<boolean>(false);

    useEffect(() => {
        const mediaQuery = window.matchMedia("(prefers-reduced-motion: reduce)");
        if (mediaQuery.matches) {
            setPaused(true);
        }
        setLoaded(true);
    }, []);

    if (!props.fields) return null;

    return (
        <Box
            as="section"
            sx={{
                position: "relative",
                overflow: "hidden",
                height: "95svh",
                color: "white",
                marginTop: [
                    "calc(var(--sizes-headerMobileTopHeight) * -1)",
                    "calc(var(--sizes-headerTopHeight) * -1)",
                ],
            }}
        >
            <>
                <Button
                    position="absolute"
                    top="50%"
                    left="50%"
                    transform="translate(-50%, -50%)"
                    bg="black"
                    color="white"
                    px={35}
                    py={20}
                    borderRadius={100}
                    height={30}
                    display={["none", null, null, "flex"]}
                    justifyContent="center"
                    alignItems="center"
                    zIndex={1}
                    opacity={0}
                    textTransform="uppercase"
                    transition="all 0.1s, opacity 0.25s, z-index 0s ease 0.25s"
                    _focusVisible={{
                        outline: "1px dotted black",
                        boxShadow: "0 0 0 2px white",
                        outlineOffset: "2px",
                        transition: "all 0.1s, opacity 0.25s, z-index 0s",
                        opacity: 1,
                        zIndex: 101,
                    }}
                    onClick={() => {
                        setPaused(!paused);
                    }}
                >
                    {paused ? "Play content slider" : "Pause content slider"}
                </Button>
                {props.fields?.Elements.map((element, index) => {
                    return (
                        <HeroCarouselElement
                            key={element.id + "heroitem"}
                            {...element}
                            index={index}
                            currentSlideIndex={currentSlideIndex}
                            firstLoad={firstLoad}
                            loaded={loaded}
                        />
                    );
                })}

                {props.fields?.Elements.length > 1 && (
                    <Box
                        sx={{
                            position: "absolute",
                            bottom: { base: "4svh", lg: "6.4svh" },
                            left: 0,
                            zIndex: 5,
                            width: "full",
                        }}
                    >
                        <Container
                            sx={{
                                display: "flex",
                                gap: "sp4",
                            }}
                        >
                            {props.fields?.Elements.map(
                                (element: HeroCarouselElementProps, index: number) => (
                                    <HeroProgressBar
                                        key={element.id + "heroprogress"}
                                        {...element}
                                        setCurrentSlideIndex={setCurrentSlideIndex}
                                        firstLoad={firstLoad}
                                        setFirstLoad={setFirstLoad}
                                        totalSlides={totalSlides}
                                        currentSlideIndex={currentSlideIndex}
                                        index={index}
                                        loaded={loaded}
                                        paused={paused}
                                    />
                                )
                            )}
                        </Container>
                    </Box>
                )}
            </>
        </Box>
    );
};
export default HeroCarousel;
