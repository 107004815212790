import { Flex, Button, useBreakpointValue } from "@chakra-ui/react";
import type { FlexProps } from "@chakra-ui/react";
import { useMemo } from "react";
import { useI18n } from "next-localization";

interface DotsProps {
    carouselId?: string;
    slidesToShowValue: number;
    childrenCount: number;
    activeDotIndex: number;
    scrollToElement: (index: number) => void;
}

const CarouselDots = (props: FlexProps & DotsProps) => {
    const { t } = useI18n();
    const dotAriaLabel = t("carouselDotAriaLabel") ? t("carouselDotAriaLabel") : "Go to slide {0}";
    const isMobileViewport = useBreakpointValue({ base: true, lg: false }, { ssr: true });
    const {
        carouselId,
        slidesToShowValue,
        childrenCount,
        activeDotIndex,
        scrollToElement,
        ...rest
    } = props;
    const dotsLength = useMemo(
        () => Math.ceil(childrenCount / slidesToShowValue),
        [childrenCount, slidesToShowValue]
    );

    return (
        <Flex justifyContent="center" mt="sp32" gap="sp8" {...rest}>
            {Array.from({ length: dotsLength }, (_element, i) => {
                const opacity = i === activeDotIndex ? 1 : 0.35;
                return (
                    <Button
                        {...(isMobileViewport
                            ? {
                                  as: "span",
                              }
                            : {
                                  onClick: () => scrollToElement(i),
                                  "aria-label": dotAriaLabel.replace("{0}", `${i + 1}`),
                                  "aria-current": i === activeDotIndex ? true : undefined,
                              })}
                        cursor="pointer"
                        variant="sliderDot"
                        borderRadius="50%"
                        className={`${carouselId}_dot`}
                        key={`${carouselId}_dot_${i}`}
                        id={`${carouselId}_dot_${i}`}
                        bg="black"
                        opacity={opacity}
                    />
                );
            })}
        </Flex>
    );
};

export default CarouselDots;
