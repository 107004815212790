import { Box } from "@chakra-ui/react";
import type { Field, ImageField } from "@sitecore-jss/sitecore-jss-nextjs";
import ArticleCard from "commons/ui/ArticleCard";

interface Fields {
    PageListImage?: ImageField;
    PageListTagline: {
        fields: {
            Tagline: Field<string>;
        };
    };
    Date: Field<string>;
    PageListHeadline: Field<string>;
    PageListTeaserText: Field<string>;
}

export type ArticleListCardProps = {
    id?: string;
    fields: Fields;
    url: string;
    carouselIndex: number;
};

const calcImgVariant = (index?: number) => {
    let imgVariant = [1, null, null, 3 / 2];
    if (index) {
        if (index % 2 !== 0) {
            imgVariant = [1, null, null, 4 / 3];
        } else if (index % 4 !== 0) {
            imgVariant = [1];
        }
    }

    return imgVariant;
};

const ArticleListCard = (props: ArticleListCardProps) => {
    const imgVariant = calcImgVariant(props.carouselIndex);

    return (
        <Box data-group height="unset">
            <ArticleCard
                imgVariant={imgVariant}
                id={props.id}
                fields={props.fields}
                url={props.url}
            />
        </Box>
    );
};

export default ArticleListCard;
