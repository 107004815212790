/* eslint-disable sonarjs/no-nested-template-literals */
import type { FunctionComponent } from "react";
import { useState } from "react";
import type { ChakraStyledOptions } from "@chakra-ui/react";
import { Box, Button, VisuallyHidden } from "@chakra-ui/react";

const area = 48;
const lines = 3;

interface BurgerNavIconProps {
    color?: string;
    duration?: number;
    easing?: string;
    label?: string;
    onToggle?: (toggled: boolean) => void;
    animateOnMount?: boolean;
    ariaLabelledby?: string;
    ariaControls?: string;
}

export const BurgerNavIcon = (({
    color = "currentColor",
    duration = 0.4,
    easing = "cubic-bezier(0, 0, 0, 1)",
    label,
    onToggle,
    ariaLabelledby,
    ariaControls,
}) => {
    const width = Math.max(12, Math.min(area, 18));
    const room = Math.round((area - width) / 2);

    const barHeightRaw = width / 12;
    const barHeight = Math.round(barHeightRaw);

    const space = 0.75;
    const marginRaw = width / (lines * (space + (lines === 3 ? 1 : 1.25)));
    const margin = Math.round(marginRaw);

    const height = barHeight * lines + margin * (lines - 1);
    const topOffset = Math.round((area - height) / 2);

    const time = Math.max(0, duration);
    const halfTime = time / 2;

    const burgerStyles: ChakraStyledOptions = {
        height: `${area}px`,
        position: "relative",
        transition: `${time}s ${easing}`,
        userSelect: "none",
        width: `${area}px`,
        zIndex: "var(--zIndices-headerItem)",
        display: { base: "flex", lg: "none" },
        justifyContent: "flex-start",
        alignItems: "flex-start",
    };

    const barStyles: ChakraStyledOptions = {
        background: color,
        height: `${barHeight}px`,
        left: `${room}px`,
        position: "absolute",
    };

    const [isToggled, setIsToggled] = useState(false);

    const handler = () => {
        setIsToggled(!isToggled);
        if (typeof onToggle === "function") onToggle(!isToggled);
    };

    return (
        <Button
            aria-labelledby={ariaLabelledby}
            aria-expanded={isToggled}
            aria-controls={ariaControls}
            onClick={handler}
            onKeyUp={(e) => e.key === "Enter" && handler()}
            variant={"unstyled"}
            sx={burgerStyles}
        >
            <Box
                sx={{
                    transition: `${halfTime}s ${easing} ${isToggled ? "0s" : `${halfTime}s`}`,
                    transform: isToggled ? `translateY(${barHeight + margin}px)` : "none",
                }}
            >
                <Box
                    sx={{
                        ...barStyles,
                        width: `${width}px`,
                        top: `${topOffset}px`,
                        transition: `${halfTime}s ${easing} ${isToggled ? `${halfTime}s` : "0s"}`,
                        transform: isToggled ? "rotate(45deg)" : "none",
                    }}
                />
            </Box>
            <Box
                sx={{
                    transition: `${halfTime}s ${easing}`,
                    opacity: `${isToggled ? "0" : "1"}`,
                }}
            >
                <Box
                    sx={{
                        ...barStyles,
                        width: `${width}px`,
                        top: `${topOffset + barHeight + margin}px`,
                        transition: `${halfTime}s ${easing}`,
                    }}
                />
            </Box>
            <Box
                sx={{
                    transition: `${halfTime}s ${easing} ${isToggled ? "0s" : `${halfTime}s`}`,
                    transform: isToggled ? `translateY(-${barHeight + margin}px)` : "none",
                }}
            >
                <Box
                    sx={{
                        ...barStyles,
                        width: `${width}px`,
                        top: `${topOffset + barHeight * 2 + margin * 2}px`,
                        transition: `${halfTime}s ${easing} ${isToggled ? `${halfTime}s` : "0s"}`,
                        transform: isToggled ? "rotate(-45deg)" : "none",
                    }}
                />
            </Box>
            <VisuallyHidden>{label}</VisuallyHidden>
        </Button>
    );
}) as FunctionComponent<BurgerNavIconProps>;
