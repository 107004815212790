import type { ComponentRendering, ImageFieldValue } from "@sitecore-jss/sitecore-jss-nextjs";
import GenericHeader, { type GenericHeaderProps } from "commons/ui/GenericHeader";
import { SponsorsGrid, SponsorsSingleColumn } from "commons/ui/SponsorList/SponsorsLayout";
import useImageSizes from "utils/hooks/useImageSizes";

export type Sponsor = {
    id: string;
    name: string;
    displayName: string;
    url: string;
    fields: {
        Name: { value: string };
        Description: { value: string };
        Logo: { value: ImageFieldValue | undefined };
    };
};

export type SponsorListProps = {
    rendering: ComponentRendering;
    params: { [key: string]: string };
    fields: GenericHeaderProps & { Sponsors: Sponsor[] };
};

const singleCol = "repeat(1, 1fr)";
const twoCol = "repeat(2, 1fr)";

export const Default = (props: SponsorListProps) => {
    const sponsors = props.fields?.Sponsors;

    const sizes = useImageSizes({ base: 1, sm: 1 / 2, md: 1 / 3 }, true);

    return (
        <GenericHeader
            Id={props.rendering?.uid ?? props.rendering?.componentName}
            AnchorId={props.fields?.AnchorId}
        >
            <SponsorsGrid
                headline={props.fields.HeaderHeadline}
                columnTemplate={{
                    base: singleCol,
                    sm: twoCol,
                    md: "repeat(3, 1fr)",
                }}
                sizes={sizes}
                displayDescription
                sponsors={sponsors}
            />
        </GenericHeader>
    );
};

export const Columns1 = (props: SponsorListProps) => {
    const sponsors = props.fields?.Sponsors;

    const sizes = useImageSizes({ base: 1, md: 1 / 2 }, true);

    return (
        <GenericHeader
            Id={props.rendering?.uid ?? props.rendering?.componentName}
            AnchorId={props.fields?.AnchorId}
        >
            <SponsorsSingleColumn
                headline={props.fields.HeaderHeadline}
                sponsors={sponsors}
                sizes={sizes}
            />
        </GenericHeader>
    );
};

export const Columns2 = (props: SponsorListProps) => {
    const sponsors = props.fields?.Sponsors;

    const sizes = useImageSizes({ base: 1, md: 1 / 2 }, true);

    return (
        <GenericHeader
            Id={props.rendering?.uid ?? props.rendering?.componentName}
            AnchorId={props.fields?.AnchorId}
        >
            <SponsorsGrid
                headline={props.fields.HeaderHeadline}
                columnTemplate={{
                    base: singleCol,
                    md: twoCol,
                }}
                sizes={sizes}
                displayDescription
                sponsors={sponsors}
            />
        </GenericHeader>
    );
};

export const Columns4 = (props: SponsorListProps) => {
    const sponsors = props.fields?.Sponsors;

    const sizes = useImageSizes({ base: 1, sm: 1 / 2, md: 1 / 4 }, true);

    return (
        <GenericHeader
            Id={props.rendering?.uid ?? props.rendering?.componentName}
            AnchorId={props.fields?.AnchorId}
        >
            <SponsorsGrid
                headline={props.fields.HeaderHeadline}
                columnTemplate={{
                    base: singleCol,
                    sm: twoCol,
                    md: "repeat(4, 1fr)",
                }}
                sizes={sizes}
                displayDescription={false}
                sponsors={sponsors}
            />
        </GenericHeader>
    );
};
