import type { ReactNode, Dispatch, SetStateAction } from "react";
import React, { createContext, useState, useContext, useEffect, useMemo } from "react";

type HeaderBackgroundContextType = {
    hasWhiteBackground: boolean;
    setHasWhiteBackground: Dispatch<SetStateAction<boolean>>;
    hasFullBackground: boolean;
    setHasFullBackground: Dispatch<SetStateAction<boolean>>;
    mobileNavOpen: boolean;
    setMobileNavOpen: Dispatch<SetStateAction<boolean>>;
    sitename: string;
};
type HeaderBacgroundContextProp = {
    children: ReactNode;
    hasFullHero?: boolean;
    sitename: string;
};

const HeaderBackgroundContext = createContext<HeaderBackgroundContextType | undefined>({
    hasWhiteBackground: false,
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    setHasWhiteBackground: () => {},
    hasFullBackground: false,
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    setHasFullBackground: () => {},
    mobileNavOpen: false,
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    setMobileNavOpen: () => {},
    sitename: "",
});

function HeaderBackgroundProvider({
    children,
    hasFullHero = false,
    sitename,
}: Readonly<HeaderBacgroundContextProp>) {
    const [hasWhiteBackground, setHasWhiteBackground] = useState(false);
    const [hasFullBackground, setHasFullBackground] = useState(hasFullHero);
    const [mobileNavOpen, setMobileNavOpen] = useState(false);

    const contextValue = useMemo(
        () => ({
            hasWhiteBackground,
            setHasWhiteBackground,
            hasFullBackground,
            setHasFullBackground,
            mobileNavOpen,
            setMobileNavOpen,
            sitename,
        }),
        [
            hasWhiteBackground,
            setHasWhiteBackground,
            hasFullBackground,
            setHasFullBackground,
            mobileNavOpen,
            setMobileNavOpen,
            sitename,
        ]
    );

    useEffect(() => {
        setHasWhiteBackground(!hasFullHero);
        setHasFullBackground(hasFullHero);
    }, [hasFullHero]);

    return (
        <HeaderBackgroundContext.Provider value={contextValue}>
            {children}
        </HeaderBackgroundContext.Provider>
    );
}

const useHeaderBackground = (): HeaderBackgroundContextType => {
    const context = useContext(HeaderBackgroundContext);
    if (!context) {
        throw new Error("useHeaderBackground must be used within a HeaderBackgroundProvider");
    }
    return context;
};

export { HeaderBackgroundProvider, useHeaderBackground };
