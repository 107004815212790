import type { BoxProps } from "@chakra-ui/react";
import { Box } from "@chakra-ui/react";
import type { RichTextField } from "@sitecore-jss/sitecore-jss-nextjs";
import { RichText as JssRichText, useSitecoreContext } from "@sitecore-jss/sitecore-jss-nextjs";
import { HtmlReactParser, HtmlReactBasicParser } from "commons/HtmlReactParser";

interface SCTextProps extends BoxProps {
    richTextField?: RichTextField;
    basic?: boolean;
}

const SCRichText = ({ richTextField, basic, ...props }: SCTextProps) => {
    const {
        sitecoreContext: { pageEditing },
    } = useSitecoreContext();

    if (!richTextField) return null;

    if (!pageEditing && richTextField?.value) {
        return (
            <Box {...props} className={"richtext"}>
                {basic ? (
                    <HtmlReactBasicParser html={richTextField.value} />
                ) : (
                    <HtmlReactParser html={richTextField.value} />
                )}
            </Box>
        );
    } else if (pageEditing) {
        return (
            <Box className="richtext">
                <JssRichText field={richTextField} />
            </Box>
        );
    }

    return null;
};

export default SCRichText;
