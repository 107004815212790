import { Box } from "@chakra-ui/react";
import { useCallback, useRef } from "react";
import { gradients } from "themes/foundations/color";
import { mapGradient } from "themes/utils/mapGradient";
import { useScroll } from "utils/hooks/useScroll";

const ScrollProgress = () => {
    const footerHeightRef = useRef(0);
    const spectrumColors = gradients.progress.colors;
    let scrollProgressBar = null as unknown as HTMLDivElement;

    const updateProgressBar = useCallback(() => {
        const windowHeight =
            document.documentElement.offsetHeight - window.innerHeight - footerHeightRef.current;
        window.requestAnimationFrame(() => {
            if (scrollProgressBar)
                scrollProgressBar.style.width = `${Math.floor(
                    (window.scrollY / windowHeight) * 100
                )}%`;
        });
    }, [scrollProgressBar]);

    useScroll(updateProgressBar, () => {
        const footerHeight =
            (document.querySelector(".page-footer") as HTMLElement)?.clientHeight || 0;
        footerHeightRef.current = footerHeight;
        scrollProgressBar = document.getElementById("scroll-progress") as HTMLDivElement;
    });

    return (
        <Box
            position="fixed"
            top={0}
            left={0}
            zIndex={101}
            width={0}
            transitionDuration=".3s"
            transitionProperty="width"
            transitionTimingFunction={"ease-out"}
            overflow="hidden"
            id="scroll-progress"
        >
            <Box
                bgGradient={mapGradient({
                    dir: "to-r",
                    colors: spectrumColors,
                })}
                height={4}
                width="100vw"
            ></Box>
        </Box>
    );
};

export default ScrollProgress;
