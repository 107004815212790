import { Box, Text } from "@chakra-ui/react";
import { Link as JssLink, useSitecoreContext } from "@sitecore-jss/sitecore-jss-nextjs";
import type { Field, ImageField, LinkField } from "@sitecore-jss/sitecore-jss-nextjs";
import SCHeading from "commons/sc/SCHeading";
import SCImage from "commons/sc/SCImage";
import SCText from "commons/sc/SCText";
import useImageSizes, { imageSize } from "utils/hooks/useImageSizes";

interface Fields {
    Image: ImageField;
    Headline?: Field<string>;
    Text?: Field<string>;
    CTA?: LinkField;
}

interface ImageCardProps {
    fields: Fields;
}

const ImageCard = (props: ImageCardProps) => {
    const sizes = useImageSizes({ base: 2 / 6, sm: 1 / 3, md: 1 / 4, lg: 1 / 5 });
    const {
        sitecoreContext: { pageEditing },
    } = useSitecoreContext();

    const groupBoxHoverStyle =
        props.fields?.Text?.value.trim() && !pageEditing
            ? {
                  pb: "70%",
              }
            : undefined;
    const groupTextHoverStyle =
        props.fields?.Text?.value.trim() && !pageEditing
            ? {
                  pt: "sp16",
                  opacity: "1",
                  maxHeight:
                      "calc((3 * var(--lineHeights-tall) * var(--fontSizes-xs)) + var(--space-sp16))",
              }
            : undefined;
    const groupCTAHoverStyle =
        props.fields?.Text?.value.trim() && !pageEditing
            ? {
                  opacity: "1",
              }
            : undefined;

    return (
        <>
            {props.fields?.Image.value?.src && (
                <Box
                    width="100%"
                    height={0}
                    flex="0 0 auto"
                    overflow="hidden"
                    pb={["70%", null, null, "100%"]}
                    position="relative"
                    borderRadius={4}
                    transition="all 0.3s ease"
                    _groupFocusVisible={groupBoxHoverStyle}
                    _groupHover={groupBoxHoverStyle}
                >
                    <SCImage
                        imageField={props.fields?.Image}
                        {...imageSize(Number(props.fields?.Image.value.width), 1, 1)}
                        sizes={sizes}
                        sx={{
                            objectFit:
                                props.fields?.Image.value.src.indexOf(".svg") < 1
                                    ? "cover"
                                    : undefined,
                            aspectRatio: ["10 / 7", null, null, "1/1"],
                            width: "100%",
                            height: "100%",
                            position: "absolute",
                            top: 0,
                            left: 0,
                        }}
                    />
                </Box>
            )}

            <SCHeading
                textField={props.fields?.Headline}
                as="h3"
                size="h5"
                mt={["sp16", null, null, "sp24"]}
                flex="0 0 auto"
            ></SCHeading>
            {(props.fields?.Text?.value.trim() || pageEditing) && (
                <SCText
                    textField={props.fields?.Text}
                    noOfLines={3}
                    sx={
                        !pageEditing
                            ? {
                                  pt: ["sp16", null, null, 0],
                                  lineHeight: "tall",
                                  maxHeight: ["auto", null, null, 0],
                                  opacity: [1, null, null, 0],
                              }
                            : {
                                  pt: "sp16",
                                  lineHeight: "tall",
                              }
                    }
                    size="bodySmall"
                    overflow="hidden"
                    transition="all 0.3s ease"
                    _groupFocusVisible={groupTextHoverStyle}
                    _groupHover={groupTextHoverStyle}
                />
            )}
            {props.fields?.CTA && (
                <Box
                    sx={
                        !pageEditing
                            ? {
                                  position: "absolute",
                                  bottom: 5,
                                  opacity: [1, null, null, 0],
                              }
                            : {
                                  pt: ["sp12", null, null, "sp8"],
                              }
                    }
                    transition="all 0.3s ease"
                    _groupFocusVisible={groupCTAHoverStyle}
                    _groupHover={groupCTAHoverStyle}
                >
                    <Text as="span" variant="link">
                        {!pageEditing &&
                            props.fields?.Text?.value.trim() &&
                            props.fields?.CTA?.value.href &&
                            props.fields?.CTA.value.text}
                        {pageEditing && (
                            <JssLink field={props.fields?.CTA} editable={pageEditing} />
                        )}
                    </Text>
                </Box>
            )}
        </>
    );
};

export default ImageCard;
