import type { ComponentParams, ComponentRendering } from "@sitecore-jss/sitecore-jss-nextjs";
import { useSitecoreContext } from "@sitecore-jss/sitecore-jss-nextjs";
import type { GenericHeaderProps } from "commons/ui/GenericHeader";
import GenericHeader from "commons/ui/GenericHeader";
import CarouselPlaceholder, { CountItems } from "commons/ui/Carousel/CarouselPlaceholder";
import QuoteElement from "./QuoteElement";

export type QuoteProps = {
    rendering: ComponentRendering & { params: ComponentParams };
    params: { [key: string]: string };
    fields: GenericHeaderProps;
};

const Quote = (props: QuoteProps) => {
    const {
        sitecoreContext: { pageEditing },
    } = useSitecoreContext();

    const phKey = `Quote-${props.params.DynamicPlaceholderId}`;
    const slides = props.rendering.placeholders
        ? Object.values(props.rendering.placeholders)[0]
        : [];
    const scSlidesLength = CountItems(slides);
    const placeholderOptions =
        slides.length > 1
            ? {
                  quoteLength: slides.length + "",
              }
            : undefined;
    if (!props.fields) return null;
    return (
        <GenericHeader
            Id={props.rendering?.uid ?? props.rendering?.componentName}
            AnchorId={props.fields?.AnchorId}
            HeaderHeadline={props.fields?.HeaderHeadline}
            HeaderSubHeadline={props.fields?.HeaderSubHeadline}
            HeaderCTA={props.fields?.HeaderCTA}
            headlineSize={props.params?.HeaderHeadlineSize}
            spacingSize={props.params?.Padding}
        >
            <CarouselPlaceholder
                id={props.rendering?.uid}
                phkey={phKey}
                componentsProps={props.rendering}
                carouselProps={{
                    id: `quoteCarousel_${props.fields.AnchorId}`,
                    gap: 0,
                    slidesToShow: [1],
                    dots: [true],
                    sx: {
                        alignItems: "center",
                        p: 1,
                    },
                }}
                carouselEditProps={{
                    width: `calc(100% * ${scSlidesLength})`,
                }}
                placeholderParams={placeholderOptions}
            >
                {!pageEditing &&
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    slides.map((slide: any) => {
                        return (
                            <QuoteElement
                                key={slide.uid}
                                {...slide}
                                params={{
                                    ...slide.params,
                                    quoteLength: scSlidesLength > 1 && scSlidesLength,
                                }}
                            />
                        );
                    })}
            </CarouselPlaceholder>
        </GenericHeader>
    );
};
export default Quote;
