import type { ComponentParams, ComponentRendering, Field } from "@sitecore-jss/sitecore-jss-nextjs";
import { useSitecoreContext } from "@sitecore-jss/sitecore-jss-nextjs";
import type { GenericHeaderProps } from "commons/ui/GenericHeader";
import GenericHeader from "commons/ui/GenericHeader";
import { Box, Flex } from "@chakra-ui/react";
import SCText from "commons/sc/SCText";
import { useRef } from "react";
import { useRouter } from "next/router";
import type { CookieConsentFieldProps } from "commons/ui/CookieConsent";
import CookieConsent from "commons/ui/CookieConsent";

interface Fields extends GenericHeaderProps, CookieConsentFieldProps {
    Url: Field<string>;
    Title: Field<string>;
    DesktopHeight: Field<number>;
    MobileHight: Field<number>;
}

export type IFrameProps = {
    rendering: ComponentRendering & { params: ComponentParams };
    params: { [key: string]: string };
    fields: Fields;
};

// eslint-disable-next-line @typescript-eslint/no-var-requires
const iFrameResizer = require("iframe-resizer/js/iframeResizer");

const Iframe = (props: IFrameProps) => {
    const {
        sitecoreContext: { pageEditing },
    } = useSitecoreContext();

    const { query } = useRouter();

    const utmSource = query.utm_source && `utm_source=${query.utm_source}`;
    const utmMedium = query.utm_medium && `utm_medium=${query.utm_medium}`;
    const utmCampaign = query.utm_campaign && `utm_campaign=${query.utm_campaign}`;
    const utmArray = [utmSource, utmMedium, utmCampaign];
    const utmString = utmArray.filter((utm) => utm).join("&");

    const iframeRef = useRef<HTMLIFrameElement>(null);

    const cookieConsentFields = {
        AnalyticsCookies: props.fields?.AnalyticsCookies,
        FunctionalCookies: props.fields?.FunctionalCookies,
        MarketingCookies: props.fields?.MarketingCookies,
    };

    if (!props.fields) return null;

    return (
        <GenericHeader
            Id={props.rendering?.uid ?? props.rendering?.componentName}
            AnchorId={props.fields.AnchorId}
            HeaderHeadline={props.fields?.HeaderHeadline}
            HeaderSubHeadline={props.fields?.HeaderSubHeadline}
            HeaderCTA={props.fields?.HeaderCTA}
            headlineSize={props.params?.HeaderHeadlineSize}
            spacingSize={props.params?.Padding}
        >
            {pageEditing && (
                <Flex flexDirection="column" gap="sp16" mb="sp16">
                    <Flex flexDirection="column">
                        Title:
                        <SCText
                            textField={props.fields?.Title}
                            border="1px solid black"
                            mt="sp12"
                        />
                    </Flex>
                    <Flex flexDirection="column">
                        Iframe URL:
                        <SCText
                            textField={props.fields?.Url}
                            border="1px solid black"
                            mt="sp12"
                            wordBreak="break-word"
                        />
                    </Flex>
                    <Flex flexDirection="column">
                        Mobile height:
                        <SCText
                            textField={props.fields?.MobileHight}
                            border="1px solid black"
                            mt="sp12"
                            wordBreak="break-word"
                        />
                    </Flex>
                    <Flex flexDirection="column">
                        Dekstop height:
                        <SCText
                            textField={props.fields?.DesktopHeight}
                            border="1px solid black"
                            mt="sp12"
                            wordBreak="break-word"
                        />
                    </Flex>
                </Flex>
            )}

            <CookieConsent {...cookieConsentFields}>
                <Box
                    sx={{
                        "> iframe": {
                            height: {
                                base: `${
                                    props.fields?.MobileHight?.value !== undefined
                                        ? props.fields?.MobileHight?.value + "px"
                                        : "auto"
                                }`,
                                lg: `${
                                    props.fields?.DesktopHeight?.value !== undefined
                                        ? props.fields?.DesktopHeight.value + "px"
                                        : "auto"
                                }`,
                            },
                        },
                    }}
                >
                    <iframe
                        ref={iframeRef}
                        width="100%"
                        height="100%"
                        title={props.fields?.Title.value}
                        src={props.fields?.Url.value + (utmString ? `?${utmString}` : "")}
                        onLoad={() => {
                            iFrameResizer(
                                {
                                    checkOrigin: false,
                                    heightCalculationMethod: "lowestElement",
                                    scrolling: false,
                                    autoResize: true,
                                },
                                iframeRef.current
                            );
                        }}
                    ></iframe>
                </Box>
            </CookieConsent>
        </GenericHeader>
    );
};
export default Iframe;
