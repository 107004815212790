import { Heading, forwardRef } from "@chakra-ui/react";
import type { HeadingProps, ResponsiveValue } from "@chakra-ui/react";
import { Text as JssText, useSitecoreContext } from "@sitecore-jss/sitecore-jss-nextjs";
import type { TextField } from "@sitecore-jss/sitecore-jss-nextjs";

interface SCHeadingProps extends HeadingProps {
    textField?: TextField;
    size?: ResponsiveValue<string>;
}
const SCHeading = forwardRef<SCHeadingProps, "h2">(({ textField, ...props }, ref) => {
    const {
        sitecoreContext: { pageEditing },
    } = useSitecoreContext();

    if (!textField) return null;
    const trimmedText = (textField.value as string).trim().length > 0;

    if (pageEditing || (!pageEditing && trimmedText)) {
        return (
            <Heading {...props} ref={ref}>
                <JssText field={textField} />
            </Heading>
        );
    }

    return null;
});

export default SCHeading;
