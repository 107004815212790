/* eslint-disable react-hooks/exhaustive-deps */
import { forwardRef, Flex, Box, useBreakpointValue } from "@chakra-ui/react";
import type { BoxProps } from "@chakra-ui/react";
import { useState, useRef, useCallback, useEffect, Children } from "react";
import type { ReactElement } from "react";

import CarouselNavButton from "./CarouselNavButton";
import CarouselDots from "./CarouselDots";
import { useI18n } from "next-localization";

type CarouselSlideProps = BoxProps;

export type CarouselProps = BoxProps & {
    navSpace?: number | string;
    slidesToShow?: number | number[];
    gap: number | string | (number | string | null)[];
    dots: (boolean | null)[];
    children: ReactElement<CarouselSlideProps> | ReactElement<CarouselSlideProps>[];
    slidesAriaLabel?: string;
};

const IsScrolledIntoView = (container: HTMLElement, elem: HTMLElement) => {
    const containerLeft = container.scrollLeft - 1;
    const containerRight = containerLeft + container.offsetWidth + 1;
    const elemLeft = elem.offsetLeft;
    const elemRight = elemLeft + elem.offsetWidth;
    return elemRight <= containerRight && elemLeft >= containerLeft;
};

// eslint-disable-next-line sonarjs/cognitive-complexity
const Carousel = forwardRef<CarouselProps, "div">((props, ref) => {
    const { children, navSpace, gap, dots, slidesToShow } = props;
    const isDots = useBreakpointValue(dots);
    const carouselRef = useRef<HTMLDivElement>(null);
    const [activeItemIndex, setActiveItemIndex] = useState<number>(0);
    const [activeDotIndex, setActiveDotIndex] = useState<number>(0);
    const [startX, setStartX] = useState(0);
    const [isSwiping, setIsSwiping] = useState<boolean>(false);

    const slidesToShowCount = slidesToShow ?? 1;

    const slidesToShowValue: number = ConvertToValidValue(slidesToShowCount);
    const negativeGap = getNegativeGap(gap);

    const childrenElements = Children.toArray(children);

    const handleScroll = useCallback(() => {
        const findActiveChildIndex = (div: HTMLDivElement | null) => {
            if (!div) return 0;
            const children = div.children;
            return Array.from(children).findIndex((child: HTMLElement, index) => {
                if (index % slidesToShowValue === 0) {
                    return IsScrolledIntoView(div, child);
                }
                return false;
            });
        };
        const div = carouselRef.current;
        const scrollPosition = div?.scrollLeft;
        if (scrollPosition) {
            const activeChildIndex = findActiveChildIndex(div);
            setActiveItemIndex(activeChildIndex);
            if (activeChildIndex / slidesToShowValue !== -1) {
                setActiveDotIndex(activeChildIndex / slidesToShowValue);
            }
        }
    }, [slidesToShowValue]);

    const handleTouchStart = useCallback((e: MouseEvent) => {
        e.preventDefault();
        setStartX(e.clientX);
        setIsSwiping(true);
    }, []);

    const handleTouchEnd = useCallback(() => {
        setIsSwiping(false);
    }, []);

    const ScrollToElement = useCallback(
        (elemIndex: number) => {
            carouselRef.current?.scrollTo({
                left: document.getElementById(
                    `${props.id}_carousel_slide_${elemIndex * slidesToShowValue}`
                )?.offsetLeft,
                behavior: "smooth",
            });
            handleTouchEnd();
        },
        [handleTouchEnd, props.id, slidesToShowValue]
    );

    const handleTouchMove = useCallback(
        (e: MouseEvent) => {
            e.preventDefault();
            if (!isSwiping) return;
            let currentX = 0;
            currentX = e.clientX;
            const diff = startX - currentX;
            // You can use this 'diff' value to determine how much the user has swiped

            // Example: Update UI based on swipe distance
            if (diff > 50) {
                // Swiped left
                ScrollToElement(activeDotIndex + 1);
            } else if (diff < -50) {
                // Swiped right
                ScrollToElement(activeDotIndex - 1);
            }
        },
        [ScrollToElement, activeDotIndex, isSwiping, startX]
    );

    useEffect(() => {
        const scrollContainer = carouselRef.current;
        if (scrollContainer) {
            scrollContainer.addEventListener("scroll", handleScroll);
            scrollContainer.addEventListener("mousedown", handleTouchStart);
            scrollContainer.addEventListener("mousemove", handleTouchMove);
            scrollContainer.addEventListener("mouseup", handleTouchEnd);
        }

        const observer = new ResizeObserver(() => handleScroll);

        return () => {
            scrollContainer?.removeEventListener("scroll", handleScroll);
            scrollContainer?.removeEventListener("mousedown", handleTouchStart);
            scrollContainer?.removeEventListener("mousemove", handleTouchMove);
            scrollContainer?.removeEventListener("mouseup", handleTouchEnd);
            observer.disconnect();
        };
    }, [handleScroll, handleTouchStart, handleTouchMove, handleTouchEnd]);

    const { t } = useI18n();
    let slidesAriaLabel = t("slidesAriaLabel") ? t("slidesAriaLabel") : "Slide {0} of {1}";
    const slidesElements = childrenElements.map((element, index) => {
        slidesAriaLabel = slidesAriaLabel ?? "Slide {0} of {1}";
        const snap = index % slidesToShowValue === 0 ? "start" : "unset";
        const slideId = `${props.id}_carousel_slide_${index}`;
        return (
            <Box
                className={`${props.id}_carousel_slide`}
                key={slideId}
                id={slideId}
                scrollSnapAlign={snap}
                width={getSlideWidth(slidesToShowCount)}
                flex="0 0 auto"
                px={gap}
                aria-labelledby={`${slideId}_${index}`}
            >
                {element}
                <span hidden id={`${slideId}_${index}`}>
                    {slidesAriaLabel
                        .replace("{0}", `${index + 1}`)
                        .replace("{1}", `${childrenElements.length}`)}
                </span>
            </Box>
        );
    });
    const GetActiveNav = useCallback(
        (navType: string) => {
            if (navType === "next") {
                const activeItemIndexAddition = slidesToShowValue === 0 ? 1 : slidesToShowValue;
                return activeItemIndex + activeItemIndexAddition < childrenElements.length;
            } else {
                return activeItemIndex !== 0;
            }
        },
        [activeItemIndex, childrenElements.length, slidesToShowValue]
    );

    const handleTabbing = useCallback(
        (e: React.KeyboardEvent) => {
            if (e.key === "Tab") {
                const activeElement = document.activeElement as HTMLElement;
                const container = carouselRef.current;
                if (container && activeElement) {
                    const containerLeft = container.scrollLeft;
                    const containerRight = containerLeft + container.offsetWidth;
                    const elementLeft = activeElement.offsetLeft;
                    const elementRight = elementLeft + activeElement.offsetWidth;
                    if (elementLeft < containerLeft || elementRight > containerRight) {
                        container.scrollTo({
                            left: elementLeft,
                            behavior: "smooth",
                        });
                    }
                }
            }
        },
        [carouselRef]
    );

    const activePrev = (
        <CarouselNavButton
            navType="prev"
            marginSpace={negativeGap}
            navSpace={navSpace}
            onClick={() => {
                ScrollToElement(activeDotIndex - 1);
            }}
            active={GetActiveNav("prev")}
        />
    );

    const activeNext = (
        <CarouselNavButton
            navType="next"
            marginSpace={negativeGap}
            navSpace={navSpace}
            onClick={() => {
                ScrollToElement(activeDotIndex + 1);
            }}
            active={GetActiveNav("next")}
        />
    );

    return (
        <Flex flexDirection="column" ref={ref}>
            <Box position="relative">
                {activePrev}
                <Flex
                    ref={carouselRef}
                    flexDirection="row"
                    overflowX="scroll"
                    overflowY="hidden"
                    scrollSnapType="x mandatory"
                    p="2px"
                    position="relative"
                    mx={negativeGap}
                    onKeyUp={handleTabbing}
                    tabIndex={-1}
                    aria-labelledby={`GH_${props.id}`}
                    sx={{
                        scrollbarWidth: "none",
                        "::-webkit-scrollbar": {
                            width: 0,
                            height: 0,
                        },
                        "::-webkit-scrollbar-track": {
                            boxShadow: "inset 0 0 0 rgba(0,0,0,0)",
                        },
                        "::-webkit-scrollbar-thumb": {
                            backgroundColor: "transparent",
                            outline: "0px solid transparent",
                        },
                        ...props.sx,
                    }}
                >
                    {slidesElements}
                </Flex>
                {activeNext}
            </Box>
            {isDots && (
                <CarouselDots
                    carouselId={props.id}
                    childrenCount={childrenElements.length}
                    slidesToShowValue={slidesToShowValue}
                    activeDotIndex={activeDotIndex}
                    scrollToElement={ScrollToElement}
                />
            )}
        </Flex>
    );
});

const ConvertToValidValue = (count: number | number[]) => {
    const arr = Array.isArray(count) ? count : [count];
    return useBreakpointValue(arr.map((value) => Math.floor(value))) ?? 1;
};

const getNegativeGap = (gap: number | string | (number | string | null)[]) => {
    const gapValue = gap ?? 0;
    return Array.isArray(gapValue)
        ? gapValue.map((value) => (value ? `-${value}` : null))
        : `-${gapValue}`;
};

const getSlideWidth = (slidesToShowCount: number | number[]) => {
    return Array.isArray(slidesToShowCount)
        ? slidesToShowCount.map((amount: number) => `calc(100% / ${amount})`)
        : `calc(100% / ${slidesToShowCount})`;
};

export default Carousel;
