import { Box, Flex, Heading, Stack, Text } from "@chakra-ui/react";
import NextImage from "next/image";
import type { ImageFieldValue } from "@sitecore-jss/sitecore-jss-nextjs";
import { Text as JssText } from "@sitecore-jss/sitecore-jss-nextjs";

import { useRouter } from "next/router";
import NextLink from "commons/ui/NextLink";
import { type PartnerResult } from "components/PartnersFilter";

interface PartnerCardProps {
    item: PartnerResult;
    image: ImageFieldValue | undefined;
    sizes: string;
}

const PartnerCard = ({ item, image, sizes }: PartnerCardProps) => {
    const { query } = useRouter();

    const firstTag = item?.tags && item.tags.jsonValue.length > 0 && item.tags.jsonValue[0];

    return (
        <NextLink
            backgroundColor="brand.lighterGray"
            padding="sp48"
            paddingTop="sp24"
            key={item?.id}
            id={item?.id}
            _hover={{
                span: {
                    _before: {
                        right: "100%",
                    },
                },
            }}
            href={{
                query: {
                    ...query,
                    highlight: item.id,
                },
            }}
        >
            <Flex
                as="article"
                minWidth="100%"
                flexDirection="column"
                gap="sp16"
                justifyContent="space-between"
                height="100%"
            >
                <Stack spacing="sp16">
                    <Box
                        aspectRatio={1}
                        alignContent="center"
                        justifyContent="center"
                        objectFit="contain"
                    >
                        {image?.src && (
                            <NextImage
                                src={image?.src}
                                alt={typeof image?.alt === "string" ? image?.alt : ""}
                                sizes={sizes}
                                width={Number(image?.width)}
                                height={Math.round(Number(image?.width) * (2 / 5))}
                            />
                        )}
                    </Box>

                    <Heading as="h3" size="2xs" textAlign="center">
                        <JssText
                            field={{
                                value: item?.partnerName?.value,
                            }}
                            editable={false}
                        />
                    </Heading>
                    {firstTag && (
                        <Text as="p" size="bodySmall" textAlign="center">
                            <JssText
                                field={{
                                    value: firstTag.displayName,
                                }}
                                editable={false}
                            />
                        </Text>
                    )}
                </Stack>
            </Flex>
        </NextLink>
    );
};

export default PartnerCard;
