import { Box } from "@chakra-ui/react";
import BundleIcon from "./BundleIcon";

export const AccordionButtonIcon = ({ isExpanded }: { isExpanded: boolean }) => {
    return (
        <Box
            as="span"
            marginLeft="auto"
            display={"flex"}
            mt={{ base: "sp4", lg: "0" }}
            alignItems={{ lg: "center" }}
        >
            {isExpanded ? (
                <BundleIcon size={["md", null, null, "lg"]} name="Remove" />
            ) : (
                <BundleIcon size={["md", null, null, "lg"]} name="Add" />
            )}
        </Box>
    );
};
