/* eslint-disable @typescript-eslint/no-explicit-any */
import type { ComponentParams, ComponentRendering } from "@sitecore-jss/sitecore-jss-nextjs";
import { useSitecoreContext, Placeholder } from "@sitecore-jss/sitecore-jss-nextjs";
import React from "react";
import type { BoxProps } from "@chakra-ui/react";
import { Box, Flex } from "@chakra-ui/react";
import Carousel from "commons/ui/Carousel/Carousel";

interface CarouselPlaceholderProps {
    id?: string;
    phkey: string;
    componentsProps: ComponentRendering;
    carouselProps: BoxProps & {
        navSpace?: number | string;
        slidesToShow?: number | number[];
        gap: number | string | (number | string | null)[];
        dots: (boolean | null)[];
    };
    carouselEditProps: BoxProps;
    placeholderParams?: ComponentParams;
    children?: React.ReactNode;
}

export const CountItems = (componentSlides: any[]) => {
    const {
        sitecoreContext: { pageEditing },
    } = useSitecoreContext();

    if (pageEditing) {
        const componentsArr: any = [];
        componentSlides.map((component: any) => {
            if (!component || component.type !== "text/sitecore") {
                componentsArr.push(component);
            }
        });

        return componentsArr.length;
    }

    return componentSlides.length;
};

const CarouselPlaceholder = (props: CarouselPlaceholderProps) => {
    const {
        sitecoreContext: { pageEditing },
    } = useSitecoreContext();

    if (pageEditing) {
        return (
            <Placeholder
                name={props.phkey}
                rendering={props.componentsProps}
                render={(components) => {
                    return (
                        <Box
                            overflowY="hidden"
                            overflowX="auto"
                            pb="sp24"
                            mx={props.carouselProps.mx}
                        >
                            <Flex {...props.carouselEditProps} gap={props.carouselProps.gap}>
                                {components}
                            </Flex>
                        </Box>
                    );
                }}
            />
        );
    } else {
        const slidesToShowCount = props.carouselProps.slidesToShow ?? 1;
        const width = Array.isArray(slidesToShowCount)
            ? slidesToShowCount.map((amount: number) => `calc(100% / ${amount})`)
            : `calc(100% / ${slidesToShowCount})`;
        return Array.isArray(props.children) && props.children.length > 1 ? (
            <Carousel {...props.carouselProps} id={props.id}>
                {props.children}
            </Carousel>
        ) : (
            <Box width={width}>{props.children}</Box>
        );
    }
};

export default CarouselPlaceholder;
