import type { ComponentParams, ComponentRendering, Field } from "@sitecore-jss/sitecore-jss-nextjs";
import { Placeholder } from "@sitecore-jss/sitecore-jss-nextjs";
import { Text, Container, Accordion, List, ListItem, Box } from "@chakra-ui/react";
import { breakoutSessionColor, sortSessionCategories } from "utils/mappings/breakout-session-color";

// Define or import the SessionCategory type
export type SessionCategory = {
    displayName: string;
    fields: {
        Color: { value: string };
        Name: { value: string };
    };
    id: string;
    name: string;
    url: string;
};

export type BreakoutSessionAccordionProps = {
    rendering: ComponentRendering & { params: ComponentParams };
    params: { [key: string]: string };
    fields: Fields;
};

interface Fields {
    Text: Field<string>;
    SessionCategories: SessionCategory[];
}

const BreakoutSessionAccordion = (props: BreakoutSessionAccordionProps) => {
    const phKey = `BreakoutSessionAccordion-${props.params?.DynamicPlaceholderId}`;

    const sessionCategories = sortSessionCategories(props.fields.SessionCategories);

    if (!props.fields) return null;
    return (
        <Container variant={"module"}>
            {props.fields.Text && <Text mb={"sp40"}>{props.fields.Text.value}</Text>}

            <List display="flex" rowGap={"sp8"} columnGap={"sp24"} mb={"sp40"} flexWrap={"wrap"}>
                {sessionCategories.map((category: SessionCategory) => {
                    const color = breakoutSessionColor[category.fields.Color.value];
                    return (
                        <ListItem
                            key={category.id}
                            display={"flex"}
                            gap={"sp8"}
                            whiteSpace={"nowrap"}
                        >
                            <Box
                                as="span"
                                display="inlune-flex"
                                borderRadius={"100%"}
                                w="25px"
                                h="25px"
                                bgColor={color}
                            ></Box>
                            {category.fields.Name.value}
                        </ListItem>
                    );
                })}
            </List>

            <Accordion
                variant="SessionAccordion"
                allowToggle
                aria-labelledby={`GH_${props.rendering?.uid}`}
            >
                <Placeholder name={phKey} rendering={props.rendering} />
            </Accordion>
        </Container>
    );
};
export default BreakoutSessionAccordion;
