import { Box, Container } from "@chakra-ui/react";
import type { ComponentParams, ComponentRendering, Field } from "@sitecore-jss/sitecore-jss-nextjs";
import { Placeholder, useSitecoreContext } from "@sitecore-jss/sitecore-jss-nextjs";
import SCHeading from "commons/sc/SCHeading";

export type TabElementProps = {
    uid: string;
    rendering: ComponentRendering & { params: ComponentParams };
    params: { [key: string]: string };
    fields: Fields;
};

interface Fields {
    Headline: Field<string>;
}

const TabElement = (props: TabElementProps) => {
    const phKey = `TabElement-${props.params?.DynamicPlaceholderId}`;

    const {
        sitecoreContext: { pageEditing },
    } = useSitecoreContext();

    if (!props.fields) return null;

    return (
        <>
            {pageEditing && (
                <Container variant={"module"}>
                    {"Tab headline: "}
                    <SCHeading textField={props.fields.Headline} />
                </Container>
            )}
            <Box
                role="tabpanel"
                tabIndex={0}
                aria-labelledby={`${props.rendering?.uid}--tab`}
                id={`${props.rendering?.uid}--tabpanel`}
                className="tab-panel"
            >
                <Placeholder name={phKey} rendering={props.rendering} />
            </Box>
        </>
    );
};
export default TabElement;
