import type { Field, LinkField } from "@sitecore-jss/sitecore-jss-nextjs";
import { AccordionButton, AccordionItem, AccordionPanel, Box, Divider } from "@chakra-ui/react";
import { spacing } from "themes/foundations/spacings";
import SCRichText from "commons/sc/SCRichText";
import SCText from "commons/sc/SCText";
import SCLink from "commons/sc/SCLink";
import { gtmCTAEvent } from "commons/head/GTMTracking";
import { AccordionButtonIcon } from "commons/ui/AccordionButtonIcon";
interface Fields {
    Headline: Field<string>;
    Text: Field<string>;
    CTA: LinkField;
}

export type AccordionElementProps = {
    fields: Fields;
};

const AccordionElement = (props: AccordionElementProps) => {
    if (!props.fields) return null;
    return (
        <AccordionItem sx={{ counterIncrement: "line-number" }}>
            {({ isExpanded }) => (
                <>
                    <AccordionButton
                        onClick={() => {
                            gtmCTAEvent(
                                props.fields?.Headline.value,
                                isExpanded ? "Foldin accordion" : "Foldout accordion"
                            );
                        }}
                    >
                        <Box
                            as="span"
                            minWidth={[spacing.sp28 as string, null, null, spacing.sp168 as string]}
                            _before={{ content: "counter(line-number, decimal-leading-zero)" }}
                        ></Box>
                        <SCText as="span" size="h3" textField={props.fields?.Headline} />
                        <AccordionButtonIcon isExpanded={isExpanded} />
                    </AccordionButton>
                    <AccordionPanel>
                        <SCRichText richTextField={props.fields?.Text} />
                        <SCLink
                            size="2xs"
                            variant={"underlineLink"}
                            linkField={props.fields?.CTA}
                        />
                    </AccordionPanel>
                    <Divider height="1" bg="brand.lightGray" />
                </>
            )}
        </AccordionItem>
    );
};
export default AccordionElement;
