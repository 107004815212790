import { Container } from "@chakra-ui/react";
import type {
    ComponentParams,
    ComponentRendering,
    Field,
    ImageField,
    LinkField,
} from "@sitecore-jss/sitecore-jss-nextjs";
import { type PartnerResult } from "./PartnersFilter";
import PartnerLayout from "commons/ui/Partner/PartnerLayout";

interface Fields {
    // Tags: Field<string>;
    Logo: ImageField;
    PartnerName: Field<string>;
    Video: Field<string>;
    ThumbnailImage: ImageField;
    ThumbnailUrl: Field<string>;
    TeaserHeadline: Field<string>;
    TeaserText: Field<string>;
    Headline: Field<string>;
    SubHeadline: Field<string>;
    Text: Field<string>;
    WebsiteLink: LinkField;
    LinkedInLink: LinkField;
    DownloadLink: LinkField;
    InternalPageLink: LinkField;
}
type PartnerProps = {
    rendering?: ComponentRendering & { params: ComponentParams };
    fields: Fields;
};

const Partner = (props: PartnerProps) => {
    const partner: PartnerResult = {
        partnerName: { value: props.fields.PartnerName.value },
        headline: { value: props.fields.Headline.value },
        subHeadline: { value: props.fields.SubHeadline.value },
        teaserHeadline: { value: props.fields.TeaserHeadline.value },
        teaserText: { value: props.fields.TeaserText.value },
        text: { value: props.fields.Text.value },
        video: { value: props.fields.Video.value },
        thumbnailImage: { jsonValue: props.fields.ThumbnailImage },
        thumbnailUrl: { value: props.fields.ThumbnailUrl.value },
        logo: { jsonValue: props.fields.Logo },
        websiteLink: { jsonValue: props.fields.WebsiteLink },
        downloadLink: { jsonValue: props.fields.DownloadLink },
        internalPageLink: { jsonValue: props.fields.InternalPageLink },
        linkedInLink: { jsonValue: props.fields.LinkedInLink },
        id: "",
    };

    return (
        <Container>
            <PartnerLayout {...partner} />
        </Container>
    );
};

export default Partner;
