import { Button, Flex } from "@chakra-ui/react";
import { useState, useRef, useCallback } from "react";
import { useI18n } from "next-localization";
import colors from "themes/foundations/color";
import BundleIcon from "../BundleIcon";

interface CarouselNavButtonProps {
    navSpace?: number | string;
    navType: "next" | "prev";
    marginSpace?: number | string | (number | string | null)[];
    onClick: () => void;
    active?: boolean;
}

const CarouselNavButton = (props: CarouselNavButtonProps) => {
    const { t } = useI18n();
    const { onClick, navType, navSpace, marginSpace } = props;
    const [isHoveredOn, setIsHoveredOn] = useState<boolean>(false);
    const contentRef = useRef<HTMLDivElement>(null);

    const navSpaceValue = navSpace ?? 100;
    const isNextButton = navType === "next";

    const handleMouseEnter = () => {
        setIsHoveredOn(true);
        contentRef?.current?.removeAttribute("style");
    };
    const handleMouseLeave = () => {
        setIsHoveredOn(false);
        contentRef?.current?.removeAttribute("style");
    };

    const handleMouseMove = useCallback(
        (e: React.MouseEvent<HTMLButtonElement>) => {
            if (isHoveredOn && contentRef.current) {
                contentRef.current.style.transform = `translate(calc(${e.clientX}px - 50%), calc(${e.clientY}px - 50%))`;
                contentRef.current.style.position = "fixed";
            }
        },
        [isHoveredOn]
    );

    const handleTabbing = useCallback((e: React.KeyboardEvent<HTMLButtonElement>) => {
        const activeElement = document.activeElement as HTMLElement;
        if (e.key === "Tab" && activeElement === e.currentTarget && contentRef.current) {
            setIsHoveredOn(true);
            contentRef.current.style.top = "50%";
            contentRef.current.style.right = "50%";
            contentRef.current.style.transform = "none";
            contentRef.current.style.position = "absolute";
            contentRef.current.style.marginTop = "-27px";
            contentRef.current.style.transform = "translateX(20px)";
        }
    }, []);

    const handleTabbingAway = useCallback((e: React.KeyboardEvent<HTMLButtonElement>) => {
        if (e.key === "Tab") {
            setIsHoveredOn(false);
            contentRef?.current?.removeAttribute("style");
        }
    }, []);

    return (
        <Button
            aria-label={isNextButton ? t("carouselNextBtnLabel") : t("carouselPrevBtnLabel")}
            display={["none", null, null, props.active ? "block" : "none"]}
            variant="unstyled"
            position="absolute"
            top="auto"
            height="100%"
            bottom={0}
            right={isNextButton ? marginSpace : "auto"}
            left={isNextButton ? "auto" : marginSpace}
            width={navSpaceValue}
            zIndex={2}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            onMouseMove={handleMouseMove}
            onKeyUp={handleTabbing}
            onKeyDown={handleTabbingAway}
            onClick={onClick}
            cursor="none"
        >
            <Flex
                ref={contentRef}
                as="span"
                position={"fixed"}
                overflow="hidden"
                top={0}
                left={0}
                width={isHoveredOn ? "carouselNavigationButtonSize" : 0}
                height={isHoveredOn ? "carouselNavigationButtonSize" : 0}
                padding={0}
                pointerEvents="none"
                borderRadius="100%"
                bg={colors.brand.orange}
                justifyContent="center"
                alignItems="center"
                zIndex={2}
                transition="width 0.15s ease, height 0.15s ease"
                transformOrigin="center"
            >
                <BundleIcon
                    name="ArrowForwardIos"
                    mr={!isNextButton ? 2 : 0}
                    transform={!isNextButton ? "scaleX(-1)" : "none"}
                />
            </Flex>
        </Button>
    );
};

export default CarouselNavButton;
