import { Box, Container, Link, ListItem, OrderedList } from "@chakra-ui/react";
import type {
    ComponentParams,
    ComponentRendering,
    Field,
    LinkField,
} from "@sitecore-jss/sitecore-jss-nextjs";
import SCText from "commons/sc/SCText";
import BundleIcon from "commons/ui/BundleIcon";
import NextLink from "next/link";
import { useRouter } from "next/router";
import { useCallback, useState } from "react";
import { pxToRem } from "themes/utils/mapPxToRem";
import { useScroll } from "utils/hooks/useScroll";

interface Fields {
    Headline: Field<string>;
    Text: Field<string>;
    CTA: LinkField;
    CTAIcon: {
        fields: {
            Key: {
                value: string;
            };
        };
    };
    Anchors: Field<string>;
}

export type AnchorNavigationProps = {
    rendering: ComponentRendering & { params: ComponentParams };
    params: { [key: string]: string };
    fields: Fields;
};

const splitAnchors = (anchors: string) => {
    return anchors.split("&").map((link) => {
        const [key, value] = link.split("=");
        const anchor = decodeURIComponent(value);
        return { key, anchor };
    });
};

const AnchorNavigation = (props: AnchorNavigationProps) => {
    const anchors = props.fields?.Anchors.value;
    const splitAnchorsArray = anchors ? splitAnchors(anchors) : [];
    const [activeAnchor, setActiveAnchor] = useState<string | null>(null);
    const router = useRouter();
    const hasLinkIcon = props.fields?.CTAIcon?.fields?.Key.value === "link";

    const handleAnchorClick = (e: React.MouseEvent<HTMLAnchorElement>, key: string) => {
        e.preventDefault();
        const element = document.getElementById(key);
        if (element) {
            element.scrollIntoView();

            router.replace(`#${key}`, undefined, { shallow: true });
        }
        setActiveAnchor(key);
    };

    const handleScroll = useCallback(() => {
        const anchors = document.querySelectorAll("section[id]");

        const { innerHeight } = window;
        anchors.forEach((el) => {
            const { top, bottom } = el.getBoundingClientRect();
            if (
                ((top > 0 && top < innerHeight / 4) || (bottom > 0 && bottom < innerHeight)) &&
                el?.id !== ""
            ) {
                setActiveAnchor(el.id);
            }
        });
    }, []);

    useScroll(handleScroll);

    return (
        <Container
            variant={"hero"}
            display="flex"
            flexWrap="wrap"
            alignItems="flex-end"
            justifyContent="space-between"
            gap={"sp40"}
            bg="white"
            py={"sp16"}
            position="sticky"
            top={{
                base: "var(--sizes-headerMobileTopHeight)",
                lg: "var(--sizes-headerTopHeight)",
            }}
            zIndex="header"
        >
            <Box as="nav" width={{ lg: `calc(100% - ${pxToRem("390px")})` }}>
                <OrderedList
                    role="list"
                    sx={{
                        padding: "0",
                        margin: "0",
                        display: "flex",
                        gap: { base: "sp24", lg: "sp40" },
                        position: "relative",
                        flex: "1",
                        listStyleType: "none",
                        _after: {
                            content: `""`,
                            display: "block",
                            position: "absolute",
                            height: "1px",
                            zIndex: "hide",
                            width: "100%",
                            left: "0",
                            bottom: "0",
                            backgroundColor: "lightGray",
                            _active: { backgroundColor: "black" },
                        },
                    }}
                >
                    {splitAnchorsArray.map(({ key, anchor }) => (
                        <ListItem key={anchor}>
                            <Link
                                as={"button"}
                                title={anchor}
                                // href={`#${key}`}
                                // scroll={false}
                                onClick={(e) => handleAnchorClick(e, key)}
                                borderBottom={
                                    activeAnchor === key ? "1px solid black" : "1px solid lightGray"
                                }
                                fontWeight={activeAnchor === key ? "bold" : "normal"}
                                _after={{
                                    display: "block",
                                    content: `attr(title)`,
                                    fontWeight: "bold",
                                    height: "1px",
                                    color: "transparent",
                                    overflow: "hidden",
                                    visibility: "hidden",
                                }}
                                fontSize={{ base: "2xs", lg: "xxs" }}
                                lineHeight={"base"}
                                py="sp16"
                                height="100%"
                                _hover={{
                                    borderBottom: "1px solid black",
                                }}
                            >
                                {anchor}
                            </Link>
                        </ListItem>
                    ))}
                </OrderedList>
            </Box>
            {props.fields?.CTA.value?.href && (
                <Link
                    as={NextLink}
                    display={"flex"}
                    href={props.fields?.CTA.value?.href}
                    flexBasis={{ base: "100%", lg: "33.33%" }}
                    w={{ base: "100%", lg: "auto" }}
                    position={{ base: "fixed", lg: "static" }}
                    left={{ base: "0", lg: "auto" }}
                    bottom={{ base: "0", lg: "auto" }}
                    maxW={{ base: "100%", lg: pxToRem("350px") }}
                    bg="brand.yellow"
                    py="sp16"
                    px={{ base: "sp24", lg: "sp32" }}
                    alignItems={"center"}
                    gap="sp28"
                    justifyContent={{ base: "space-between", lg: "flex-start" }}
                    transition={"background-color 0.3s ease"}
                    sx={{
                        _hover: {
                            bg: "brand.lightGray",
                        },
                    }}
                    {...(!hasLinkIcon && { download: true })}
                >
                    <Box flex="1">
                        <SCText textField={props.fields?.Headline} size="anchorDownloadHeadline" />
                        <SCText textField={props.fields?.Text} size="3xs" />
                    </Box>
                    <BundleIcon size="md" name={hasLinkIcon ? "ArrowForward" : "Download"} />
                </Link>
            )}
        </Container>
    );
};

export default AnchorNavigation;
