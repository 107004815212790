import { pxToRem } from "themes/utils/mapPxToRem";

const underlinedText = {
    content: "''",
    position: "absolute",
    bg: "currentColor",
    bottom: -2,
    height: 1,
    left: 0,
    right: "100%",
    transition: "all ease 0.2s",
};

export const MainNavigationStyling = {
    sx: {
        display: "flex",
        order: [0, null, null, "inherit"],

        ".mainnav__item": {
            fontSize: "xxs",
            display: "flex",
            transitionDuration: ".30s",
            transitionTimingFunction: "ease-in-out",
            transitionProperty: "color",
            color: "currentColor",
            textAlign: "left",

            position: "relative",
            // fontSize: "xxs",
            lineHeight: "none",
            fontWeight: "normal",
            letterSpacing: "wide",

            _before: {
                ...underlinedText,
                bottom: -4,
            },

            // "&--text": {

            // },

            svg: {
                transition: "all ease 0.2s",
                fill: "currentColor",
                width: 16,
                height: 16,
                ml: "sp8",
            },

            "&:hover, &:focus, &--active": {
                ".nav--open &": {
                    _before: {
                        right: pxToRem("24px"),
                    },
                    "&.link": {
                        _before: {
                            right: 0,
                        },
                    },
                },

                textStrokeWidth: 0.2,
                textStrokeColor: "currentColor",
                _before: {
                    right: pxToRem("24px"),
                },
                "&.link": {
                    _before: {
                        right: 0,
                    },
                },
            },

            "&--active": {
                svg: {
                    transform: "scaleY(-1)",
                },
            },
        },
        ".subnav__wrapper": {
            backgroundColor: "white",
            overflow: "hidden",
        },
    },
};

export const SubNavigationStyling = {
    sx: {
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        ".mainnav--active &": {
            zIndex: 3,
            maxHeight: "100svh",
            ".subnav__wrapper--inner": {
                overflowY: [null, null, null, "auto"],
                maxHeight: "calc(100svh - var(--sizes-headerTopHeight))",
            },
        },

        ".subnav__block": {
            display: "flex",
            flexShrink: [0, null, 1],
            flexGrow: 0,
            px: "sp24",
            pt: ["9rem", null, null, 20],
            position: ["absolute", null, null, "relative"],
            width: "100%",
            height: "100%",
            color: "black",
            maxHeight: ["calc(100svh - var(--space-sp80))", null, null, "full"],
        },

        ".subnav__inner": {
            height: ["100svh", null, null, "full"],
        },

        li: {
            py: ["2", null, null, "0.625rem"],
        },

        ".subnav__item": {
            py: "sp16",
            px: 3,
            listStyle: "none",
            transition: "opacity 0.15s ease-in-out",

            "a, button": {
                // "&,.subnav__item--text": {

                // },
                fontSize: "md",
                borderRadius: 0,
                fontWeight: "normal",
                letterSpacing: "tight",
                lineHeight: "normal",
                display: "inline-flex",
                justifyContent: "space-between",
                alignItems: "center",
                flexWrap: "wrap",
                width: "100%",
            },

            "&.second-level": {
                "a, button": {
                    fontSize: ["sm", null, null, "lmd"],
                    lineHeight: "shorter",
                },
            },

            "&.third-level": {
                "a, button": {
                    fontSize: ["md", null, null, "md"],
                    lineHeight: "tall",
                    position: "relative",
                    width: "auto",
                    _before: {
                        ...underlinedText,
                        right: 0,
                        bottom: 0,
                    },
                },
                "& ~ ul": {
                    "a, button": {
                        fontSize: ["sm", null, null, "md"],
                        _before: {
                            content: "unset",
                        },
                    },
                },
            },

            "&--parent": {
                "span.nav-text": {
                    maxWidth: "100%",
                },
            },

            "&:hover": {
                opacity: 0.5,
            },

            "&--in-path": {
                _hover: {
                    opacity: 1,
                },
            },

            "&--out-path": {
                opacity: 0.5,
            },

            "&--active": {
                opacity: 1,
            },
        },

        ".closeButtonContainer": {
            position: "absolute",
            display: ["none", null, null, "flex"],
            justifyContent: "flex-end",
            left: 0,
            right: 0,
            zIndex: 1,
        },

        ".backbutton": {
            position: "absolute",
            top: "var(--sizes-headerMobileTopHeight)",
            left: "sp28",
            right: "auto",
            display: ["flex", null, null, "none"],
            height: "32px",
            flexWrap: "wrap",
            alignContent: "center",
            alignItems: "center",
            zIndex: 1,

            span: {
                marginLeft: 0,
            },

            svg: {
                width: 16,
                height: 16,
            },
        },
    },
};
