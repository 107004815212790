import type { Field } from "@sitecore-jss/sitecore-jss-nextjs";
import {
    AccordionButton,
    AccordionItem,
    AccordionPanel,
    Box,
    Divider,
    Grid,
    Heading,
} from "@chakra-ui/react";
import { gtmCTAEvent } from "commons/head/GTMTracking";
import SCText from "commons/sc/SCText";
import SCRichText from "commons/sc/SCRichText";
import SCHeading from "commons/sc/SCHeading";
import type { SpeakerProps } from "components/SpeakerList";
import { SpeakerBox } from "components/SpeakerList";
import { AccordionButtonIcon } from "commons/ui/AccordionButtonIcon";
import type { SessionCategory } from "./BreakoutSessionAccordion";
import { breakoutSessionColor, sortSessionCategories } from "utils/mappings/breakout-session-color";

interface Fields {
    Headline: Field<string>;
    Speakers: SpeakerProps[];
    SubHeadline: Field<string>;
    Text: Field<string>;
    SessionCategories: SessionCategory[];
}

export type BreakoutSessionAccordionElementProps = {
    fields: Fields;
};

const SpearkerList = ({ speakers }: { speakers: SpeakerProps[] }) => {
    return (
        <Grid
            templateColumns={{
                base: "repeat(1, 1fr)",
                md: "repeat(2, 1fr)",
                lg: "repeat(4, 1fr)",
            }}
            columnGap="sp16"
            rowGap={{ base: "sp24" }}
            mt={"sp16"}
        >
            {speakers.map((speaker: SpeakerProps) => {
                return <SpeakerBox key={speaker.id} {...speaker} />;
            })}
        </Grid>
    );
};

const BreakoutSessionAccordionElement = (props: BreakoutSessionAccordionElementProps) => {
    const sessionCategories = sortSessionCategories(props.fields.SessionCategories);

    if (!props.fields) return null;
    return (
        <AccordionItem>
            {({ isExpanded }) => (
                <>
                    <AccordionButton
                        onClick={() => {
                            gtmCTAEvent(
                                props.fields?.Headline.value,
                                isExpanded ? "Foldin accordion" : "Foldout accordion"
                            );
                        }}
                    >
                        <Box>
                            <SCHeading
                                as="h3"
                                size="sm"
                                textField={props.fields?.Headline}
                                mb={"sp8"}
                            />

                            <Box display={"flex"}>
                                <SCText size="xs" textField={props.fields.SubHeadline} mr="sp16" />

                                {sessionCategories.map((category: SessionCategory) => {
                                    const color = breakoutSessionColor[category.fields.Color.value];
                                    return (
                                        <Box
                                            key={category.id}
                                            as="span"
                                            display="inlune-flex"
                                            borderRadius={"100%"}
                                            w="22px"
                                            h="22px"
                                            mr="sp8"
                                            bgColor={color}
                                        ></Box>
                                    );
                                })}
                            </Box>
                        </Box>
                        <AccordionButtonIcon isExpanded={isExpanded} />
                    </AccordionButton>
                    <AccordionPanel>
                        <SCRichText richTextField={props.fields?.Text} />
                        {props.fields.Speakers?.length > 0 && (
                            <>
                                <Heading as="h4" size="sx" mt={"sp64"}>
                                    Speakers
                                </Heading>
                                <SpearkerList speakers={props.fields.Speakers} />
                            </>
                        )}
                    </AccordionPanel>
                    <Divider height="1" bg="brand.lightGray" />
                </>
            )}
        </AccordionItem>
    );
};
export default BreakoutSessionAccordionElement;
