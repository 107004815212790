import type {
    Field,
    ImageField,
    ComponentRendering,
    ComponentParams,
} from "@sitecore-jss/sitecore-jss-nextjs";
import { useSitecoreContext, Text as JssText } from "@sitecore-jss/sitecore-jss-nextjs";
import { Box, List, Text, ListItem, ListIcon } from "@chakra-ui/react";
import BundleIcon from "commons/ui/BundleIcon";
import useImageSizes, { imageSize } from "utils/hooks/useImageSizes";
import SCHeading from "commons/sc/SCHeading";
import SCText from "commons/sc/SCText";
import SCLinkButton from "commons/sc/SCLinkButton";
import SCImage from "commons/sc/SCImage";
import type { GenericHeaderProps } from "commons/ui/GenericHeader";
import GenericHeader from "commons/ui/GenericHeader";

interface Fields {
    Headline: Field<string>;
    Text: Field<string>;
    CTA: Field<{ text: string; href: string }>;
    Image: ImageField;
    ElementHeader: Field<string>;
    ElementBullets: Field<string>;
    ElementText: Field<string>;
}

interface Fields extends GenericHeaderProps {
    fields: Fields;
}

interface ShowcaseProps {
    rendering: ComponentRendering & { params: ComponentParams };
    params: { [key: string]: string };
    fields: Fields;
}

interface ShowcaseBulletListProps {
    fields: Fields;
}

const ShowCaseBulletList = (props: ShowcaseBulletListProps) => {
    const bullets = props.fields?.ElementBullets?.value.split("\r\n");
    const {
        sitecoreContext: { pageEditing },
    } = useSitecoreContext();
    return (
        <Box
            display="flex"
            flexDirection={"column"}
            gap="sp16"
            bg="white"
            px={{ base: "sp24", lg: "sp60" }}
            pt={{ base: "sp24", lg: "sp36" }}
            pb={{ base: "sp24", lg: "sp48" }}
            borderRadius={"base"}
            gridColumn={{ base: "1fr", lg: "span 3" }}
        >
            <SCHeading
                as="h2"
                textField={props.fields?.ElementHeader}
                size="md"
                id={props.fields.Id}
            />
            <SCText textField={props.fields?.ElementText} size="bodySmall" />

            {pageEditing && <JssText field={props.fields?.ElementBullets} />}
            {!pageEditing && props.fields?.ElementBullets?.value !== "" && (
                <List
                    pt={{ base: "sp16", lg: "sp32" }}
                    display={"grid"}
                    gridTemplateColumns={{ base: "1fr", lg: "1fr 1fr 1fr" }}
                    gap="sp16"
                    flexDirection={{ base: "column", lg: "row" }}
                    placeItems={"flex-start"}
                >
                    {bullets?.map((bullet, index) => {
                        return (
                            <ListItem
                                key={`${bullet}-${index}`}
                                display={"grid"}
                                gap="sp12"
                                gridTemplateColumns={"auto 1fr"}
                                alignItems={"top"}
                            >
                                <ListIcon as={BundleIcon} size="md" name="Verified" />
                                <Text as="span" size="body">
                                    {bullet}
                                </Text>
                            </ListItem>
                        );
                    })}
                </List>
            )}
        </Box>
    );
};

const Showcase = (props: ShowcaseProps) => {
    const sizes = useImageSizes({ base: 1, sm: 1, md: 2 / 3 });

    if (!props.fields) return null;
    return (
        <GenericHeader
            Id={props.rendering?.uid ?? props.rendering?.componentName}
            AnchorId={props.fields?.AnchorId}
            HeaderHeadline={props.fields?.HeaderHeadline}
            HeaderSubHeadline={props.fields?.HeaderSubHeadline}
            HeaderCTA={props.fields?.HeaderCTA}
            headlineSize={props.params?.HeaderHeadlineSize}
            spacingSize={props.params?.Padding}
        >
            <Box
                display={"grid"}
                px={{ base: "sp16", lg: "sp60" }}
                py="sp48"
                gridTemplateColumns={{ base: "1fr", lg: "1fr 1fr 1fr" }}
                alignItems={"end"}
                rowGap={{ base: "sp40", lg: "sp96" }}
                bg="brand.lighterGray"
                borderRadius={"base"}
            >
                <Box display={"grid"} gap="sp16">
                    <SCHeading textField={props.fields?.Headline} as="h2" size="xl" />
                    <SCText textField={props.fields?.Text} size="bodyXl" />
                    <SCLinkButton
                        mt="sp24"
                        width={"fit-content"}
                        size="sm"
                        rightIcon={<BundleIcon name="ArrowForward" size="md" />}
                        linkField={props.fields?.CTA}
                    >
                        {props.fields?.CTA.value.text}
                    </SCLinkButton>
                </Box>
                <SCImage
                    sizes={sizes}
                    imageField={props.fields?.Image}
                    {...imageSize(Number(props.fields?.Image?.value?.width), 4, 3)}
                    sx={{ objectFit: "contain", width: "100%", height: "100%" }}
                    gridColumn="span 2"
                    aspectRatio={"4/3"}
                />
                <ShowCaseBulletList {...props} />
            </Box>
        </GenericHeader>
    );
};
export default Showcase;
