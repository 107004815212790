import type { Field, ComponentParams, ComponentRendering } from "@sitecore-jss/sitecore-jss-nextjs";
import {
    Box,
    Button,
    Link,
    Heading,
    Text,
    Flex,
    VisuallyHidden,
    List,
    ListItem,
    Stack,
} from "@chakra-ui/react";
import type { GenericHeaderProps } from "commons/ui/GenericHeader";
import GenericHeader from "commons/ui/GenericHeader";
import { useCallback, useEffect, useRef, useState } from "react";
import NextLink from "next/link";
import { useI18n } from "next-localization";
import { useRouter } from "next/router";
import BundleIcon from "commons/ui/BundleIcon";
import type { FormattedJobDataProps, JobProps } from "src/pages/api/career/types";
import { SkeletonContainerList } from "commons/ui/SkeletonContainer";
import { FilterLoadmoreHandler } from "commons/ui/FilterUI";

interface Fields extends GenericHeaderProps {
    JobCategoryLabel: Field<string>;
    LocationLabel: Field<string>;
    SummaryText: Field<string>;
}

export type CareerProps = {
    rendering: ComponentRendering & { params: ComponentParams };
    params: { [key: string]: string };
    fields: Fields;
};

interface JobDataProps extends FormattedJobDataProps {
    firstNewResultIndex: number;
    hasNext: boolean;
}

const Career = (props: CareerProps) => {
    const [careerResults, setCareerResults] = useState<JobDataProps>({
        results: [],
        totalResults: 0,
        categories: [],
        countries: [],
        firstNewResultIndex: 0,
        hasNext: false,
    });
    const { push, query, isReady } = useRouter();
    const [queryString, setQueryString] = useState<string>("");
    const [loading, setLoading] = useState<boolean>(true);
    const [openCategory, setOpenCategory] = useState<string>("");

    const numberOfResultsPerPage = 12;
    const { t } = useI18n();
    const firstNewResultRef = useRef<HTMLAnchorElement>(null);

    const fetchResults = useCallback(async () => {
        setLoading(true);

        try {
            const queryDidChange = JSON.stringify(query) !== queryString;
            const requestBody = {
                category: query.category ? query.category : "",
                country: query.country ? query.country : "",
                offset: queryDidChange ? "0" : careerResults.results.length.toString(),
            };
            const response = await fetch(`/api/career`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(requestBody),
            });
            if (!response.ok) {
                throw new Error("Failed to fetch data");
            }

            const responseData = (await response.json()) as FormattedJobDataProps;

            setCareerResults((prevResults) => ({
                results: queryDidChange
                    ? responseData.results
                    : [...prevResults.results, ...responseData.results],
                categories: responseData.categories,
                countries: responseData.countries,
                totalResults: responseData.totalResults,
                firstNewResultIndex: queryDidChange ? 0 : prevResults.results.length,
                hasNext: responseData.results.length === numberOfResultsPerPage,
            }));
        } catch (error) {
            // eslint-disable-next-line no-console
            console.error("error fetching data", error);
        } finally {
            setLoading(false);
        }
    }, [query, queryString, careerResults.results.length, numberOfResultsPerPage]);

    const onToggleTag = (name: string) => {
        if (name === openCategory) {
            setOpenCategory("");
        } else {
            setOpenCategory(name);
        }
    };
    const onSelectTag = (categoryName: string, tagId: string) => {
        const updatedQuery = { ...query };
        let existingTags = (updatedQuery[encodeURIComponent(categoryName)] as string[]) || [];
        if (existingTags && typeof existingTags === "string") {
            existingTags = [existingTags];
        }

        const tagIndex = existingTags.indexOf(tagId);
        if (tagIndex !== -1) {
            existingTags.splice(tagIndex, 1);
        } else {
            existingTags.push(tagId);
        }

        updatedQuery[encodeURIComponent(categoryName)] = existingTags;
        push({ query: updatedQuery }, undefined, { shallow: true });
    };

    const cleanAllFilters = () => {
        setOpenCategory("");
        push({ query: { path: query["path"] } }, undefined, { shallow: true });
    };

    useEffect(() => {
        if (isReady) {
            fetchResults();
            setQueryString(JSON.stringify(query));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [query, isReady]);

    useEffect(() => {
        if (
            isReady &&
            firstNewResultRef?.current &&
            careerResults.hasNext &&
            careerResults.results.length > numberOfResultsPerPage
        ) {
            firstNewResultRef?.current.focus();
            firstNewResultRef?.current.scrollIntoView({
                behavior: "smooth",
                block: "center",
                inline: "center",
            });
        }
    }, [careerResults, isReady]);

    const renderLoadMoreButtonLabel = ({
        t,
        loading,
        hasNext,
    }: {
        t: (key: string) => string;
        loading: boolean;
        hasNext: boolean;
    }): string => {
        if (loading) {
            return t("loadMoreLoadingA11yText");
        } else if (hasNext) {
            return t("fetchMoreResultsA11yText");
        } else {
            return "";
        }
    };

    const showClearAll = query && Object.keys(query).filter((e) => e !== "path").length > 0;
    const showing = careerResults.results.length;
    const totalResults = careerResults?.totalResults ?? 0;

    const borderBottom = "1px solid lightGray";

    if (!props) return null;

    return (
        <GenericHeader
            Id={props.rendering?.uid ?? props.rendering?.componentName}
            AnchorId={props.fields?.AnchorId}
            HeaderHeadline={props.fields?.HeaderHeadline}
            HeaderSubHeadline={props.fields?.HeaderSubHeadline}
            HeaderCTA={props.fields?.HeaderCTA}
            headlineSize={props.params?.HeaderHeadlineSize}
            spacingSize={props.params?.Padding}
        >
            <>
                <Flex
                    gap={16}
                    pb={"sp24"}
                    mb={"sp24"}
                    justifyContent={"space-between"}
                    alignItems={"center"}
                    position={"relative"}
                    borderBottom={borderBottom}
                    flexWrap={"wrap"}
                >
                    <Text width={{ base: "full", md: "auto" }}>
                        {props.fields?.SummaryText.value
                            .replace("{{totalResults}}", totalResults.toString())
                            .replace("{{showing}}", showing.toString())}
                    </Text>
                    <Flex
                        gap={16}
                        alignItems={"center"}
                        flexWrap={"wrap"}
                        width={{ base: "full", md: "auto" }}
                    >
                        <Flex
                            width={{ base: "100%", md: "auto" }}
                            flexDir={{ base: "row-reverse", md: "inherit" }}
                            justifyContent={{ base: "space-between", md: "inherit" }}
                        >
                            {showClearAll && (
                                <Link
                                    as="button"
                                    variant={"clearAllLink"}
                                    onClick={cleanAllFilters}
                                    mr="sp24"
                                >
                                    {t("clearAllButtonText")}
                                </Link>
                            )}
                            <Text>{t("filterLabelText")}</Text>
                        </Flex>

                        <Button
                            variant={"outline"}
                            size={"sm"}
                            {...(openCategory === "country" && {
                                sx: {
                                    span: {
                                        transform: "rotate(180deg)",
                                    },
                                },
                            })}
                            onClick={() => {
                                onToggleTag("country");
                            }}
                            onKeyUp={(e) => {
                                if (
                                    e.key === "Enter" ||
                                    (e.key === " " && openCategory === "country")
                                ) {
                                    const firstTag = document
                                        .getElementById(`tagcategorytags-country`)
                                        ?.querySelector("button");
                                    if (firstTag) {
                                        firstTag.focus();
                                    }
                                }
                            }}
                            rightIcon={<BundleIcon name={"ExpandMore"} />}
                            id={`filterCategory-country`}
                            aria-controls={`filterCategory-country`}
                            aria-expanded={openCategory === "country"}
                            isLoading={loading}
                        >
                            {props.fields?.LocationLabel.value}{" "}
                            {careerResults.countries.length && (
                                <>
                                    ({careerResults.countries.length ?? 0})
                                    <VisuallyHidden>{t("selectedTagsA11yText")}</VisuallyHidden>
                                </>
                            )}
                        </Button>

                        <Button
                            variant={"outline"}
                            size={"sm"}
                            {...(openCategory === "category" && {
                                sx: {
                                    span: {
                                        transform: "rotate(180deg)",
                                    },
                                },
                            })}
                            onClick={() => {
                                onToggleTag("category");
                            }}
                            onKeyUp={(e) => {
                                if (
                                    e.key === "Enter" ||
                                    (e.key === " " && openCategory === "category")
                                ) {
                                    const firstTag = document
                                        .getElementById(`tagcategorytags-category`)
                                        ?.querySelector("button");
                                    if (firstTag) {
                                        firstTag.focus();
                                    }
                                }
                            }}
                            rightIcon={<BundleIcon name={"ExpandMore"} />}
                            id={`filterCategory-category`}
                            aria-controls={`filterCategory-category`}
                            aria-expanded={openCategory === "category"}
                            isLoading={loading}
                        >
                            {props.fields?.JobCategoryLabel.value}{" "}
                            {careerResults.categories.length && (
                                <>
                                    ({careerResults.categories.length ?? 0})
                                    <VisuallyHidden>{t("selectedTagsA11yText")}</VisuallyHidden>
                                </>
                            )}
                        </Button>
                    </Flex>
                </Flex>
                <Flex
                    id={`tagcategorytags-country`}
                    aria-labelledby={`tagcategory-country`}
                    {...(openCategory !== "country" && {
                        hidden: true,
                    })}
                    borderBottom={borderBottom}
                    pb={"sp24"}
                    gap={"sp16"}
                    flexWrap={"wrap"}
                    marginBottom={"sp40"}
                >
                    {careerResults.countries?.map((country) => {
                        const selected = query["country"]?.includes(country.id);
                        return (
                            <Button
                                key={`${country.id}_option`}
                                variant={selected ? "primary" : "outline"}
                                size={"xs"}
                                onClick={() => onSelectTag("country", country.id)}
                                aria-pressed={selected}
                                {...(selected && {
                                    rightIcon: <BundleIcon name={"Check"} />,
                                })}
                            >
                                {country.name}
                            </Button>
                        );
                    })}
                </Flex>
                <Flex
                    id={`tagcategorytags-category`}
                    aria-labelledby={`tagcategory-category`}
                    {...(openCategory !== "category" && {
                        hidden: true,
                    })}
                    borderBottom={borderBottom}
                    pb={"sp24"}
                    gap={"sp16"}
                    flexWrap={"wrap"}
                    marginBottom={"sp40"}
                >
                    {careerResults.categories?.map((category) => {
                        const selected = query["category"]?.includes(category.id);
                        return (
                            <Button
                                key={`${category.id}_option`}
                                variant={selected ? "primary" : "outline"}
                                size={"xs"}
                                onClick={() => onSelectTag("category", category.id)}
                                aria-pressed={selected}
                                {...(selected && {
                                    rightIcon: <BundleIcon name={"Check"} />,
                                })}
                            >
                                {category.name}
                            </Button>
                        );
                    })}
                </Flex>
            </>

            <VisuallyHidden role="status" aria-live="polite">
                {renderLoadMoreButtonLabel({ t, loading, hasNext: careerResults.hasNext })}
            </VisuallyHidden>

            {!loading && careerResults.results.length === 0 && query.q && (
                <Box mt={{ base: "sp40", lg: "sp88" }}>
                    <Text as="p" size="bodyXl">
                        {t("noSearchResults")}
                    </Text>
                </Box>
            )}

            {careerResults.results.length > 0 && (
                <List
                    display={"flex"}
                    mt={{ base: "sp40", lg: "sp88" }}
                    flexDir={"column"}
                    gap={"sp40"}
                >
                    {careerResults.results.map((result: JobProps, index: number) => {
                        const isFirstNewResult = index === careerResults.firstNewResultIndex;
                        return (
                            <ListItem key={result.url}>
                                <Link
                                    as={NextLink}
                                    ref={isFirstNewResult ? firstNewResultRef : null}
                                    href={result.url}
                                    variant={"searchLink"}
                                    target="_blank"
                                    {...(index === 0 && {
                                        ref: firstNewResultRef,
                                    })}
                                >
                                    <Stack>
                                        <Heading
                                            as="h3"
                                            size="sm"
                                            textDecoration={"underline"}
                                            lineHeight={"base"}
                                            mb={{ base: "sp8", lg: "sp8" }}
                                        >
                                            {result.title}
                                        </Heading>
                                        <Text
                                            size="bodySmall"
                                            sx={{
                                                ".max": {
                                                    fontWeight: "bold",
                                                },
                                            }}
                                            display={"flex"}
                                            alignItems={"center"}
                                        >
                                            <BundleIcon name={"Label"} mr={"sp8"} />
                                            {result.categories
                                                ?.map((category) => category.name)
                                                .join(", ")}
                                        </Text>
                                        <Text
                                            size="bodySmall"
                                            display={"flex"}
                                            alignItems={"center"}
                                        >
                                            <BundleIcon name={"Location"} mr={"sp8"} />

                                            {result.country.name}
                                        </Text>
                                    </Stack>
                                </Link>
                            </ListItem>
                        );
                    })}
                </List>
            )}
            {loading && <SkeletonContainerList numberOfResultsPerPage={numberOfResultsPerPage} />}

            <FilterLoadmoreHandler
                loading={loading}
                hasNext={careerResults.hasNext}
                fetchSearchResults={fetchResults}
                ariaControls={props.rendering.uid}
            />
        </GenericHeader>
    );
};

export default Career;
