import { getPublicUrl } from "@sitecore-jss/sitecore-jss-nextjs/utils";
import Head from "next/head";

const Favicon = () => {
    const publicUrl = getPublicUrl();
    return (
        <Head>
            <link rel="icon" href={`${publicUrl}/favicon.ico`} />
            <link
                rel="apple-touch-icon"
                sizes="180x180"
                href={`${publicUrl}/favicons/apple-touch-icon.png`}
            />
            <link
                rel="icon"
                type="image/png"
                sizes="32x32"
                href={`${publicUrl}/favicons/favicon-32x32.png`}
            />
            <link
                rel="icon"
                type="image/png"
                sizes="16x16"
                href={`${publicUrl}/favicons/favicon-16x16.png`}
            />
            <link rel="manifest" href={`/favicons/manifest.json`} />
            <link
                rel="mask-icon"
                href={`${publicUrl}/favicons/safari-pinned-tab.svg`}
                color="#000000"
            />
            <meta name="msapplication-TileColor" content="#000000" />
            <meta name="theme-color" content="#ffffff"></meta>
        </Head>
    );
};

export default Favicon;
