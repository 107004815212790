const jssConfig = require("./src/temp/config");
const { getPublicUrl } = require("@sitecore-jss/sitecore-jss-nextjs/utils");
const plugins = require("./src/temp/next-config-plugins") || {};
const publicUrl = getPublicUrl();
// const withBundleAnalyzer = require("@next/bundle-analyzer")();

const isProd = process.env.NODE_ENV === "production";
const isProductionSite = process.env.VERCEL_DOMAIN_URL === "www.simcorp.com";
/**
 * @type {import('next').NextConfig}
 */
const nextConfig = {
    // Set assetPrefix to our public URL
    assetPrefix: isProd ? undefined : publicUrl,

    productionBrowserSourceMaps: !isProductionSite,

    // Allow specifying a distinct distDir when concurrently running app in a container
    distDir: process.env.NEXTJS_DIST_DIR || ".next",

    // Make the same PUBLIC_URL available as an environment variable on the client bundle
    env: {
        PUBLIC_URL: publicUrl,
        VERCEL_DOMAIN_URL: process.env.VERCEL_DOMAIN_URL,
        GOOGLE_CSE_CX: process.env.GOOGLE_CSE_CX,
        GOOGLE_CSE_API_KEY: process.env.GOOGLE_CSE_API_KEY,
    },

    i18n: {
        // These are all the locales you want to support in your application.
        // These should generally match (or at least be a subset of) those in Sitecore.
        locales: ["en", "it-IT"],
        defaultLocale: jssConfig.defaultLanguage,
        // This is the locale that will be used when visiting a non-locale
        // prefixed path e.g. `/styleguide`.
        localeDetection: false,
    },

    // Enable React Strict Mode
    reactStrictMode: true,

    images: {
        dangerouslyAllowSVG: true,
        formats: ["image/avif", "image/webp"],
        remotePatterns: [
            {
                protocol: "https",
                hostname: "edge*.**",
                port: "",
            },
            {
                protocol: "https",
                hostname: "xmc-*.**",
                port: "",
            },
            {
                protocol: "https",
                hostname: "feaas*.blob.core.windows.net",
                port: "",
            },
            {
                protocol: "http",
                hostname: "cm",
            },
            {
                protocol: "http",
                hostname: "xmcloudcm.localhost",
            },
            {
                protocol: "https",
                hostname: "xmcloudcm.localhost",
            },
            {
                protocol: "https",
                hostname: "simcorpexternal.23video.com",
            },
            {
                protocol: "http",
                hostname: "simcorpexternal.23video.com",
            },
        ],
    },

    // Svg icons
    webpack(config) {
        config.module.rules.push({
            test: /\.svg$/i,
            issuer: /\.[jt]sx?$/,
            use: [
                {
                    loader: "@svgr/webpack",
                    options: {
                        svgoConfig: {
                            plugins: [
                                {
                                    name: "preset-default",
                                    params: {
                                        overrides: {
                                            cleanupIds: true,
                                            removeViewBox: false,
                                        },
                                    },
                                },
                                {
                                    name: "removeAttrs",
                                    params: {
                                        attrs: "width|height",
                                        elemSeparator: ":",
                                        preserveCurrentColor: false,
                                    },
                                },
                                "removeXMLNS",
                            ],
                        },
                    },
                },
            ],
        });

        return config;
    },

    async rewrites() {
        // When in connected mode we want to proxy Sitecore paths off to Sitecore
        return [
            // API endpoints
            {
                source: "/sitecore/api/:path*",
                destination: `${jssConfig.sitecoreApiHost}/sitecore/api/:path*`,
            },
            // media items
            {
                source: "/-/:path*",
                destination: `${jssConfig.sitecoreApiHost}/-/:path*`,
            },
            // healthz check
            {
                source: "/healthz",
                destination: "/api/healthz",
            },
            // rewrite for Sitecore service pages
            {
                source: "/sitecore/service/:path*",
                destination: `${jssConfig.sitecoreApiHost}/sitecore/service/:path*`,
            },
        ];
    },
};

module.exports = () => {
    // Run the base config through any configured plugins
    const configMerge = Object.values(plugins).reduce((acc, plugin) => plugin(acc), nextConfig);
    // const withPlugins =
    //     process.env.ANALYZE === "true" ? withBundleAnalyzer(configMerge) : configMerge;
    return configMerge;
};
