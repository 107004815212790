import { useToken } from "@chakra-ui/react";

export type UseImageSizesParams = {
    [key: string]: number;
};

export const imageSize = (width: number, ratioW: number, ratioH: number) => {
    return {
        width: width,
        height: Math.round(width * (ratioH / ratioW)),
    };
};

/**
 * @description - returns sizes string based on chakra breakpoints. Must accept a "base" value
 *
 * @example
 * const sizes = useImageSizes({base: 1, md: 2/3, lg: 1/3})
 *
 * return <Image
 *  sizes={sizes}
 *  // image props
 * />
 */
const useImageSizes = (sizes: UseImageSizesParams, hasContainerWidth?: boolean): string => {
    // Container width
    const [containerWidth] = useToken("sizes", ["container.xl"]);

    // Breakpoint keys without "base" from largest to smallest
    const breakpointKeys = Object.keys(sizes)
        .filter((size) => size !== "base")
        .reverse();

    // breakpoint values from theme
    const breakpoints = useToken("breakpoints", breakpointKeys);

    // Build sizes string
    return `${
        hasContainerWidth
            ? `(min-width: ${containerWidth}) ${
                  parseFloat(containerWidth) * sizes[breakpointKeys[0] || "base"]
              }rem, `
            : ""
    }${breakpoints.reduce(
        (acc, value, index) =>
            `${acc}(min-width: ${value}) ${sizes[breakpointKeys[index]] * 100}vw, `,
        ""
    )}${sizes?.base * 100}vw`;
};

useImageSizes.default = {
    hasContainer: true,
};

export default useImageSizes;
