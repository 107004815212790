import { Button, Flex, Text } from "@chakra-ui/react";

import BundleIcon from "commons/ui/BundleIcon";
import { type PartnerResults } from "components/PartnersFilter";
import { useI18n } from "next-localization";
import { useRouter } from "next/router";

interface FilterItem {
    id: string;
    title: {
        jsonValue: {
            value: string;
        };
    };
}

interface FiltersProps {
    partnerResults: PartnerResults;
    filterItems: FilterItem[];
    cleanFilters: () => void;
}

const Filters = ({ partnerResults, filterItems, cleanFilters }: FiltersProps) => {
    const { t } = useI18n();
    const { push, query } = useRouter();
    const showing = partnerResults.results.length;
    const totalResults = partnerResults?.totalResults ?? 0;

    const onSelectTag = (tagId: string) => {
        const updatedQuery = { ...query };
        updatedQuery.tagId = tagId;
        push({ query: updatedQuery }, undefined, { shallow: true });
    };

    return (
        <>
            <Flex
                gap={16}
                pb={"sp24"}
                mb={"sp24"}
                justifyContent={"space-between"}
                alignItems={"center"}
                position={"relative"}
                borderBottom={"1px solid lightGray"}
                flexWrap={"wrap"}
            >
                <Text width={{ base: "full", md: "auto" }}>
                    {t("showingOfArticlesText", {
                        showing,
                        totalResults,
                    })}
                </Text>
            </Flex>
            <Flex
                borderBottom={"1px solid lightGray"}
                pb={"sp24"}
                gap={"sp16"}
                flexWrap={"wrap"}
                marginBottom={"sp40"}
            >
                <Button
                    variant={!query.tagId ? "primary" : "outline"}
                    size={"xs"}
                    onClick={() => cleanFilters()}
                >
                    {"All"}
                </Button>
                {filterItems?.map((tag) => {
                    const selected =
                        typeof query.tagId === "string" && query.tagId.split(",").includes(tag.id);
                    return (
                        <Button
                            key={`${tag.id}_option`}
                            variant={selected ? "primary" : "outline"}
                            size={"xs"}
                            onClick={() => onSelectTag(tag.id)}
                            aria-pressed={selected}
                            {...(selected && {
                                rightIcon: <BundleIcon name={"Check"} />,
                            })}
                        >
                            {tag.title.jsonValue.value}
                        </Button>
                    );
                })}
            </Flex>
        </>
    );
};

export default Filters;
