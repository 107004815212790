import { sendGTMEvent } from "@next/third-parties/google";
import { useRouter } from "next/router";
import { useEffect } from "react";

export const gtmCustomEvent = (
    eventName: string,
    eventData: Record<string, string | undefined>
) => {
    sendGTMEvent({ event: eventName, value: eventData });
};

export const gtmCTAEvent = (name: string, subType: string, value?: string) => {
    gtmCustomEvent("ctaClick", {
        type: "cta",
        name: name,
        sub_type: subType,
        value: value,
    });
};

export const GTMPageviewTracking = () => {
    const router = useRouter();
    useEffect(() => {
        const handleRouteChange = () => {
            sendGTMEvent({ event: "pageview" });
        };
        router.events.on("routeChangeComplete", handleRouteChange);
        return () => {
            router.events.off("routeChangeComplete", handleRouteChange);
        };
    }, [router.events]);
    return null;
};
