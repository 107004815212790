import type { Field, ImageField, LinkField } from "@sitecore-jss/sitecore-jss-nextjs";
import { Box, Container, Heading, Text, useBreakpointValue } from "@chakra-ui/react";
import { useEffect, useRef } from "react";
import useImageSizes from "utils/hooks/useImageSizes";
import breakpoints from "themes/foundations/breakpoints";
import LinkButton from "commons/ui/LinkButton";
import BundleIcon from "commons/ui/BundleIcon";
import SplitType from "split-type";
import SCImage from "commons/sc/SCImage";
import { modifyLineSplitting } from "commons/ui/GenericHeader";

interface Fields {
    Id: string;
    Headline: Field<string>;
    SubHeadline: Field<string>;
    SliderText: Field<string>;
    CTA: LinkField;
    BackgroundVideoMobile?: Field<string>;
    BackgroundVideoDesktop?: Field<string>;
    BackgroundImageMobile?: ImageField;
    BackgroundImageDesktop?: ImageField;
}

export type HeroCarouselElementProps = {
    id: string;
    fields: Fields;
    index: number;
    currentSlideIndex: number;
    firstLoad: boolean;
    loaded: boolean;
};

type HeroCarouselBackgroundProps = {
    fields: Fields;
    index: number;
    currentSlideIndex: number;
    loaded: boolean;
};

const HeroBackground = (props: HeroCarouselBackgroundProps) => {
    const isMobileViewport = useBreakpointValue({ base: true, lg: false }, { ssr: true });
    const imageMobile = props.fields?.BackgroundImageMobile;
    const imageDesktop = props.fields?.BackgroundImageDesktop;
    const videoMobile = props.fields?.BackgroundVideoMobile?.value;
    const videoDesktop = props.fields?.BackgroundVideoDesktop?.value;

    const sizes = useImageSizes({ base: 2 / 3 }, false);

    return (
        <Box
            sx={{
                position: "absolute",
                top: 0,
                left: 0,
                width: "full",
                height: "full",
                bgColor: "black",
                bgSize: "300% 200%",
                bgPos: "90% 0",
                zIndex: 0,
                video: {
                    position: "absolute",
                    width: "100%",
                    height: "100%",
                    objectFit: "cover",
                    objectPosition: "center",
                },
                "> div:first-of-type, > img:first-of-type": {
                    display: { base: "block", lg: "none" },
                },
                "> div:last-of-type, > img:last-of-type": {
                    display: { base: "none", lg: "block" },
                },
                "> div, .sc-image-wrapper": {
                    height: "full",
                },
            }}
        >
            <>
                {videoMobile !== "" && videoDesktop !== "" && (
                    <video autoPlay loop muted playsInline>
                        <source
                            type="video/webm"
                            media={`(min-width: ${String(breakpoints.lg)})`}
                            src={videoDesktop?.replaceAll("mp4", "webm")}
                        />
                        <source
                            type="video/mp4"
                            media={`(min-width: ${String(
                                breakpoints.lg
                            )}) and (prefers-reduced-motion: no-preference)`}
                            src={videoDesktop}
                        />
                        <source
                            type="video/webm"
                            media={`(prefers-reduced-motion: no-preference)`}
                            src={videoMobile?.replaceAll("mp4", "webm")}
                        />
                        <source
                            type="video/mp4"
                            media={`(prefers-reduced-motion: no-preference)`}
                            src={videoMobile}
                        />
                    </video>
                )}
                {imageMobile?.value?.src !== "" &&
                    imageDesktop?.value?.src !== "" &&
                    videoMobile === "" &&
                    videoDesktop === "" &&
                    props.index === props.currentSlideIndex && (
                        <>
                            {isMobileViewport ? (
                                <SCImage
                                    imageField={imageMobile}
                                    fill
                                    quality={60}
                                    loading="lazy"
                                    sizes={sizes}
                                    contentEditable={false}
                                    sx={{
                                        width: "full",
                                        objectFit: "cover",
                                        display: { base: "block", lg: "none" },
                                    }}
                                />
                            ) : (
                                <SCImage
                                    imageField={imageDesktop}
                                    fill
                                    quality={60}
                                    loading="lazy"
                                    sizes={sizes}
                                    contentEditable={false}
                                    sx={{
                                        width: "full",
                                        objectFit: "cover",
                                        display: { base: "none", lg: "block" },
                                    }}
                                />
                            )}
                        </>
                    )}
            </>
        </Box>
    );
};

const HeroCarouselElement = (props: HeroCarouselElementProps) => {
    const headlineRef = useRef<HTMLHeadingElement>(null);
    const isShown = props.currentSlideIndex === props.index;

    useEffect(() => {
        if (headlineRef.current && isShown) {
            const text = new SplitType(headlineRef.current, {
                types: "lines",
                tagName: "span",
            });

            modifyLineSplitting(text);
        }
    }, [headlineRef, isShown]);

    return (
        <Box
            transition={`opacity 0.3s linear`}
            opacity={isShown ? 1 : 0}
            sx={{
                position: "absolute",
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                height: "full",
                width: "full",
                pt: [
                    "calc(var(--sizes-headerMobileTopHeight))",
                    null,
                    null,
                    "calc(var(--sizes-headerTopHeight) - 41px)",
                ],
            }}
        >
            {props.fields?.Headline?.value && (
                <Container
                    position={"relative"}
                    zIndex={1}
                    display="flex"
                    width={"full"}
                    height={"full"}
                    alignContent={"center"}
                    px={"clamp(2.625rem, 0.028rem + 11.08vw, 10rem)"}
                    justifyContent={{
                        lg: "center",
                    }}
                    flexDir={"column"}
                    pt={{
                        base: "sp40",
                        lg: 0,
                    }}
                    pb="calc(5svh + 44px)"
                    pr={{
                        lg: "calc(clamp(2.625rem, 0.028rem + 11.08vw, 10rem) + 16%)",
                    }}
                    sx={{
                        containerType: "inline-size",
                    }}
                >
                    <Heading
                        ref={headlineRef}
                        as={props.index === 0 ? "h1" : "h2"}
                        size="h1"
                        overflow="hidden"
                        sx={{
                            wordWrap: "normal",
                            span: {
                                wordWrap: "normal",
                            },
                            ".line": {
                                height: "1em",
                                overflow: "hidden",
                            },
                            ".innerLine": {
                                display: "block",
                                transform: `translateY(${isShown ? 0 : "-1.1em"})`,
                                transition: "transform 0.8s ease-out",
                            },
                        }}
                    >
                        {props.fields?.Headline?.value}
                    </Heading>

                    <Text size={"md"} mt={{ base: "sp32", lg: "sp68" }}>
                        {props.fields?.SubHeadline.value}
                    </Text>
                    {props.fields?.CTA.value.href && props.fields?.CTA.value.text && (
                        <LinkButton
                            sx={{ mt: { base: "sp28", lg: "sp50" }, width: "fit-content" }}
                            href={props.fields?.CTA.value.href}
                            size="sm"
                            tabIndex={isShown ? 0 : -1}
                            variant={"secondary"}
                            rightIcon={<BundleIcon name="ArrowForward" />}
                            {...(props.index > 0 && { prefetch: false })}
                        >
                            {props.fields?.CTA.value.text}
                        </LinkButton>
                    )}
                </Container>
            )}

            <HeroBackground {...props} />
        </Box>
    );
};
export default HeroCarouselElement;
