import { Flex } from "@chakra-ui/react";
import type { ComponentParams, Field } from "@sitecore-jss/sitecore-jss-nextjs";
import SCHeading from "commons/sc/SCHeading";
import SCText from "commons/sc/SCText";

interface Fields {
    LargeText: Field<string>;
    Text?: Field<string>;
}

interface DigitsAndDataElementProps {
    params: ComponentParams;
    fields: Fields;
}

const DigitsAndDataElement = (props: DigitsAndDataElementProps) => {
    if (!props.fields) return null;
    return (
        <Flex
            alignItems={["flex-start", null, null, "flex-end"]}
            flexDir={["column", null, null, "row"]}
            flexWrap="wrap"
            pt={["sp48", null, null, "sp68"]}
            pb={["sp48", null, null, "sp36"]}
            borderBottom="1px solid rgba(255,255,255, 0.2)"
        >
            <SCHeading
                textField={props.fields?.LargeText}
                as="p"
                size="digitsAndDataSize"
                variant="digitsAndDataInfo"
                pr={[null, null, null, "sp40"]}
                flex="0 0 auto"
                minWidth="58%"
                maxWidth="100%"
                noOfLines={2}
                sx={{
                    wordWrap: "break-word",
                }}
            />
            <SCText
                textField={props.fields?.Text}
                size="bodyLarge"
                mt={["sp16", null, null, "sp24"]}
                mb={[0, null, null, "sp12"]}
                flex={1}
            />
        </Flex>
    );
};

export default DigitsAndDataElement;
