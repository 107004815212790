import type { BundleIconProps } from "./BundleIcon";
import BundleIcon from "./BundleIcon";

const Logos: { [key: string]: BundleIconProps } = {
    SimCorpCom: { name: "SimcorpFullLogo", width: "144px", height: "29px", viewBox: "0 0 144 29" },
    Sofia: {
        name: "SofiaLogo",
        width: "93px",
        height: "50px",
        viewBox: "0 0 93 50",
    },
};

const SiteLogo = ({ sitename }: { sitename: string }) => {
    const logo = Logos[sitename] ?? Logos.SimCorpCom;
    return (
        <BundleIcon
            name={logo.name ?? "SimCorpCom"}
            width={logo.width ?? "144px"}
            height={logo.height ?? "29px"}
            viewBox={logo.viewBox ?? "0 0 144 29"}
            fill="currentColor"
            sx={{
                transitionDuration: ".30s",
                transitionTimingFunction: "ease-in-out",
                transitionProperty: "color",
            }}
        />
    );
};

export default SiteLogo;
