import type {
    ComponentParams,
    ComponentRendering,
    Field,
    ImageField,
    LinkField,
} from "@sitecore-jss/sitecore-jss-nextjs";
import { useSitecoreContext } from "@sitecore-jss/sitecore-jss-nextjs";
import { Box, Flex } from "@chakra-ui/react";
import type { GenericHeaderProps } from "commons/ui/GenericHeader";
import GenericHeader from "commons/ui/GenericHeader";
import BundleIcon from "commons/ui/BundleIcon";
import SCHeading from "commons/sc/SCHeading";
import SCText from "commons/sc/SCText";
import SCLinkButton from "commons/sc/SCLinkButton";
import type { CookieConsentFieldProps } from "commons/ui/CookieConsent";
import VideoPlayer from "commons/ui/VideoPlayer";

interface Fields extends GenericHeaderProps, CookieConsentFieldProps {
    Title: Field<string>;
    Text: Field<string>;
    CTA: LinkField;
    ThumbnailImage: ImageField;
    ThumbnailUrl: Field<string>;
    VideoUrl: Field<string>;
}

export type VideoProps = {
    rendering: ComponentRendering & { params: ComponentParams };
    params: { [key: string]: string };
    fields: Fields;
};

const Video = (props: VideoProps) => {
    const {
        sitecoreContext: { pageEditing },
    } = useSitecoreContext();

    const renderVideoPlayer =
        props.fields?.VideoUrl?.value ||
        props.fields?.ThumbnailUrl?.value ||
        props.fields?.ThumbnailImage?.value?.src ||
        pageEditing;

    if (!props.fields) return null;

    const cookieConsentFields = {
        AnalyticsCookies: props.fields?.AnalyticsCookies,
        FunctionalCookies: props.fields?.FunctionalCookies,
        MarketingCookies: props.fields?.MarketingCookies,
    };

    const thumbnail = {
        thumbnailAlt: props.fields?.Title.value,
        thumbnailImage: props.fields?.ThumbnailImage,
        thumbnailUrl: props.fields?.ThumbnailUrl.value,
    };

    return (
        <GenericHeader
            Id={props.rendering?.uid ?? props.rendering?.componentName}
            AnchorId={props.fields?.AnchorId}
            HeaderHeadline={props.fields?.HeaderHeadline}
            HeaderSubHeadline={props.fields?.HeaderSubHeadline}
            HeaderCTA={props.fields?.HeaderCTA}
            headlineSize={props.params?.HeaderHeadlineSize}
            spacingSize={props.params?.Padding}
        >
            {pageEditing && (
                <Flex flexDirection="column" gap="sp16" mb="sp16">
                    <Flex flexDirection="column">
                        Thumbnail URL: (Leave empty to choose ThumbnailImage)
                        <SCText
                            textField={props.fields?.ThumbnailUrl}
                            border="1px solid black"
                            mt="sp12"
                            wordBreak="break-word"
                        />
                    </Flex>
                    <Flex flexDirection="column">
                        Video URL:
                        <SCText
                            textField={props.fields?.VideoUrl}
                            border="1px solid black"
                            mt="sp12"
                            wordBreak="break-word"
                        />
                    </Flex>
                </Flex>
            )}

            {renderVideoPlayer && (
                <VideoPlayer
                    cookies={cookieConsentFields}
                    videoUrl={props.fields?.VideoUrl?.value}
                    thumbnail={thumbnail}
                />
            )}

            <Flex
                {...(renderVideoPlayer && { mt: "sp48" })}
                flexDirection={["column", null, null, "row"]}
            >
                <Box flex={1}>
                    <SCHeading as="h3" size="sm" textField={props.fields?.Title} />
                </Box>
                <Box flex={1} mt={["sp16", null, null, 0]}>
                    <SCText textField={props.fields?.Text} />
                    <SCLinkButton
                        linkField={props.fields?.CTA}
                        variant="primary"
                        mt="sp32"
                        rightIcon={<BundleIcon name="ArrowForward" />}
                    />
                </Box>
            </Flex>
        </GenericHeader>
    );
};
export default Video;
