import { Text } from "@chakra-ui/react";
import { Text as JssText, useSitecoreContext } from "@sitecore-jss/sitecore-jss-nextjs";
import type { TextProps } from "@chakra-ui/react";
import type { TextField } from "@sitecore-jss/sitecore-jss-nextjs";

interface SCTextProps extends TextProps {
    textField?: TextField;
}

const SCText = ({ textField, ...props }: SCTextProps) => {
    const {
        sitecoreContext: { pageEditing },
    } = useSitecoreContext();

    if (!textField) return null;
    const trimmedText = (textField.value as string).trim().length > 0;

    if (pageEditing || (!pageEditing && trimmedText)) {
        return (
            <Text {...props}>
                <JssText field={textField} />
            </Text>
        );
    }

    return null;
};

export default SCText;
