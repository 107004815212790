import { Link } from "@chakra-ui/react";
import { useI18n } from "next-localization";

const SkipLink = () => {
    const { t } = useI18n();

    return (
        <Link
            position="fixed"
            href="#main-content"
            top={`calc(var(--sizes-headerTopHeight) / 2)`}
            left="50%"
            transform="translate(-50%, -50%)"
            bg="black"
            color="white"
            px={35}
            py={20}
            borderRadius={100}
            height={30}
            display={["none", null, null, "flex"]}
            justifyContent="center"
            alignItems="center"
            zIndex={1}
            opacity={0}
            textTransform="uppercase"
            transition="all 0.1s, opacity 0.25s, z-index 0s ease 0.25s"
            _focusVisible={{
                outline: "1px dotted black",
                outlineOffset: "2px",
                boxShadow: "0 0 0 2px white",
                transition: "all 0.1s, opacity 0.25s, z-index 0s",
                opacity: 1,
                zIndex: 101,
            }}
        >
            {t("skipLinkLabel")}
        </Link>
    );
};

export default SkipLink;
