import type { ResponsiveValue } from "@chakra-ui/react";

type GenericHeaderSpacingsProps = {
    [key: string]: ResponsiveValue<number | string>;
};

const ghSpacings = {
    "{B672C28B-4C70-49A9-8A05-B8A94E61D79D}": ["sp32", null, null, "sp48"], // Small
    "{58728429-9946-4DBA-9C2B-6DCB54F04FEC}": 0, // None
} as GenericHeaderSpacingsProps;

const getSpacing = (id?: string) => (id ? ghSpacings[id] : ["sp60", null, null, "sp96"]);

export default getSpacing;
