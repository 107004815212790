import { Heading, Link, Text, Flex, AspectRatio } from "@chakra-ui/react";
import type { BoxProps } from "@chakra-ui/react";
import NextLink from "next/link";
import Image from "next/image";
import type { Field, ImageField } from "@sitecore-jss/sitecore-jss-nextjs";
import { useI18n } from "next-localization";
import useImageSizes, { imageSize } from "utils/hooks/useImageSizes";
import { dateFormatter } from "utils/dateFormatter";
import { gtmCTAEvent } from "commons/head/GTMTracking";

interface Fields {
    PageListImage?: ImageField;
    PageListTagline: {
        fields: {
            Tagline: Field<string>;
        };
    };
    Date: Field<string>;
    PageListHeadline: Field<string>;
    PageListTeaserText: Field<string>;
}

export interface ArticleCardProps {
    key?: string;
    fields: Fields;
    url: string;
    imgVariant?: Array<number | null> | number;
}

const ArticleCard = (props: BoxProps & ArticleCardProps) => {
    const sizes = useImageSizes({ base: 1, sm: 1 / 3, md: 1 / 4, lg: 1 / 5 });
    const { t } = useI18n();
    const articleDate = dateFormatter(props.fields?.Date?.value);
    return (
        <Link
            id={props.key}
            as={NextLink}
            href={props.url}
            title={props.fields?.PageListHeadline?.value}
            position="relative"
            height="100%"
            display="flex"
            flexDir="column"
            pb="sp20"
            className="item"
            _focusVisible={{
                outline: "1px dotted black",
                outlineOffset: "2px",
                transition: "all 0.1s",

                span: {
                    _before: {
                        right: "100%",
                    },
                },
            }}
            _hover={{
                span: {
                    _before: {
                        right: "100%",
                    },
                },
            }}
            onClick={() => {
                gtmCTAEvent(
                    props.fields?.PageListHeadline?.value,
                    "Article carousel link",
                    props.url
                );
            }}
        >
            {props.fields?.PageListImage?.value?.src && (
                <AspectRatio
                    ratio={props.imgVariant ?? 3 / 2}
                    width="100%"
                    flex="0 0 auto"
                    overflow="hidden"
                    position="relative"
                    borderRadius={4}
                    mb="sp16"
                >
                    <Image
                        src={props.fields?.PageListImage?.value?.src ?? ""}
                        alt={props.fields?.PageListImage?.value?.alt as string}
                        {...imageSize(Number(props.fields?.PageListImage?.value?.width), 1, 1)}
                        sizes={sizes}
                    />
                </AspectRatio>
            )}
            {(props.fields?.PageListTagline ||
                props.fields?.Date?.value ||
                props.fields?.PageListHeadline?.value) && (
                <>
                    {(props.fields?.PageListTagline?.fields?.Tagline.value ||
                        props.fields?.Date?.value) && (
                        <Flex justifyContent="space-between" mb="sp16">
                            {props.fields?.PageListTagline?.fields?.Tagline.value && (
                                <Text
                                    size="eyebrowSmall"
                                    variant="articleCardHeader"
                                    pr="sp8"
                                    flex={1}
                                >
                                    {props.fields?.PageListTagline?.fields?.Tagline.value}
                                </Text>
                            )}
                            {articleDate && (
                                <Text
                                    size="articleDate"
                                    variant="articleCardHeader"
                                    flex="0 0 auto"
                                >
                                    {articleDate}
                                </Text>
                            )}
                        </Flex>
                    )}

                    {props.fields?.PageListHeadline?.value && (
                        <Heading as="h3" noOfLines={3} size="h5" flex="0 0 auto" mb="sp16">
                            {props.fields?.PageListHeadline?.value}
                        </Heading>
                    )}
                </>
            )}
            {props.fields?.PageListTeaserText?.value && (
                <Text
                    noOfLines={3}
                    size="bodySmall"
                    overflow="hidden"
                    transition="all 0.3s ease"
                    mb="sp16"
                    order={5}
                >
                    {props.fields?.PageListTeaserText?.value}
                </Text>
            )}
            {props.url && (
                <Text as="span" order={6} width={"max-content"} variant="link">
                    {t("readTheArticle")}
                </Text>
            )}
        </Link>
    );
};

export default ArticleCard;
