import type { SessionCategory } from "components/BreakoutSessionAccordion/BreakoutSessionAccordion";

export const breakoutSessionColor = {
    Blue: "#0A7BD7", // Blue
    Black: "#1A1D31", // Black
    Purple: "#4B17B6", // Purple
    Red: "#EF3E4A", // Red
    Orange: "#FB9B2A", // Orange
    Yellow: "#F0CF61", // Yellow
    Cyan: "#7DE6E0", // Cyan
    DarkBlue: "#083EA7", // DarkBlue
} as { [key: string]: string };

export const sortSessionCategories = (sessionCategories: SessionCategory[]) => {
    return sessionCategories.sort((a: SessionCategory, b: SessionCategory) => {
        return (
            Object.keys(breakoutSessionColor).indexOf(a.fields.Color.value) -
            Object.keys(breakoutSessionColor).indexOf(b.fields.Color.value)
        );
    });
};
