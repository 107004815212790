import type { GenericHeaderProps } from "commons/ui/GenericHeader";
import GenericHeader from "commons/ui/GenericHeader";
import CarouselPlaceholder, { CountItems } from "commons/ui/Carousel/CarouselPlaceholder";
import type { ComponentParams, ComponentRendering } from "@sitecore-jss/sitecore-jss-nextjs";
import { useSitecoreContext } from "@sitecore-jss/sitecore-jss-nextjs";
import ImageCarouselElement from "./ImageCarouselElement";

export type ImageCarouselProps = {
    rendering: ComponentRendering & { params: ComponentParams };
    params: { [key: string]: string };
    fields: GenericHeaderProps;
};

const ImageCarousel = (props: ImageCarouselProps) => {
    const {
        sitecoreContext: { pageEditing },
    } = useSitecoreContext();

    const phKey = `ImageCarousel-${props.params.DynamicPlaceholderId}`;
    const slides = props.rendering.placeholders
        ? Object.values(props.rendering.placeholders)[0]
        : [];
    const scSlidesLength = CountItems(slides);
    const isSlidesCountOver = slides && scSlidesLength > 3;
    if (!props.fields) return null;
    return (
        <GenericHeader
            Id={props.rendering.uid ?? props.rendering.componentName}
            AnchorId={props.fields?.AnchorId}
            HeaderHeadline={props.fields?.HeaderHeadline}
            HeaderSubHeadline={props.fields?.HeaderSubHeadline}
            HeaderCTA={props.fields?.HeaderCTA}
            headlineSize={props.params?.HeaderHeadlineSize}
            spacingSize={props.params?.Padding}
        >
            <CarouselPlaceholder
                id={props.rendering?.uid}
                phkey={phKey}
                componentsProps={props.rendering}
                carouselProps={{
                    id: `imageCarousel_${props.fields.AnchorId}`,
                    gap: ["sp16", null, "sp20"],
                    slidesToShow: [1, 2, 2, isSlidesCountOver ? 3.3 : 3],
                    dots: [
                        slides && scSlidesLength > 1,
                        slides && scSlidesLength > 2,
                        null,
                        slides && scSlidesLength > 3.3,
                    ],
                }}
                carouselEditProps={{
                    width: [
                        `calc(100% / 1 * ${scSlidesLength})`,
                        `calc(100% / 2 * ${scSlidesLength})`,
                        `calc(100% / ${isSlidesCountOver ? 3.3 : 3} * ${scSlidesLength})`,
                    ],
                }}
            >
                {!pageEditing &&
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    slides.map((slide: any) => {
                        return <ImageCarouselElement id={slide.uid} key={slide.uid} {...slide} />;
                    })}
            </CarouselPlaceholder>
        </GenericHeader>
    );
};

export default ImageCarousel;
