import {
    Breadcrumb as ChakraBreadcrumb,
    BreadcrumbItem,
    BreadcrumbLink,
    Container,
} from "@chakra-ui/react";
import type { Field } from "@sitecore-jss/sitecore-jss-nextjs";
import BundleIcon from "commons/ui/BundleIcon";

interface Fields {
    data: {
        item: {
            navigationTitle: Field<string>;
            url: {
                path: string;
            };
            displayName: string;
            name: string;
            navigationFilter: {
                targetItems: {
                    key: {
                        value: string;
                    };
                }[];
            };
            ancestors: {
                navigationTitle: Field<string>;
                url: {
                    path: string;
                };
                navigationFilter: {
                    targetItems: {
                        key: {
                            value: string;
                        };
                    }[];
                };
            }[];
        };
    };
}

interface BreadcrumbProps {
    fields: Fields;
}

const getBreadcrumbTitle = (fields: Fields) => {
    if (fields?.data.item.navigationTitle.value !== "") {
        return fields?.data.item.navigationTitle.value;
    } else if (fields?.data.item.displayName !== "") {
        return fields?.data.item.displayName;
    } else {
        return fields?.data.item.name;
    }
};

const Breadcrumb = (props: BreadcrumbProps) => {
    const ancestors = props.fields?.data.item.ancestors;
    const reversedAncestors = ancestors ? [...ancestors].reverse() : [];
    const exlcludedFromBreadcrumb = props.fields?.data?.item?.navigationFilter?.targetItems.some(
        (targetItem) => targetItem.key.value === "breadcrumb"
    );

    const currentPath = props.fields.data.item.url.path;

    if (!props.fields) return null;

    return (
        <Container
            py="sp24"
            display={{ base: "flex", lg: "block" }}
            alignItems={"center"}
            gap="sp8"
        >
            <BundleIcon display={{ base: "block", lg: "none" }} name="ArrowBackIos" size="sm" />
            <ChakraBreadcrumb
                separator=""
                alignItems={"center"}
                lineHeight={1}
                spacing="sp12"
                fontSize="xxs"
            >
                {reversedAncestors.map((ancestor, index) => {
                    const exlcludedFromBreadcrumb = ancestor?.navigationFilter?.targetItems?.some(
                        (targetItem) => targetItem.key.value === "breadcrumb"
                    );
                    const hideItem =
                        reversedAncestors.length === 2
                            ? index === 1
                            : index !== reversedAncestors.length - 1;
                    if (exlcludedFromBreadcrumb) return null;
                    return (
                        <BreadcrumbItem
                            fontWeight={{ base: "medium", lg: "normal" }}
                            sx={{
                                a: {
                                    opacity: { base: "1", lg: ".5" },
                                    transition: "opacity 150ms ease-in-out",
                                    _hover: {
                                        opacity: "1",
                                    },
                                },
                                span: {
                                    display: { base: "none", lg: "block" },
                                    mx: "sp12",
                                    h: "1px",
                                    width: "14px",
                                    bg: "black",
                                    opacity: hideItem ? "0.5" : "1",
                                },
                            }}
                            display={{
                                base: hideItem ? "none" : "flex",
                                lg: "flex",
                            }}
                            key={ancestor.navigationTitle.value}
                        >
                            <BreadcrumbLink href={ancestor.url?.path}>
                                {ancestor.navigationTitle.value}
                            </BreadcrumbLink>
                        </BreadcrumbItem>
                    );
                })}
                {!exlcludedFromBreadcrumb && (
                    <BreadcrumbItem
                        display={{ base: "none", lg: "flex" }}
                        fontWeight={"medium"}
                        opacity={"1"}
                    >
                        <BreadcrumbLink href={currentPath} aria-current="page">
                            {getBreadcrumbTitle(props.fields)}
                        </BreadcrumbLink>
                    </BreadcrumbItem>
                )}
            </ChakraBreadcrumb>
        </Container>
    );
};

export default Breadcrumb;
