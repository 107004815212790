import React from "react";
import type { ComponentRendering } from "@sitecore-jss/sitecore-jss-nextjs";
import { Placeholder } from "@sitecore-jss/sitecore-jss-nextjs";

type DynamicPlaceholderProps = {
    rendering: ComponentRendering;
};

const PartialDesignDynamicPlaceholder = (props: DynamicPlaceholderProps) => (
    <Placeholder name={props.rendering?.params?.sig || ""} rendering={props.rendering} />
);

export default PartialDesignDynamicPlaceholder;
