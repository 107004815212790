import type { ChakraStyledOptions } from "@chakra-ui/react";
import { Link, List, ListItem } from "@chakra-ui/react";
import { useSitecoreContext } from "@sitecore-jss/sitecore-jss-nextjs";
import NextLink from "next/link";
import config from "temp/config";

interface LanguageSelectorProps extends ChakraStyledOptions {
    mobile?: boolean;
}

const LanguageSelector = (props: LanguageSelectorProps) => {
    const { mobile, ...rest } = props;
    const siteLanguages = config?.siteLanguages?.split("|");

    const {
        sitecoreContext: { itemPath },
    } = useSitecoreContext();

    return (
        <>
            {siteLanguages.length > 1 && (
                <List
                    display={mobile ? { base: "flex", lg: "none" } : { base: "none", lg: "flex" }}
                    alignItems={"center"}
                    {...rest}
                >
                    {siteLanguages.map((lang) => {
                        const currentLang = config.defaultLanguage === lang;
                        const href = `${currentLang ? "" : lang}${itemPath}`;
                        return (
                            <ListItem
                                key={lang}
                                _before={{
                                    content: "'|'",
                                }}
                                _first={{
                                    _before: {
                                        display: "block",
                                        content: "''",
                                    },
                                }}
                                display={"flex"}
                                alignItems={"center"}
                                gap={"sp4"}
                                pr={"sp4"}
                            >
                                <Link
                                    as={NextLink}
                                    href={href}
                                    hrefLang={lang}
                                    locale={lang}
                                    variant={"underlineHoverLink"}
                                    fontWeight={"normal"}
                                >
                                    {lang.split("-")[0]}
                                </Link>
                            </ListItem>
                        );
                    })}
                </List>
            )}
        </>
    );
};

export default LanguageSelector;
