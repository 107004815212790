import {
    Heading,
    Text,
    List,
    ListItem,
    Container,
    Flex,
    Accordion,
    AccordionItem,
    AccordionButton,
    AccordionPanel,
    AccordionIcon,
    useBreakpointValue,
    Link,
    Box,
} from "@chakra-ui/react";
import NextLink from "next/link";
import colors, { gradients } from "themes/foundations/color";
import { transparentize } from "polished";
import BundleIcon from "commons/ui/BundleIcon";
import { mapGradient } from "themes/utils/mapGradient";
import { useEffect, useRef, useState } from "react";
import { gtmCTAEvent } from "commons/head/GTMTracking";
import { useSitecoreContext } from "@sitecore-jss/sitecore-jss-nextjs";

interface FooterGroupItemProps {
    id: string;
    displayOpenPositionsLabel: {
        jsonValue: {
            value: boolean;
        };
    };
    link: {
        jsonValue: {
            value: {
                href: string;
                text: string;
                title: string;
                target: string;
            };
        };
    };
}

interface FooterGroupProps {
    id: string;
    groupHeadline: {
        jsonValue: {
            value: string;
        };
    };
    links: {
        results: FooterGroupItemProps[];
    };
}

interface FooterDisclaimerLinkProps {
    id: string;
    url: {
        path: string;
    };
    metadataPageTitle?: {
        jsonValue: {
            value: string;
        };
    };
}

interface FooterDisclaimerLinksProps {
    targetItems: FooterDisclaimerLinkProps[];
}
interface FooterProps {
    fields: {
        data: {
            datasource: {
                groups: {
                    results: FooterGroupProps[];
                };
                copyrightNotice: {
                    jsonValue: {
                        value: string;
                    };
                };
                disclaimerLinks: FooterDisclaimerLinksProps;
            };
        };
    };
}

const FooterGroupItems = ({
    groupItems,
    isOpen,
}: {
    groupItems: FooterGroupItemProps[];
    isOpen: boolean;
}) => {
    return (
        <List
            spacing={8}
            mt={[0, null, null, "sp24"]}
            mb={[0, null, null, "sp40"]}
            overflow={["hidden", null, null, "visible"]}
            height={[isOpen ? null : "0", null, null, "auto"]}
        >
            {groupItems.map((groupItem: FooterGroupItemProps) => (
                <ListItem key={groupItem.id}>
                    {groupItem.link.jsonValue.value.text && (
                        <Link
                            as={NextLink}
                            href={groupItem.link.jsonValue.value.href}
                            {...(groupItem.link.jsonValue.value.target && {
                                target: groupItem.link.jsonValue.value.target,
                            })}
                            variant="footerGroupLink"
                            prefetch={false}
                        >
                            {groupItem.link.jsonValue.value.text}
                            {groupItem.displayOpenPositionsLabel.jsonValue.value && (
                                <Text
                                    background="white"
                                    color="black"
                                    py={4}
                                    px={8}
                                    flex="0 0 auto"
                                    ml={10}
                                    size="openPositionsLabel"
                                >
                                    +10 open positions
                                </Text>
                            )}
                        </Link>
                    )}
                </ListItem>
            ))}
        </List>
    );
};

const FooterGroups = ({ groups }: { groups: FooterGroupProps[] }) => {
    const isMobileViewport = useBreakpointValue({ base: true, lg: false }, { ssr: true });

    if (isMobileViewport) {
        return (
            <Accordion
                display="flex"
                flexDirection={["column", null, null, "row"]}
                borderTop={[`2px solid ${transparentize(0.8, "white")}`, null, null, "none"]}
                borderBottom={[
                    "none",
                    null,
                    null,
                    `2px solid ${transparentize(0.9, colors.white)}`,
                ]}
                mt={["sp16", null, null, 0]}
                allowToggle
            >
                {groups?.map((group: FooterGroupProps) => (
                    <AccordionItem
                        key={group.id}
                        borderBottom={[
                            `2px solid ${transparentize(0.8, "white")}`,
                            null,
                            null,
                            "none",
                        ]}
                        pb={["sp16", null, null, 0]}
                        pr={[0, null, null, "sp56"]}
                        flex={1}
                    >
                        {({ isExpanded }) => (
                            <>
                                <AccordionButton
                                    pt={["sp16", null, null, 0]}
                                    onClick={() => {
                                        gtmCTAEvent(
                                            group.groupHeadline?.jsonValue.value,
                                            isExpanded ? "Foldin accordion" : "Foldout accordion"
                                        );
                                    }}
                                >
                                    <Heading as="span" size="footerGroupTitle">
                                        {group.groupHeadline?.jsonValue.value}
                                    </Heading>
                                    <AccordionIcon ml="auto" />
                                </AccordionButton>
                                <AccordionPanel mt="sp16" mb={[0, null, null, "sp40"]}>
                                    <FooterGroupItems
                                        groupItems={group.links.results}
                                        isOpen={isExpanded}
                                    />
                                </AccordionPanel>
                            </>
                        )}
                    </AccordionItem>
                ))}
            </Accordion>
        );
    }

    return (
        <Flex
            display="flex"
            flexDirection={["column", null, null, "row"]}
            borderTop={[`2px solid ${transparentize(0.8, "white")}`, null, null, "none"]}
            borderBottom={["none", null, null, `2px solid ${transparentize(0.9, colors.white)}`]}
            mt={["sp16", null, null, 0]}
        >
            {groups?.map((group: FooterGroupProps) => (
                <Box
                    key={group.id}
                    as="nav"
                    aria-label={`${group.groupHeadline?.jsonValue.value}`}
                    borderBottom={[`2px solid ${transparentize(0.8, "white")}`, null, null, "none"]}
                    pb={["sp16", null, null, 0]}
                    pr={[0, null, null, "sp56"]}
                    flex={1}
                >
                    <Heading as="h3" size="footerGroupTitle" pt={["sp16", null, null, 0]}>
                        {group.groupHeadline?.jsonValue.value}
                    </Heading>
                    <FooterGroupItems groupItems={group.links.results} isOpen={true} />
                </Box>
            ))}
        </Flex>
    );
};

const FooterDisclaimerLinks = (props: FooterDisclaimerLinksProps) => {
    return (
        <List
            display="flex"
            flexDirection="row"
            flexWrap={["wrap", null, null, "nowrap"]}
            alignItems="center"
            textAlign="center"
            justifyContent="space-between"
            px={["10%", "15%", "20%", 0]}
            width={["100%", null, null, "auto"]}
        >
            {props?.targetItems?.map((link: FooterDisclaimerLinkProps) => {
                return (
                    <ListItem
                        key={link.id}
                        flex="0 0 auto"
                        width={["40%", null, null, "auto"]}
                        pr={[0, null, null, "sp32"]}
                        mt={["sp8", null, null, 0]}
                        px={"sp4"}
                    >
                        <Link
                            as={NextLink}
                            href={link.url.path}
                            variant="footerDisclaimerLink"
                            prefetch={false}
                        >
                            {link?.metadataPageTitle?.jsonValue.value}
                        </Link>
                    </ListItem>
                );
            })}
        </List>
    );
};

const Footer = (props: FooterProps) => {
    const footerRef = useRef<HTMLDivElement>(null);
    const [isFooterLargeStyle, setIsFooterLargeStyle] = useState({});

    const datasourse = props.fields.data.datasource;
    const currentYear = new Date().getFullYear().toString();

    const {
        sitecoreContext: { pageEditing },
    } = useSitecoreContext();

    useEffect(() => {
        const checkFooterHeight = () => {
            if (footerRef.current && footerRef.current?.clientHeight > window.innerHeight - 120) {
                setIsFooterLargeStyle({
                    mt: [0, null, null, "calc(var(--sizes-headerTopHeight))"],
                    transform: [
                        "none",
                        null,
                        null,
                        "translateY(calc(100% + var(--sizes-headerTopHeight) - 100vh))",
                    ],
                });
            } else {
                setIsFooterLargeStyle({});
            }
        };
        checkFooterHeight();
        // Attach event listener for window resize
        window.addEventListener("resize", checkFooterHeight);

        // Clean up the event listener when component unmounts
        return () => {
            window.removeEventListener("resize", checkFooterHeight);
        };
    }, []);

    return (
        <Box
            ref={footerRef}
            as="footer"
            position={["relative", null, null, pageEditing ? null : "sticky"]}
            bottom={0}
            zIndex={0}
            bgGradient={mapGradient(gradients.spectrum, "radial")}
            bgSize="300% 200%"
            bgPos="90% 0"
            color="white"
            pt={["sp32", null, null, "calc(var(--space-sp72))"]}
            pb={["sp36", null, null, "sp48"]}
            _before={{
                content: '""',
                position: "absolute",
                overflow: "hidden",
                top: 0,
                right: 0,
                bottom: 0,
                left: 0,
                bgGradient: mapGradient(gradients.spectrum, "radial"),
                mixBlendMode: "overlay",
                opacity: 0.1,
                zIndex: 2,
                pointerEvents: "none",
                bgSize: "300% 200%",
                bgPos: "90% 0",
                animate: {
                    backgroundSize: [
                        "200% 200%",
                        "250% 200%",
                        "250% 250%",
                        "200% 250%",
                        "200% 200%",
                    ],
                },
                transition: {
                    duration: 16,
                    ease: "easeInOut",
                    repeat: Infinity,
                    repeatType: "loop",
                },
            }}
            _after={{
                content: '""',
                position: "absolute",
                top: 0,
                bottom: 0,
                left: 0,
                right: 0,
                background: "linear-gradient(315deg, rgba(255,255,255,0.2) 0%, rgba(0,0,0,0) 100%)",
                bgSize: ["28px 28px", null, null, null],
                mixBlendMode: "overlay",
                opacity: [0.4, null, null, 0.4],
                zIndex: 3,
                pointerEvents: "none",
            }}
            sx={isFooterLargeStyle}
        >
            <Container>
                {datasourse?.groups?.results.length && (
                    <FooterGroups groups={datasourse.groups.results} />
                )}

                <Flex
                    justifyContent="space-between"
                    alignItems="center"
                    flexDir={["column", null, null, "row"]}
                    mt="sp36"
                >
                    {datasourse?.disclaimerLinks && (
                        <FooterDisclaimerLinks {...datasourse?.disclaimerLinks} />
                    )}
                    <Text
                        size="copyright"
                        color={transparentize(0.5, colors.white)}
                        mt={["sp20", null, null, 0]}
                    >
                        {datasourse?.copyrightNotice?.jsonValue.value.replace(
                            "{year}",
                            currentYear
                        )}
                    </Text>
                </Flex>
                <BundleIcon
                    name="SimcorpTypeLogo"
                    fill="white"
                    width={"100%"}
                    height="auto"
                    viewBox="0 0 485 118"
                    mt={["sp56", null, null, "sp96"]}
                />
            </Container>
        </Box>
    );
};

export default Footer;
