import {
    Box,
    List,
    Stack,
    Skeleton as ChakraSkeleton,
    type SkeletonProps as ChakraSkeletonProps,
} from "@chakra-ui/react";
import { type ReactNode } from "react";
import { pulseLoadingAnimation } from "themes/foundations/animations";
import { pxToRem } from "themes/utils/mapPxToRem";

type SkeletonProps = {
    width?: string;
    height?: string;
    aspectRatio?: string;
};

const loadingAnimation = `${pulseLoadingAnimation} infinite 2s linear`;

const Skeleton = (props: SkeletonProps) => {
    return (
        <Box
            width={props.width}
            height={props.height}
            bg="brand.lightGray"
            borderRadius="base"
            aspectRatio={props.aspectRatio}
            animation={`${pulseLoadingAnimation} infinite 2s linear`}
        />
    );
};

export const SkeletonContainer = (
    props: {
        children: ReactNode;
    } & ChakraSkeletonProps
) => {
    return (
        <ChakraSkeleton
            animation={props.isLoaded ? "none" : `${pulseLoadingAnimation} infinite 2s linear`}
            bgColor={props.isLoaded ? "none" : "brand.lightGray"}
            {...props}
        >
            {props.children}
        </ChakraSkeleton>
    );
};

export const SkeletonContainerGrid = ({
    numberOfResultsPerPage,
}: {
    numberOfResultsPerPage: number;
}) => {
    return (
        <>
            {Array.from({ length: numberOfResultsPerPage }, (_, index) => (
                <Stack key={index} spacing="sp16" w="full" animation={loadingAnimation}>
                    <Skeleton aspectRatio="4/3" />
                    <Box display={"grid"} gridTemplateColumns={"1fr 1fr"} gap="sp16">
                        <Skeleton height={pxToRem("12px")} />
                        <Skeleton height={pxToRem("12px")} />
                    </Box>
                    <Stack spacing="sp4">
                        <Skeleton width="80%" height={pxToRem("16px")} />
                        <Skeleton width="70%" height={pxToRem("16px")} />
                        <Skeleton width="90%" height={pxToRem("16px")} />
                    </Stack>
                    <Stack spacing="sp4">
                        <Skeleton width="70%" height={pxToRem("12px")} />
                        <Skeleton width="60%" height={pxToRem("12px")} />
                        <Skeleton width="80%" height={pxToRem("12px")} />
                    </Stack>
                </Stack>
            ))}
        </>
    );
};

export const SkeletonContainerList = ({
    numberOfResultsPerPage,
}: {
    numberOfResultsPerPage: number;
}) => {
    return (
        <List
            display={"flex"}
            flexDir={"column"}
            gap={"sp40"}
            mt={{ base: "sp40", lg: "sp88" }}
            animation={loadingAnimation}
        >
            {Array.from({ length: numberOfResultsPerPage }, (_, index) => (
                <Stack key={index} as="li" spacing={{ base: "sp8", lg: "sp16" }}>
                    <Skeleton height={pxToRem("30px")} />
                    <Skeleton height={pxToRem("42px")} />
                </Stack>
            ))}
        </List>
    );
};
