import { Box, Button, Heading, Text } from "@chakra-ui/react";
import type { Field } from "@sitecore-jss/sitecore-jss-nextjs";
import { useI18n } from "next-localization";
import { useEffect, useState } from "react";

export interface CookieConsentFieldProps {
    AnalyticsCookies?: Field<boolean>;
    FunctionalCookies?: Field<boolean>;
    MarketingCookies?: Field<boolean>;
}
export interface CookieConsentProps extends CookieConsentFieldProps {
    children?: React.ReactNode;
}

declare global {
    interface Window {
        CookieConsent: {
            renew: () => void;
        };
        CookieInformation: {
            getConsentGivenFor: (cookieCategory: string) => boolean;
        };
    }
}

export const cookieConsentTypes = {
    functional: "cookie_cat_functional",
    statistic: "cookie_cat_statistic",
    marketing: "cookie_cat_marketing",
};

const CookieConsent = (props: CookieConsentProps) => {
    const { t } = useI18n();

    const [cookiesArray, setCookiesArray] = useState<string[] | null>(
        globalThis?.window?.CookieInformation ? [] : null
    );

    useEffect(() => {
        const setCookieConsent = () => {
            const cookiesArray = [];
            if (!globalThis?.window?.CookieInformation) return;
            if (
                props?.FunctionalCookies?.value &&
                !globalThis?.window?.CookieInformation?.getConsentGivenFor(
                    cookieConsentTypes.functional
                )
            ) {
                cookiesArray.push("functional");
            }
            if (
                props?.AnalyticsCookies?.value &&
                !globalThis?.window?.CookieInformation?.getConsentGivenFor(
                    cookieConsentTypes.statistic
                )
            ) {
                cookiesArray.push("statistic");
            }
            if (
                props?.MarketingCookies?.value &&
                !globalThis?.window?.CookieInformation?.getConsentGivenFor(
                    cookieConsentTypes.marketing
                )
            ) {
                cookiesArray.push("marketing");
            }
            setCookiesArray(cookiesArray);
        };

        setCookieConsent();

        globalThis?.window.addEventListener(
            "CookieInformationConsentGiven",
            setCookieConsent,
            false
        );
    }, [
        props,
        props?.AnalyticsCookies?.value,
        props?.FunctionalCookies?.value,
        props?.MarketingCookies?.value,
    ]);

    return (
        <Box position={"relative"}>
            {cookiesArray && cookiesArray.length > 0 && (
                <Box
                    display={"flex"}
                    flexDir={"column"}
                    gap={"sp16"}
                    width={"full"}
                    height={"full"}
                    bgColor={"white"}
                    textAlign={"center"}
                    border={"1px solid black"}
                    padding={"sp16"}
                >
                    <Heading as="h3">{t("cookieconsentFallbackHeading")}</Heading>
                    <Text>
                        {t("cookieconsentFallbackText", {
                            cookietypes: `${cookiesArray?.join(", ").replace(/,\s([^,]+)$/, " and $1")} cookies`,
                        })}
                    </Text>
                    <Button
                        variant="primary"
                        width={"fit-content"}
                        margin={"auto"}
                        size={"sm"}
                        onClick={() => globalThis.window.CookieConsent.renew()}
                    >
                        {t("cookieconsentFallbackCtaText")}
                    </Button>
                </Box>
            )}

            {(cookiesArray?.length === 0 || cookiesArray === null) && props?.children}
        </Box>
    );
};

export default CookieConsent;
