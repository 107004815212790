import { Box, Heading, VisuallyHidden, Text, Link } from "@chakra-ui/react";
import type {
    ComponentParams,
    ComponentRendering,
    ImageField,
    LinkField,
} from "@sitecore-jss/sitecore-jss-nextjs";
import type { GenericHeaderProps } from "commons/ui/GenericHeader";
import GenericHeader from "commons/ui/GenericHeader";
import { SkeletonContainerGrid } from "commons/ui/SkeletonContainer";
import { useI18n } from "next-localization";
import { useRouter } from "next/router";
import { useState, useRef, useCallback, useEffect } from "react";
import useImageSizes from "utils/hooks/useImageSizes";
import { FilterLoadmoreHandler } from "commons/ui/FilterUI";
import Filters from "commons/ui/PartnerFilter/Filters";
import PartnerOverlay from "commons/ui/PartnerFilter/PartnerOverlay";
import PartnerCard from "commons/ui/PartnerFilter/PartnerCard";

export interface PartnerResult {
    id: string;
    partnerName: {
        value: string;
    };
    headline: {
        value: string;
    };
    subHeadline: {
        value: string;
    };
    teaserHeadline: {
        value: string;
    };
    teaserText: {
        value: string;
    };
    text: {
        value: string;
    };
    video: {
        value: string;
    };
    thumbnailImage: {
        jsonValue: ImageField;
    };
    thumbnailUrl: {
        value: string;
    };
    logo: {
        jsonValue: ImageField;
    };
    websiteLink: {
        jsonValue: LinkField;
    };
    downloadLink: {
        jsonValue: LinkField;
    };
    internalPageLink: {
        jsonValue: LinkField;
    };
    linkedInLink: {
        jsonValue: LinkField;
    };
    tags?: {
        jsonValue: { displayName: string }[];
    };
}

export interface PartnerResults {
    results: PartnerResult[];
    totalResults: number;
    firstNewResultIndex: number;
    endCursor: string;
    hasNext: boolean;
}

interface FilterTagCategoryProps {
    targetItem: {
        name: string;
        displayName: string;
        children: {
            results: {
                id: string;
                title: {
                    jsonValue: {
                        value: string;
                    };
                };
            }[];
        };
    };
}

interface Fields extends GenericHeaderProps {
    filterStartItem: {
        jsonValue: {
            id: string;
        };
    };
    filterTagCategory: FilterTagCategoryProps;
    pageTemplate: {
        jsonValue: {
            id: string;
        };
    };
}

type PartnersFilterProps = {
    rendering: ComponentRendering & { params: ComponentParams };
    fields: {
        data: {
            item: Fields;
        };
    };
};

const numberOfResultsPerPage = 12;

const PartnersFilter = (props: PartnersFilterProps) => {
    const fieldData = props.fields.data.item;

    const sizes = useImageSizes({ base: 1, sm: 1 / 2, lg: 1 / 3 });
    const [partnerResults, setPartnerResults] = useState<PartnerResults>({
        results: [],
        totalResults: 0,
        firstNewResultIndex: -1,
        endCursor: "",
        hasNext: false,
    });

    const { push, query, isReady } = useRouter();

    const [queryString, setQueryString] = useState<string>("");
    const [loading, setLoading] = useState<boolean>(true);

    const { t } = useI18n();
    const firstNewResultRef = useRef<HTMLAnchorElement>(null);

    const fetchResults = useCallback(async () => {
        setLoading(true);

        if (!fieldData?.filterStartItem || !fieldData?.pageTemplate) return;

        try {
            const queryDidChange = JSON.stringify(query) !== queryString;

            const requestBody = {
                tagStartId: fieldData?.filterStartItem?.jsonValue?.id,
                templateId: fieldData?.pageTemplate?.jsonValue?.id,
                tagId: typeof query.tagId === "string" ? query.tagId : null,
                numberOfResultsPerPage,
                afterId: !queryDidChange ? partnerResults.endCursor : "",
            };

            const response = await fetch(`/api/partners-filter`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(requestBody),
            });
            if (!response.ok) {
                throw new Error("Failed to fetch data");
            }

            const responseData = await response.json();
            const filteredResults = responseData.results?.filter(
                (item: PartnerResult) => item !== null
            ) as PartnerResult[];

            setPartnerResults((prevResults) => ({
                results: queryDidChange
                    ? [...filteredResults]
                    : [...prevResults.results, ...filteredResults],
                totalResults: responseData.total,
                firstNewResultIndex: queryDidChange ? 0 : prevResults.results.length,
                endCursor: responseData.endCursor,
                hasNext: responseData.hasNext,
            }));
            setLoading(false);
        } catch (error) {
            // eslint-disable-next-line no-console
            console.error("error fetching data", error);
        } finally {
            setLoading(false);
        }
    }, [
        fieldData?.filterStartItem,
        fieldData?.pageTemplate,
        partnerResults.endCursor,
        query,
        queryString,
    ]);

    useEffect(() => {
        if (isReady) {
            fetchResults();
            setQueryString(JSON.stringify(query));
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [query, isReady]);

    useEffect(() => {
        if (
            isReady &&
            firstNewResultRef?.current &&
            partnerResults.hasNext &&
            partnerResults.results.length > numberOfResultsPerPage
        ) {
            firstNewResultRef?.current.focus();
            firstNewResultRef?.current.scrollIntoView({
                behavior: "smooth",
                block: "center",
                inline: "center",
            });
        }
    }, [
        partnerResults.firstNewResultIndex,
        isReady,
        partnerResults.hasNext,
        partnerResults.results.length,
    ]);

    const renderLoadMoreButtonLabel = ({
        t,
        loading,
        hasNext,
    }: {
        t: (key: string) => string;
        loading: boolean;
        hasNext: boolean;
    }): string => {
        if (loading) {
            return t("loadMoreLoadingA11yText");
        } else if (hasNext) {
            return t("fetchMoreResultsA11yText");
        } else {
            return "";
        }
    };

    const cleanAllFilters = () => {
        push({ query: { path: query["path"] } }, undefined, { shallow: true });
    };

    const showClearAll = query && Object.keys(query).filter((e) => e !== "path").length > 0;
    if (!fieldData) return null;

    const filterItems = fieldData.filterTagCategory.targetItem?.children.results;
    const hasFilters = !!filterItems;

    return (
        <GenericHeader
            Id={props.rendering?.uid ?? props.rendering?.componentName}
            AnchorId={props.fields?.data?.item?.AnchorId}
            HeaderHeadline={props.fields?.data?.item?.HeaderHeadline}
            HeaderSubHeadline={props.fields?.data?.item?.HeaderSubHeadline}
            HeaderCTA={props.fields?.data?.item?.HeaderCTA}
            // headlineSize={props.params?.HeaderHeadlineSize}
            // spacingSize={props.params?.Padding}
        >
            {hasFilters && (
                <Filters
                    partnerResults={partnerResults}
                    filterItems={filterItems}
                    cleanFilters={cleanAllFilters}
                />
            )}

            <VisuallyHidden role="output" aria-live="polite">
                {renderLoadMoreButtonLabel({ t, loading, hasNext: partnerResults.hasNext })}
            </VisuallyHidden>

            <PartnerOverlay partners={partnerResults} />

            <Box
                display="grid"
                gridTemplateRows="repeat(5, auto)"
                gridTemplateColumns={{ base: "1fr", sm: "repeat(2, 1fr)", lg: "repeat(3, 1fr)" }}
                gap="sp40"
                aria-busy={loading}
                id={props.rendering.uid}
            >
                {!loading && partnerResults.results.length === 0 && (
                    <Box textAlign={"center"} gridColumn={"1 / 4"} gridRow={"3 / 4"}>
                        <Heading as="h3" size="h4" mb={"sp16"}>
                            {t("noResultsHeading")}
                        </Heading>
                        <Text>{t("noResultsText")}</Text>
                        {showClearAll && (
                            <Link
                                as="button"
                                onClick={cleanAllFilters}
                                variant={"clearAllLink"}
                                mt="sp24"
                            >
                                {t("clearAllButtonText")}
                            </Link>
                        )}
                    </Box>
                )}

                {partnerResults?.results.map((item: PartnerResult) => {
                    const image = item?.logo?.jsonValue?.value;
                    return <PartnerCard image={image} item={item} sizes={sizes} key={item.id} />;
                })}

                {loading && (
                    <SkeletonContainerGrid numberOfResultsPerPage={numberOfResultsPerPage} />
                )}
            </Box>

            <FilterLoadmoreHandler
                loading={loading}
                hasNext={partnerResults.hasNext}
                fetchSearchResults={fetchResults}
                ariaControls={props.rendering.uid}
            />
        </GenericHeader>
    );
};

export default PartnersFilter;
