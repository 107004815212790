import { Container } from "@chakra-ui/react";
import type { ComponentParams, ComponentRendering } from "@sitecore-jss/sitecore-jss-nextjs";
import type { TeaserFields } from "commons/ui/TeaserCard";
import TeaserCard from "commons/ui/TeaserCard";

export type DownloadProps = {
    rendering: ComponentRendering & { params: ComponentParams };
    fields: TeaserFields;
};

const Download = (props: DownloadProps) => {
    if (!props.fields) return null;
    return (
        <Container
            variant="module"
            as="section"
            aria-labelledby={props.rendering.uid ?? props.rendering.componentName}
        >
            <TeaserCard
                variant="imageLeft"
                download
                fields={props.fields}
                id={props.rendering.uid ?? props.rendering.componentName}
            />
        </Container>
    );
};
export default Download;
