import type { TeaserFields } from "commons/ui/TeaserCard";
import TeaserCard from "commons/ui/TeaserCard";

export type GridTeaserElementProps = {
    fields: TeaserFields;
};

export const Default = (props: GridTeaserElementProps) => {
    if (!props.fields) return null;
    return <TeaserCard variant="columns2" fields={props.fields} />;
};

export const Columns3 = (props: GridTeaserElementProps) => {
    if (!props.fields) return null;
    return <TeaserCard variant="columns3" fields={props.fields} />;
};

export const Columns4 = (props: GridTeaserElementProps) => {
    if (!props.fields) return null;
    return <TeaserCard variant="columns4" fields={props.fields} />;
};

export const ImageRight = (props: GridTeaserElementProps) => {
    if (!props.fields) return null;
    return <TeaserCard variant="imageRight" fields={props.fields} />;
};

export const ImageLeft = (props: GridTeaserElementProps) => {
    if (!props.fields) return null;
    return <TeaserCard variant="imageLeft" fields={props.fields} />;
};
