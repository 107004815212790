import parse, { domToReact } from "html-react-parser";
import type { DOMNode, HTMLReactParserOptions } from "html-react-parser";
import { createElement } from "react";
import NextLink from "next/link";
interface HtmlParserProps {
    html: string;
    parseOptions?: HTMLReactParserOptions;
}

const createLink = (domNode: DOMNode, parseOptions?: HTMLReactParserOptions) => {
    if (domNode && domNode.type === "tag" && domNode.name === "a") {
        const href = domNode.attribs?.href;
        if (href !== "") {
            if (href.startsWith("/")) {
                return createElement(
                    NextLink,
                    { href: href?.split("?")[0], target: domNode.attribs?.target },
                    domToReact([domNode.children?.[0] as DOMNode], parseOptions)
                );
            } else {
                return createElement(
                    "a",
                    { href, ...domNode.attribs },
                    domToReact([domNode.children?.[0] as DOMNode], parseOptions)
                );
            }
        }
    }
    return null;
};

export const HtmlReactBasicParser = ({ html, parseOptions }: HtmlParserProps) => {
    const defaultOptions: HTMLReactParserOptions = {
        replace: (domNode: DOMNode) => {
            if (domNode && domNode.type === "tag" && domNode.children?.[0]) {
                switch (domNode.name) {
                    case "h1":
                    case "h2":
                    case "h3":
                    case "h4":
                    case "h5":
                    case "h6":
                        return createElement(
                            "p",
                            {},
                            domToReact([domNode.children?.[0] as DOMNode], parseOptions)
                        );
                    case "a": {
                        return createLink(domNode, parseOptions);
                    }
                    case "img": {
                        return null;
                    }
                }
            }
            return null;
        },
    };

    return <>{parse(html, { ...defaultOptions, ...parseOptions })}</>;
};

export const HtmlReactParser = ({ html, parseOptions }: HtmlParserProps) => {
    const defaultOptions: HTMLReactParserOptions = {
        // eslint-disable-next-line sonarjs/cognitive-complexity
        replace: (domNode: DOMNode) => {
            if (domNode && domNode.type === "tag" && domNode.children?.[0]) {
                const containsNoText = domNode.children?.every((child) => child.type !== "text");
                const containsBr = domNode.children?.some(
                    (child) => child.type === "tag" && child.name === "br"
                );
                if (containsNoText && containsBr) {
                    return createElement("br");
                } else if (containsNoText) {
                    return null;
                }
                switch (domNode.name) {
                    case "h1":
                        return createElement(
                            "h2",
                            {},
                            domToReact([domNode.children?.[0] as DOMNode], parseOptions)
                        );
                    case "h6":
                        return createElement(
                            "blockquote",
                            {},
                            domToReact([domNode.children?.[0] as DOMNode], parseOptions)
                        );
                    case "a": {
                        return createLink(domNode, parseOptions);
                    }
                }
            }
            return null;
        },
    };

    return <>{parse(html, { ...defaultOptions, ...parseOptions })}</>;
};
