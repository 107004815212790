import { AspectRatio, Box, Container, Flex } from "@chakra-ui/react";
import type {
    ComponentParams,
    ComponentRendering,
    Field,
    ImageField,
} from "@sitecore-jss/sitecore-jss-nextjs";
import { Placeholder, useSitecoreContext } from "@sitecore-jss/sitecore-jss-nextjs";
import { gradients } from "themes/foundations/color";
import { mapGradient } from "themes/utils/mapGradient";
import SCImage from "commons/sc/SCImage";
import SCHeading from "commons/sc/SCHeading";
import SCText from "commons/sc/SCText";
import useImageSizes from "utils/hooks/useImageSizes";

interface Fields {
    AnchorId?: Field<string>;
    Headline?: Field<string>;
    BackgroundImage?: ImageField;
    StickDir?: "top" | "bottom";
    SubHeadline?: Field<string>;
    Text?: Field<string>;
}

interface DigitsAndDataProps {
    rendering: ComponentRendering & { params: ComponentParams };
    params: ComponentParams;
    fields: Fields;
}

const getHeadingSize = (headingLength: number) => {
    if (headingLength <= 50) {
        return "xlTitle";
    } else if (headingLength <= 70) {
        return "lgTitle";
    } else {
        return "lgTitle";
    }
};

const getShowAspectRatio = (pageEditing: boolean, fields: Fields) => {
    return (
        pageEditing ||
        (!pageEditing &&
            (fields?.Headline?.value !== "" ||
                (fields?.BackgroundImage?.value?.src &&
                    fields?.BackgroundImage?.value?.src !== "")))
    );
};

const getShowImage = (pageEditing: boolean, fields: Fields) => {
    return pageEditing || (!pageEditing && fields?.BackgroundImage?.value?.src !== "");
};

const DigitsAndDataHeader = (props: DigitsAndDataProps) => {
    const {
        sitecoreContext: { pageEditing },
    } = useSitecoreContext();

    const lengthOfHeading = props.fields?.Headline?.value.length ?? 0;
    const sizes = useImageSizes({ base: 2 / 3 }, false);
    const headingSize = getHeadingSize(lengthOfHeading);

    const showAspectRatio = getShowAspectRatio(pageEditing ?? false, props.fields);
    const showImage = getShowImage(pageEditing ?? false, props.fields);

    return (
        <>
            <Box
                width="100%"
                position="sticky"
                top={
                    props.fields?.StickDir === "top" || !props.fields?.StickDir
                        ? ["var(--sizes-headerMobileTopHeight)", "var(--sizes-headerTopHeight)"]
                        : "auto"
                }
                bottom={props.fields?.StickDir === "bottom" ? 0 : "auto"}
                {...(!pageEditing && { zIndex: -1 })}
                _before={{
                    content: '""',
                    position: "absolute",
                    top: 0,
                    bottom: 0,
                    left: 0,
                    right: 0,
                    bgGradient: mapGradient(gradients.shadowGradient),
                    zIndex: 1,
                    pointerEvents: "none",
                }}
                sx={{
                    ".sc-image-wrapper": {
                        height: "full",
                        img: {
                            width: "100%",
                        },
                    },
                }}
            >
                {showAspectRatio && (
                    <AspectRatio ratio={{ base: 2 / 3, sm: 16 / 9 }} position={"relative"}>
                        {showImage ? (
                            <SCImage
                                imageField={props.fields?.BackgroundImage}
                                fill
                                sizes={sizes}
                                sx={{
                                    objectFit: "cover",
                                    width: "100%",
                                    maxWidth: "100%",
                                    objectPosition: "50% 50%",
                                }}
                            />
                        ) : (
                            <></>
                        )}
                    </AspectRatio>
                )}
            </Box>
            <Box position="relative" zIndex={2} pointerEvents="none">
                <Container py="0">
                    <Box
                        height={
                            props.fields?.BackgroundImage ? ["50vh", null, null, "100vh"] : "auto"
                        }
                        mt={props.fields?.BackgroundImage ? ["-50vh", null, null, "-100vh"] : 0}
                        pb={["sp56", null, null, "sp128"]}
                        display="flex"
                        alignItems="flex-end"
                    >
                        <SCHeading
                            textField={props.fields?.Headline}
                            size={headingSize}
                            noOfLines={3}
                            variant="moduleHeaderTitle"
                            flex={1}
                            pointerEvents="all"
                            color={
                                props.fields?.BackgroundImage?.value?.src !== undefined
                                    ? "white"
                                    : "black"
                            }
                        />
                    </Box>
                </Container>
            </Box>
        </>
    );
};

const DigitsAndData = (props: DigitsAndDataProps) => {
    const phKey = `DigitsAndData-${props.params?.DynamicPlaceholderId}`;

    if (!props.fields) return null;

    return (
        <Box
            as="section"
            id={props.fields?.AnchorId?.value}
            position="relative"
            minHeight="100vh"
            display="flex"
            flexDir={
                props.fields?.StickDir === "top" || !props.fields?.StickDir
                    ? "column"
                    : "column-reverse"
            }
            aria-labelledby={props.rendering.uid ?? props.rendering.componentName}
        >
            <DigitsAndDataHeader {...props} />
            <Box
                bgGradient={mapGradient(gradients.aquaToRed)}
                py={["sp72", null, null, "sp128"]}
                position="relative"
                zIndex={2}
                _before={{
                    content: '""',
                    position: "absolute",
                    top: 0,
                    bottom: 0,
                    left: 0,
                    right: 0,
                    bgGradient: mapGradient(gradients.aquaToRed),
                    mixBlendMode: "overlay",
                    opacity: 0,
                    zIndex: 3,
                    pointerEvents: "none",
                }}
                _after={{
                    content: '""',
                    position: "absolute",
                    top: 0,
                    bottom: 0,
                    left: 0,
                    right: 0,
                    background:
                        "linear-gradient(315deg, rgba(255,255,255,0.2) 0%, rgba(0,0,0,0) 100%)",
                    bgSize: ["28px 28px", null, null, null],
                    mixBlendMode: "overlay",
                    opacity: [0.4, null, null, 0.7],
                    zIndex: 4,
                    pointerEvents: "none",
                }}
            >
                <Container color="white">
                    <Flex
                        flexDirection="column"
                        alignItems="flex-end"
                        mb={["sp40", null, null, "sp60"]}
                    >
                        <SCHeading
                            id={props.rendering.uid ?? props.rendering.componentName}
                            textField={props.fields?.SubHeadline}
                            width={["100%", null, null, "58%"]}
                            as="h3"
                            size="h3"
                            textAlign={
                                props.params?.Styles === "position-left"
                                    ? ["center", null, null, "left"]
                                    : ["center", null, null, "right"]
                            }
                            mb={props.fields?.Text ? "sp40" : "sp60"}
                        />
                        <SCText
                            textField={props.fields?.Text}
                            width={["100%", null, null, "58%"]}
                            size="bodyLarge"
                            textAlign={[
                                "center",
                                null,
                                null,
                                props.params?.Styles === "position-left" ? "left" : "right",
                            ]}
                        />
                    </Flex>
                    <Placeholder name={phKey} rendering={props.rendering} />
                </Container>
            </Box>
        </Box>
    );
};

export default DigitsAndData;
