import { useRouter } from "next/router";
import { useEffect, useState } from "react";
import NextLink from "commons/ui/NextLink";
import PartnerLayout from "commons/ui/Partner/PartnerLayout";
import { ModalOverlay, ModalNextArrow, ModalPrevArrow } from "commons/ModalOverlay";
import type { PartnerResult, PartnerResults } from "components/PartnersFilter";

interface PartnerOverlayProps {
    partners: PartnerResults;
}

const PartnerOverlay = ({ partners }: PartnerOverlayProps) => {
    const { query, push } = useRouter();
    const [partner, setPartner] = useState<PartnerResult | null>();
    const [prevPartnerId, setPrevPartnerId] = useState<string>();
    const [nextPartnerId, setNextPartnerId] = useState<string>();

    const closeOverlay = () => {
        setPartner(null);
        delete query.highlight;
        push({ query }, undefined, { shallow: true });
    };

    // Calculate previous and next partner based on currently selected and current search results displayed
    useEffect(() => {
        if (!partners?.results || partners.results.length === 0) return;

        const activePartnerIndex = partners.results.findIndex((res) => res.id === query?.highlight);
        let nextIndex = activePartnerIndex + 1;
        let prevIndex = activePartnerIndex - 1;

        if (nextIndex >= partners.results.length) {
            nextIndex = 0;
        }
        if (prevIndex < 0) {
            prevIndex = partners.results.length - 1;
        }

        setNextPartnerId(partners.results[nextIndex].id);
        setPrevPartnerId(partners.results[prevIndex].id);
    }, [partners, query]);

    // Get current partner data from server
    useEffect(() => {
        const getPartner = async () => {
            const response = await fetch(`/api/partner/?id=${query.highlight}`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
            });

            if (!response.ok) {
                throw new Error("Failed to fetch data");
            }

            const responseData = await response.json();
            setPartner(responseData.result as PartnerResult);
        };

        if (!query.highlight) return;

        getPartner();
    }, [query.highlight]);

    if (!query.highlight || !partner) return null;

    return (
        <ModalOverlay
            closeOverlay={closeOverlay}
            prev={
                <NextLink
                    fontSize="xx-large"
                    href={{
                        query: { ...query, highlight: prevPartnerId },
                    }}
                >
                    <ModalPrevArrow />
                </NextLink>
            }
            next={
                <NextLink
                    fontSize="xx-large"
                    href={{
                        query: { ...query, highlight: nextPartnerId },
                    }}
                >
                    <ModalNextArrow />
                </NextLink>
            }
        >
            <PartnerLayout {...partner} />
        </ModalOverlay>
    );
};
export default PartnerOverlay;
