import type { ResponsiveValue } from "@chakra-ui/react";

interface GenericHeaderHeadlineProps {
    [key: string]: ResponsiveValue<string>;
}

const ghHeadlines = {
    "{840B6D5F-A144-4A1C-B79F-675F0B1F1BC7}": "lgTitle", // Large
    "{117C649E-9E4A-4D15-993D-3D094EBA9A0C}": "xlTitle", // Extra Large
} as GenericHeaderHeadlineProps;

const getHeadingSize = (id?: string) => (id ? ghHeadlines[id] : "xlTitle");

export default getHeadingSize;
