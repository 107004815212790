import type { Field, LinkField } from "@sitecore-jss/sitecore-jss-nextjs";
import { Box, Heading } from "@chakra-ui/react";
import BundleIcon from "commons/ui/BundleIcon";
import SCText from "commons/sc/SCText";
import SCLink from "commons/sc/SCLink";

interface Fields {
    AnswerText: Field<string>;
    QuestionText: Field<string>;
    AnswerCTA: LinkField;
}

export type QAElementProps = {
    params: { [key: string]: string };
    fields: Fields;
    rendering: {
        uid: string;
    };
};

const QAElement = (props: QAElementProps) => {
    if (!props.fields) return null;
    return (
        <Box
            as="dl"
            bg="brand.lighterGray"
            scrollSnapAlign={{ base: "start", lg: "none" }}
            minWidth={{ base: "85%", sm: "65%", md: "45%", lg: "auto" }}
            display={"grid"}
            gridTemplateRows={{ base: "1fr 1fr", lg: "auto" }}
            gridTemplateColumns={{ lg: "1fr auto 1fr" }}
            columnGap={{ base: undefined, lg: "sp48" }}
            rowGap={{ base: "sp16", lg: undefined }}
            px={{ base: "sp16", lg: "sp80" }}
            pt={{ base: "sp16", lg: "sp32" }}
            pb={{ base: "sp16", lg: "sp24" }}
            _hover={{
                background: { base: "brand.lighterGray", lg: "brand.yellow" },
            }}
            transition={"background 150ms ease-in"}
            borderBottom={{ base: "none", lg: "1px solid" }}
            _last={{ borderBottom: "none" }}
            borderBottomColor={{ base: "transparent", lg: "brand.lightGray" }}
        >
            <Box
                as="dt"
                bg={{ base: "brand.yellow", lg: "transparent" }}
                p={{ base: "sp16", lg: 0 }}
                display="grid"
                gridTemplateRows={{ base: "auto 1fr", lg: "auto" }}
                gap={{ base: "sp16", lg: 0 }}
            >
                {props.params?.QuestionHeadline && (
                    <Heading as="h4" size="sm" display={{ base: "block", lg: "none" }}>
                        {props.params?.QuestionHeadline}
                    </Heading>
                )}

                <SCText
                    as="p"
                    size={{ base: "bodySmall", lg: "bodyLarge" }}
                    textField={props.fields?.QuestionText}
                />
            </Box>
            <BundleIcon size="md" display={{ base: "none", lg: "block" }} name="ArrowForward" />
            <Box
                as="dd"
                p={{ base: "sp16", lg: 0 }}
                display="grid"
                gridTemplateRows={"auto 1fr"}
                gap={{ base: "sp20" }}
            >
                {props.params?.AnswerHeadline && (
                    <Heading as="h4" size="sm" display={{ base: "block", lg: "none" }}>
                        {props.params?.AnswerHeadline}
                    </Heading>
                )}

                <SCText
                    as="p"
                    lineHeight="short"
                    size={{ base: "bodySmall", lg: "bodyXl" }}
                    textField={props.fields?.AnswerText}
                />
                <SCLink
                    width="fit-content"
                    variant="underlineLink"
                    size="2xs"
                    my="sp12"
                    linkField={props.fields?.AnswerCTA}
                />
            </Box>
        </Box>
    );
};
export default QAElement;
