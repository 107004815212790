import { Button } from "@chakra-ui/react";
import NextLink from "next/link";
import BundleIcon from "commons/ui/BundleIcon";
import { useHeaderBackground } from "utils/contexts/headerBackgroundContext";
import type { LinkField } from "@sitecore-jss/sitecore-jss-nextjs";
import { gtmCTAEvent } from "commons/head/GTMTracking";

type Fields = {
    ContactUsLink: LinkField;
};

type ContactUsProps = {
    fields: Fields;
};

const ContactUs = (props: ContactUsProps) => {
    const { hasFullBackground, hasWhiteBackground } = useHeaderBackground();
    if (!props.fields?.ContactUsLink.value.href) return null;
    return (
        <Button
            as={NextLink}
            href={props.fields?.ContactUsLink.value.href}
            {...(props.fields?.ContactUsLink.value.target && {
                target: props.fields?.ContactUsLink.value.target,
            })}
            size={"sm"}
            variant={!hasFullBackground || hasWhiteBackground ? "primary" : "secondary"}
            rightIcon={<BundleIcon name="ArrowForward" />}
            width={{ base: "full", lg: "auto" }}
            sx={{
                ".chakra-button__icon": {
                    ml: "sp16",
                },
            }}
            onClick={() => {
                gtmCTAEvent(
                    "Contact us link header",
                    "Contact us link header",
                    props.fields?.ContactUsLink.value.href
                );
            }}
        >
            {props.fields?.ContactUsLink.value.text}
        </Button>
    );
};

export default ContactUs;
