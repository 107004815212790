import { Box, Button, Flex, FocusLock, Portal, useBreakpointValue } from "@chakra-ui/react";
import BundleIcon from "./ui/BundleIcon";
import { useEffect, type ReactNode } from "react";

interface ModalOverlayProps {
    closeOverlay: () => void;
    children: ReactNode;
    prev: JSX.Element;
    next: JSX.Element;
}

export const ModalPrevArrow = () => {
    const isMobileViewport = useBreakpointValue({ base: true, md: false }, { ssr: true });
    return (
        <BundleIcon
            name="ArrowForward"
            width={isMobileViewport ? "32px" : "45px"}
            height={isMobileViewport ? "45px" : "90px"}
            color={isMobileViewport ? "black" : "white"}
            style={{ transform: "scaleX(-1)" }}
        />
    );
};

export const ModalNextArrow = () => {
    const isMobileViewport = useBreakpointValue({ base: true, md: false }, { ssr: true });
    return (
        <BundleIcon
            name="ArrowForward"
            width={isMobileViewport ? "32px" : "45px"}
            height={isMobileViewport ? "45px" : "90px"}
            color={isMobileViewport ? "black" : "white"}
        />
    );
};

export const ModalOverlay = ({ closeOverlay, children, prev, next }: ModalOverlayProps) => {
    const isMobileViewport = useBreakpointValue({ base: true, md: false }, { ssr: true });

    useEffect(() => {
        const handleKeyDown = (event: KeyboardEvent) => {
            if (event.key === "Escape") {
                closeOverlay();
            }
        };

        window.addEventListener("keydown", handleKeyDown);

        return () => {
            window.removeEventListener("keydown", handleKeyDown);
        };
    }, [closeOverlay]);

    // Lock scroll if partner overlay is open
    useEffect(() => {
        document.body.style.overflow = "hidden";

        return () => {
            document.body.style.overflow = "auto";
        };
    }, []);

    return (
        <Portal>
            <FocusLock>
                <Box
                    position="fixed"
                    top={isMobileViewport ? "0" : "sp120"}
                    bottom={0}
                    left={0}
                    right={0}
                    zIndex={200}
                    bg={isMobileViewport ? "white" : "rgba(0, 0, 0, 0.5)"}
                    display={isMobileViewport ? "unset" : "flex"}
                    alignItems="center"
                    justifyContent="center"
                >
                    <Box position="absolute" width="100%" height="100%" onClick={closeOverlay} />
                    <Flex
                        position="relative"
                        alignItems="center"
                        justifyContent="center"
                        width={isMobileViewport ? "100%" : "100vw"}
                        zIndex={10}
                    >
                        {
                            //Commands for Desktop
                        }
                        {!isMobileViewport && prev}

                        <Box
                            position="relative"
                            padding={isMobileViewport ? "sp24" : "sp80"}
                            paddingBottom={isMobileViewport ? "90px" : "sp80"}
                            bgColor="white"
                            width="1370px"
                            maxH="calc(100vh - 160px)"
                            overflowY="auto"
                        >
                            {
                                // Close button
                            }
                            <Button
                                position="absolute"
                                right="24px"
                                top="24px"
                                variant="unstyled"
                                onClick={closeOverlay}
                            >
                                <BundleIcon name="Close" />
                            </Button>
                            {children}
                        </Box>

                        {
                            //Commands for mobile
                        }
                        {isMobileViewport && (
                            <Flex
                                justify="space-between"
                                alignItems="center"
                                position="fixed"
                                paddingX="sp24"
                                bottom="0"
                                width="100%"
                                zIndex={10}
                                backgroundColor="white"
                                height="70px"
                            >
                                {prev}
                                {next}
                            </Flex>
                        )}

                        {
                            //Commands for Desktop
                        }
                        {!isMobileViewport && next}
                    </Flex>
                </Box>
            </FocusLock>
        </Portal>
    );
};
